import React, { useState, useEffect } from "react";
import { Form, Button, Spinner, Alert, ProgressBar } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { UpdateBusinessFromOrganisationDetails } from "../OrganisationDetails/organisationDetailsAPI";
import { toast } from "react-toastify";
import { CheckCircle } from "lucide-react";
import { fetchNetnadaFactorsFromMongoDB } from "../../helpers/suppliersHelper";
import { isStepCompleted } from "../../helpers/stepsProgress";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import app from "../../firebase";
import { useAuth } from "../../Contexts/AuthContext";

function AccountOrgDetails({
  businessDetails,
  updateBusinessDetailsInState,
  handleMarkAsComplete,
  loadingChild,
  loading,
  // updateSectionProgress, // Receive the callback
  section, // Receive the section key
  totalQuestions, // Receive total questions if needed
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const { currentUser } = useAuth();
  // const [logoUrl, setLogoUrl] = useState("");
  const [primaryCategories, setPrimaryCategories] = useState([]);
  const [combinedCategoryFactorsObj, setCombinedCategoryFactorsObj] = useState(
    {}
  );
  const stepCompleted = isStepCompleted("review-organisation-details");

  // Fetch data when component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = await fetchNetnadaFactorsFromMongoDB();

        const filteredData = data
          .map((item) => {
            if (
              item.category_name === "Electricity" ||
              item.category_name === "Stationary energy (gaseous fuels)"
            ) {
              // Keep these categories with all activities
              return item;
            }

            // Filter activities to only those with unit as '$'
            const dollarActivities = item.activities.filter(
              (activity) => activity.unit === "$"
            );

            // If there are no dollar activities, exclude this category by returning null
            if (dollarActivities.length === 0) {
              return null;
            }

            // Otherwise, return the category with only the dollar activities
            return {
              ...item,
              activities: dollarActivities,
            };
          })
          .filter((item) => item !== null); // Remove categories without any dollar activities

        let primaryCategories = [];
        let combinedCategoryFactorsObj = {};
        for (let i = 0; i < filteredData.length; i++) {
          let factorObject = filteredData[i];
          let primaryCategory = factorObject.category_name;
          primaryCategories.push(primaryCategory);
          combinedCategoryFactorsObj[primaryCategory] =
            factorObject["activities"];
        }
        setPrimaryCategories(primaryCategories);
        setCombinedCategoryFactorsObj(combinedCategoryFactorsObj);
        // console.log("primaryCategories", primaryCategories);
        // console.log("combinedCategoryFactorsObj", combinedCategoryFactorsObj);
      } catch (error) {
        console.error("Error fetching netnada factors:", error);
      }
    };

    fetchData();
  }, []);

  function convertToYesNoString(value) {
    if (value === null || value === undefined) {
      return "";
    }
    return value ? "Yes" : "No";
  }

  function convertYesNoToBoolean(value) {
    if (value === "Yes") {
      return true;
    } else if (value === "No") {
      return false;
    } else {
      return null;
    }
  }

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      manufactureOrSellGoods: convertToYesNoString(
        businessDetails?.manufacture_or_sell_goods
      ),
      leaseSpaces: convertToYesNoString(businessDetails?.lease_spaces),
      remoteEmployees: convertToYesNoString(businessDetails?.remote_employees),
      ownOrLeaseVehicles: convertToYesNoString(
        businessDetails?.own_or_lease_vehicles
      ),
      primaryCategory: businessDetails?.primarycategory || "",
      secondaryCategory: businessDetails?.secondarycategory || "",
      organisationId: businessDetails?.abn || "",
      logoUrl: businessDetails?.logourl || "",
    },
  });

  // const selectedPrimaryCategory = watch(
  //   "primaryCategory",
  //   businessDetails?.primarycategory || ""
  // );
  const selectedPrimaryCategory = watch(
    "primaryCategory",
    businessDetails?.primarycategory || ""
  );
  const logoUrl = watch("logoUrl", businessDetails?.logourl || "");

  // Update secondary categories based on selected primary category
  const secondaryCategories =
    selectedPrimaryCategory &&
    combinedCategoryFactorsObj[selectedPrimaryCategory]
      ? combinedCategoryFactorsObj[selectedPrimaryCategory].map(
          (activity) => activity.activity_name
        )
      : [];

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const downloadURL = await storeFile(file);
        // setValue("logoUrl", downloadURL);
        setValue("logoUrl", downloadURL, { shouldDirty: true });
        setUploadProgress(0);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const storeFile = (file) => {
    return new Promise((resolve, reject) => {
      const storage = getStorage(app);
      const userFilePath = `OrganisationLogos/${currentUser.uid}/${file.name}`;
      const storageRef = ref(storage, userFilePath);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setUploadProgress(progress);
        },
        (error) => {
          console.error(error);
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          } catch (error) {
            console.error(error);
            reject(error);
          }
        }
      );
    });
  };

  // Reset secondaryCategory when primaryCategory changes
  useEffect(() => {
    setValue("secondaryCategory", "");
  }, [selectedPrimaryCategory, setValue]);

  const onSubmit = async (formData) => {
    setIsLoading(true);
    try {
      const body = {
        abn: formData.organisationId,
        primarycategory: formData.primaryCategory,
        secondarycategory: formData.secondaryCategory,
        lease_spaces: convertYesNoToBoolean(formData.leaseSpaces),
        manufacture_or_sell_goods: convertYesNoToBoolean(
          formData.manufactureOrSellGoods
        ),
        remote_employees: convertYesNoToBoolean(formData.remoteEmployees),
        own_or_lease_vehicles: convertYesNoToBoolean(
          formData.ownOrLeaseVehicles
        ),
        logourl: formData.logoUrl,
      };

      // 4. Calculate completed questions
      // let completedQuestions = 0;

      // if (body.abn) completedQuestions += 1;
      // if (body.primarycategory) completedQuestions += 1;
      // if (body.secondarycategory) completedQuestions += 1;
      // if (body.manufacture_or_sell_goods !== null) completedQuestions += 1;
      // if (body.lease_spaces !== null) completedQuestions += 1;
      // if (body.remote_employees !== null) completedQuestions += 1;
      // if (body.own_or_lease_vehicles !== null) completedQuestions += 1;
      // // Ensure completedQuestions does not exceed totalQuestions
      // completedQuestions = Math.min(completedQuestions, totalQuestions);

      // // 5. Update progress in parent component
      // updateSectionProgress(section, completedQuestions);

      await UpdateBusinessFromOrganisationDetails(body);
      updateBusinessDetailsInState(body);
      setIsLoading(false); // Stop loading
      // navigate("/welcome/new-overview"); // Navigate to the new route
      toast.success(`Organisation Details Saved Successfully!`);

      setIsEditing(false);
    } catch (error) {
      console.error("Failed to update organisation details:", error);
      // Handle error (e.g., show an error message)
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (businessDetails) {
      reset({
        manufactureOrSellGoods: convertToYesNoString(
          businessDetails?.manufacture_or_sell_goods
        ),
        leaseSpaces: convertToYesNoString(businessDetails?.lease_spaces),
        remoteEmployees: convertToYesNoString(
          businessDetails?.remote_employees
        ),
        ownOrLeaseVehicles: convertToYesNoString(
          businessDetails?.own_or_lease_vehicles
        ),
        primaryCategory: businessDetails.primarycategory || "",
        secondaryCategory: businessDetails.secondarycategory || "",
        organisationId: businessDetails.abn || "",
        logoUrl: businessDetails.logourl || "",
      });
    }
    // setLogoUrl(businessDetails.logourl || "");
  }, [businessDetails, reset]);

  // console.log("businessDetails", businessDetails);

  if (loading || !businessDetails)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "200px" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  return (
    <div>
      <h3 className="mb-4">Organisation Details</h3>

      {!stepCompleted && (
        <Alert variant="info" className="mb-4">
          To proceed to the next step, please review your answers below and
          click <strong>Confirm and Proceed</strong>.
        </Alert>
      )}
      <Form onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: 5 }}>
        {!isEditing ? (
          <div>
            <Form.Label style={{ fontSize: "18px", width: 300 }}>
              Organisation Logo:
            </Form.Label>
            {/* {logoUrl && ( */}
            <img
              src={logoUrl}
              alt="Uploaded Logo"
              style={{ maxWidth: "100px", marginTop: "10px" }}
            />
            {/* )} */}
          </div>
        ) : (
          <Form.Group className="mb-4" controlId="logoUrl">
            <Form.Label style={{ fontSize: "14px" }}>
              Organisation Logo
            </Form.Label>
            <Form.Control
              type="file"
              onChange={handleFileChange}
              size="sm"
              accept=".png, .jpg, .jpeg, .svg"
            />
            {uploadProgress > 0 && (
              <ProgressBar
                now={uploadProgress}
                label={`${uploadProgress}%`}
                style={{ marginTop: "10px" }}
              />
            )}
            {logoUrl && (
              <div>
                <img
                  src={logoUrl}
                  alt="Uploaded Logo"
                  style={{ maxWidth: "100px", marginTop: "10px" }}
                />
              </div>
            )}
          </Form.Group>
        )}

        {/* Business Identification Number */}

        <Form.Group
          className="mb-2"
          controlId="organisationId"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Form.Label style={{ fontSize: "18px", width: 300 }}>
            Business Identification Number (BIN):
          </Form.Label>
          {!isEditing ? (
            <div>{businessDetails.abn}</div>
          ) : (
            <>
              <Form.Control
                type="text"
                placeholder="Enter Business Identification Number (BIN)"
                isInvalid={!!errors.secondarycategory}
                {...register("organisationId", {
                  // required: "Secondary Category is required",
                })}
              />
              {errors.secondarycategory && (
                <Form.Control.Feedback type="invalid">
                  {errors.secondarycategory.message}
                </Form.Control.Feedback>
              )}
            </>
          )}
        </Form.Group>
        {/* Manufacture or Sell Goods */}
        <Form.Group
          className="mb-2"
          controlId="manufactureOrSellGoods"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Form.Label style={{ fontSize: "18px", width: 300 }}>
            Manufacture or Sell Goods:
          </Form.Label>
          {!isEditing ? (
            <div>
              {convertToYesNoString(businessDetails.manufacture_or_sell_goods)}
            </div>
          ) : (
            <div>
              <Form.Check
                type="radio"
                label="Yes"
                value="Yes"
                inline
                {...register("manufactureOrSellGoods", { required: true })}
              />
              <Form.Check
                type="radio"
                label="No"
                value="No"
                inline
                {...register("manufactureOrSellGoods", { required: true })}
              />
              {errors.manufacture_or_sell_goods && (
                <Form.Text className="text-danger">
                  This field is required.
                </Form.Text>
              )}
            </div>
          )}
        </Form.Group>

        {/* Lease Spaces */}
        <Form.Group
          className="mb-2"
          controlId="leaseSpaces"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Form.Label style={{ fontSize: "18px", width: 300 }}>
            Lease Spaces:
          </Form.Label>
          {!isEditing ? (
            <div>
              <div>{convertToYesNoString(businessDetails.lease_spaces)}</div>
            </div>
          ) : (
            <div>
              <Form.Check
                type="radio"
                label="Yes"
                value="Yes"
                inline
                {...register("leaseSpaces", { required: true })}
              />
              <Form.Check
                type="radio"
                label="No"
                value="No"
                inline
                {...register("leaseSpaces", { required: true })}
              />
              {errors.lease_spaces && (
                <Form.Text className="text-danger">
                  This field is required.
                </Form.Text>
              )}
            </div>
          )}
        </Form.Group>

        {/* Remote Employees */}
        <Form.Group
          className="mb-2"
          controlId="remoteEmployees"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Form.Label style={{ fontSize: "18px", width: 300 }}>
            Remote Employees:
          </Form.Label>
          {!isEditing ? (
            <div>
              <div>
                {convertToYesNoString(businessDetails.remote_employees)}
              </div>
            </div>
          ) : (
            <div>
              <Form.Check
                type="radio"
                label="Yes"
                value="Yes"
                inline
                {...register("remoteEmployees", { required: true })}
              />
              <Form.Check
                type="radio"
                label="No"
                value="No"
                inline
                {...register("remoteEmployees", { required: true })}
              />
              {errors.remote_employees && (
                <Form.Text className="text-danger">
                  This field is required.
                </Form.Text>
              )}
            </div>
          )}
        </Form.Group>

        {/* Own or Lease Vehicles */}
        <Form.Group
          className="mb-2"
          controlId="ownOrLeaseVehicles"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Form.Label style={{ fontSize: "18px", width: 300 }}>
            Own or Lease Vehicles:
          </Form.Label>
          {!isEditing ? (
            <div>
              {convertToYesNoString(businessDetails.own_or_lease_vehicles)}
            </div>
          ) : (
            <div>
              <Form.Check
                type="radio"
                label="Yes"
                value="Yes"
                inline
                {...register("ownOrLeaseVehicles", { required: true })}
              />
              <Form.Check
                type="radio"
                label="No"
                value="No"
                inline
                {...register("ownOrLeaseVehicles", { required: true })}
              />
              {errors.own_or_lease_vehicles && (
                <Form.Text className="text-danger">
                  This field is required.
                </Form.Text>
              )}
            </div>
          )}
        </Form.Group>

        {/* Primary Category */}
        <Form.Group
          className="mb-2"
          controlId="primaryCategory"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Form.Label style={{ fontSize: "18px", width: 300 }}>
            Primary Category:
          </Form.Label>
          {!isEditing ? (
            <div>{businessDetails.primarycategory}</div>
          ) : (
            <>
              <Form.Select
                isInvalid={!!errors.primaryCategory}
                {...register("primaryCategory", {
                  required: "Primary Category is required",
                })}
              >
                <option value="">Select Primary Category</option>
                {primaryCategories.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </Form.Select>
              {errors.primaryCategory && (
                <Form.Control.Feedback type="invalid">
                  {errors.primaryCategory.message}
                </Form.Control.Feedback>
              )}
            </>
          )}
        </Form.Group>

        {/* Secondary Category */}
        <Form.Group
          className="mb-4"
          controlId="secondaryCategory"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Form.Label style={{ fontSize: "18px", width: 300 }}>
            Secondary Category:
          </Form.Label>
          {!isEditing ? (
            <div>{businessDetails.secondarycategory}</div>
          ) : (
            <>
              <Form.Select
                isInvalid={!!errors.secondaryCategory}
                {...register("secondaryCategory", {
                  required: "Secondary Category is required",
                })}
                disabled={!selectedPrimaryCategory} // Disable if no primary category selected
              >
                <option value="">Select Secondary Category</option>
                {secondaryCategories.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </Form.Select>
              {errors.secondaryCategory && (
                <Form.Control.Feedback type="invalid">
                  {errors.secondaryCategory.message}
                </Form.Control.Feedback>
              )}
            </>
          )}
        </Form.Group>

        {isEditing ? (
          <>
            <Button
              variant="primary"
              type="submit"
              disabled={!isDirty || loadingChild}
            >
              {isLoading ? <Spinner animation="border" size="sm" /> : "Save"}
            </Button>{" "}
            <Button
              variant="secondary"
              onClick={() => setIsEditing(false)}
              disabled={isLoading || loadingChild}
              className="ml-2"
            >
              Cancel
            </Button>
          </>
        ) : (
          <div style={{ display: "flex", gap: 15, marginTop: "20px" }}>
            <Button
              variant="secondary"
              onClick={() => {
                setIsEditing(true);
                // Optional: Reset step completion if editing after completion
                // If you want users to reconfirm after editing, uncomment the line below
                // if (stepCompleted) resetStepCompletion("review-onboarding");
              }}
              disabled={loadingChild}
            >
              {loadingChild ? <Spinner animation="border" size="sm" /> : "Edit"}
            </Button>
            {!stepCompleted && (
              <Button
                variant="success"
                onClick={() =>
                  handleMarkAsComplete("review-organisation-details")
                }
                disabled={loadingChild}
              >
                {loadingChild ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <>
                    <CheckCircle className="tw-mr-2 tw-h-4 tw-w-4" /> Confirm
                    and Proceed
                  </>
                )}
              </Button>
            )}
          </div>
        )}
      </Form>
    </div>
  );
}

export default AccountOrgDetails;
