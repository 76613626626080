import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import PieChart from "./PieChart";
import BarChart from "./BarChart";
import {
  computeDecimalsToShow,
  extractDataBarChart,
  getTopThreeLabels,
} from "./chartHelpers";

function EmissionsByCategoryCard({ data }) {
  const [clickedId, setClickedId] = useState(
    data.yearlyData.length > 0 ? data.yearlyData[0].label : ""
  );
  const [colorId, setColorId] = useState(0);
  const sortedPieData = data?.yearlyData?.sort((a, b) => b.value - a.value);
  const roundedPieData = sortedPieData?.map(
    ({ id, label, value, datalines }, index) => ({
      id,
      label,
      value: Math.round(value),
      datalines,
      idColor: index,
    })
  );
  // console.log(roundedPieData)
  const topThreeLabels = getTopThreeLabels(roundedPieData);
  const { first, second, third } = topThreeLabels;
  const {
    maxDatalinesLabel,
    maxDatalines,
    specifiedLabel,
    specifiedDatalines,
    isSameAsMax,
  } = extractDataBarChart(clickedId, roundedPieData);

  const colors = [
    "#00688b", // Slightly Lighter Dark Blue
    "#4da6cc", // Light Blue
    "#9ec5d3", // Very Light Blue
    "#ffd099", // Light Orange
    "#ffb066", // Lighter Orange
    "#ff9015", // Orange Logo (less bright)
    "#d9780b", // Darker Orange
    "#b06000", // Darkest Orange
    "#e6e6cc", // Lightest Green
    "#ccecd0", // Light Green
    "#b3d9b3", // Lighter Green
    "#99e0bc", // Light Green transitioning to Gray
    "#80c8a8", // Light Green
    "#b0b0b0", // Gray
    "#909090", // Dark Gray
    "#707070", // Darker Gray
    "#505050", // Darkest Gray
    "#333333", // Very Dark Gray
  ];

  const handlePieClick = (id, idColor) => {
    setClickedId(id);
    setColorId(idColor);
  };

  // Filter the barData to show only data for the clicked ID
  const filteredData = data?.monthlyData?.map((data) => {
    return {
      month: data.month,
      [clickedId]: data?.categories[clickedId] || 0, // Fall back to 0 if no data is available for this ID
    };
  });

  // console.log(filteredData);
  // Set the keys dynamically based on the clickedId
  const keys = clickedId ? [clickedId] : [];
  const dynamicColor = colorId !== null ? colors[colorId] : "#ccc";
  const marginPie = { top: 40, bottom: 80, left: 100, right: 20 };
  return (
    <Card>
      <Card.Header>
        <h4>Emissions By Category</h4>
      </Card.Header>
      <Card.Body style={{ marginTop: -20 }}>
        {/* <p>
          Another way to understand emissions is by using the categories defined
          by the <strong>National Greenhouse Accounts Factors</strong>.
        </p> */}
        <Row>
          <Col lg={6}>
            {" "}
            <PieChart
              pieData={roundedPieData}
              colorTheme={colors}
              onClick={handlePieClick}
              title={"Category Data in tonnes of CO₂e"}
              margin={marginPie}
            />
            {roundedPieData.length > 0 && (
              <p>
                <strong>{first}</strong>, <strong>{second}</strong>, and{" "}
                <strong>{third}</strong> are the main drivers.
              </p>
            )}
          </Col>
          <Col lg={6}>
            <BarChart
              data={filteredData}
              colorTheme={dynamicColor}
              keys={keys}
              title={`${clickedId} Data`}
              small={true}
              decimalsToShow={computeDecimalsToShow(filteredData, keys)}
            />
            {roundedPieData?.length > 0 && (
              <p style={{ marginTop: 40 }}>
                <strong>{specifiedLabel}</strong> has{" "}
                <strong>{specifiedDatalines}</strong> invoices
                {isSameAsMax ? (
                  " and the highest number overall."
                ) : (
                  <>
                    , while <strong>{maxDatalinesLabel}</strong> leads with{" "}
                    <strong>{maxDatalines}</strong>.
                  </>
                )}
              </p>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default EmissionsByCategoryCard;
