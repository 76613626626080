import React, { useContext, useEffect, useState } from "react";
import { TransparencyContext } from "../../context/Context";
import LoadingTransparencyHub from "./LoadingTransparencyHub";

import PieChartTransparencyHub from "./PieChartTransparencyHub";
import ResultsTable from "./ResultsTable";
import AlphabeticalGrade from "./AlphabeticalGrade";
import {
  isStepCompleted,
  updateStepProgress,
} from "../../helpers/stepsProgress";
import { getActiveUserData, getInventoryProgress } from "../../helpers/store";
import { toast } from "react-toastify";
// Ensure correct import

const Step5TransparencyHub = () => {
  const [currentInventoryProgress, setCurrentInventoryProgress] = useState(
    JSON.parse(getInventoryProgress())
  );
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const { isLoading, selectedAssessment } = useContext(TransparencyContext);
  const score = selectedAssessment?.responses?.totalScore;
  const maxScore = selectedAssessment?.responses?.maxTotalScore;
  // console.log(selectedAssessment?.responses);
  const pieData = [
    { id: "Total Score", value: score, color: "#00d27a" },
    { id: "Remaining Score", value: maxScore - score, color: "#e0e0e0" },
  ];

  //Need to check if the step is completed
  useEffect(() => {
    const updateProgress = async () => {
      if (!isStepCompleted("answer-questions")) {
        try {
          const response = await updateStepProgress({
            stringId: "answer-questions",
            status: "completed",
            activeUser,
            currentInventoryProgress,
          });

          if (response.success) {
            toast.success(response.text);
          } else {
            toast.error(response.text);
          }
        } catch (error) {
          toast.error("An error occurred while updating progress.");
          console.error(error);
        }
      }
    };

    updateProgress();
  }, []);
  return (
    <>
      {isLoading ? (
        <LoadingTransparencyHub />
      ) : (
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: "40%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h4 style={{ marginBottom: 20 }}>Overall Score</h4>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>
                <PieChartTransparencyHub pieData={pieData} />
              </div>
              <p
                style={{
                  width: "100%",
                }}
              >
                Your overall score is <strong>{score}</strong> out of a maximum
                possible score of <strong>{maxScore}</strong>. You can always go
                back and revisit the questions to improve your score further.
              </p>
            </div>
            <div>
              <AlphabeticalGrade score={score} maxScore={maxScore} />
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // justifyContent: "center",
            }}
          >
            <h4 style={{ marginBottom: 20 }}>Detailed Sections Score</h4>
            {selectedAssessment?.responses?.progress.length > 0 && (
              <ResultsTable
                tableData={selectedAssessment?.responses?.progress}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Step5TransparencyHub;
