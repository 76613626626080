import { Card, Form, ProgressBar, Spinner } from "react-bootstrap";
import classNames from "classnames";
import IconButton from "../common/IconButton";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
// import Screen1 from "./Screen1";
// import Screen2 from "./Screen2";
import { useForm } from "react-hook-form";
import { useState } from "react";
import {
  getActiveUserData,
  getInventoryProgress,
  getUserData,
  setSingleInventoryProgress,
} from "../../helpers/store";
import { useNavigate } from "react-router-dom";
import { updateReportingPeriodDetailsFromWizard } from "./reportingPeriodDetailsAPI";
import Screen1 from "./Screen1";
import Screen2 from "./Screen2";
import { toast } from "react-toastify";
import {
  updateActiveFieldForSubStep,
  updateStepProgress,
} from "../../helpers/stepsProgress";

function ReportingPeriodDetailsWizardLayout() {
  const [step, setStep] = useState(1); // Manage current step
  const totalSteps = 2; // Updated from 5 to 4
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [userData] = useState(JSON.parse(getUserData()));
  const progress = (step / totalSteps) * 100;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    getValues,
  } = useForm();

  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [currentInventoryProgress, setCurrentInventoryProgress] = useState(
    JSON.parse(getInventoryProgress())
  );

  // console.log("selectedLocations:", selectedLocations);

  const onSubmitData = async (data) => {
    if (step < totalSteps) {
      setStep(step + 1);
    } else {
      // console.log("Form Data:", data);

      const body = {
        employeeNumber: data.numberOfEmployees,
        revenue: data.annualRevenue,
        offsets: data.offsetPeriod,
        businessFlightsExist: data.businessFlights === "Yes" ? true : false,
      };

      // console.log("Body:", body);

      setLoading(true); // Start loading

      try {
        const response = await updateReportingPeriodDetailsFromWizard(
          userData.inventoryId,
          body
        );

        // console.log("Response:", response);

        const updateData = {
          ...body,
        };

        // console.log("updateData:", updateData);
        setSingleInventoryProgress(userData.inventoryId, updateData);

        //conditionally deactivate flight step

        if (!data.businessFlightsExist) {
          const activeStatusBody = [
            {
              inventoryId: activeUser.inventoryId,
              stringId: "submit-flights-calculator",
              active: false,
              officeid: null,
            },
          ];
          // console.log("UPDATE STEPS", activeStatusBody);
          await updateActiveFieldForSubStep(activeStatusBody);
        }

        //Update step

        const stringId = "reporting-period-details";
        const responseProgress = await updateStepProgress({
          stringId,
          status: "completed",

          activeUser,
          currentInventoryProgress,
          onUpdateState: false,
          onUpdateInventoryProgress: false,
        });
        if (responseProgress.success) {
          toast.success(responseProgress.text);
        } else {
          toast.error(responseProgress.text);
        }

        setLoading(false); // Stop loading
        navigate("/welcome/new-overview"); // Navigate to the new route
        toast.success(`Details Saved Successfully!`);
      } catch (error) {
        console.error("Error updating business details:", error);
        setLoading(false); // Stop loading even if there is an error
        // Optionally, handle the error (e.g., display a message to the user)
      }
    }
  };

  const onError = (errors) => {
    console.log("Errors:", errors);
  };
  const yeartext = `${userData.selectedMeasurementYear} ${
    userData.selectedMeasurementYearType.charAt(0).toUpperCase() +
    userData.selectedMeasurementYearType.slice(1)
  } Year`;
  return (
    <Card
      as={Form}
      noValidate
      onSubmit={handleSubmit(onSubmitData, onError)}
      className="theme-wizard mb-5"
    >
      <Card.Header>
        <div className="text-center">
          <h3>
            Details for {userData.selectedMeasurementYear}{" "}
            {userData.selectedMeasurementYearType.charAt(0).toUpperCase() +
              userData.selectedMeasurementYearType.slice(1)}{" "}
            Year
          </h3>
          <ProgressBar
            now={progress}
            min={0}
            max={100}
            striped
            animated
            variant="info"
          />
        </div>
      </Card.Header>
      <Card.Body className="fw-normal px-md-6 py-4">
        {step === 1 && (
          <Screen1 register={register} errors={errors} year={yeartext} />
        )}
        {step === 2 && <Screen2 register={register} errors={errors} />}
      </Card.Body>
      <Card.Footer className={classNames("px-md-6 bg-light d-flex")}>
        <IconButton
          variant="link"
          icon={faChevronLeft}
          iconAlign="left"
          transform="down-1 shrink-4"
          className={classNames("px-0 fw-semi-bold", {
            "d-none": step === 1,
          })}
          onClick={() => {
            clearErrors();
            setStep(step - 1);
          }}
        >
          Prev
        </IconButton>

        <IconButton
          variant="primary"
          className="ms-auto px-5"
          type="submit"
          icon={faChevronRight}
          iconAlign="right"
          transform="down-1 shrink-4"
          disabled={loading} // Disable the button when loading
        >
          {loading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Submitting...
            </>
          ) : step === totalSteps ? (
            "Submit"
          ) : (
            "Next"
          )}
        </IconButton>
      </Card.Footer>
    </Card>
  );
}

export default ReportingPeriodDetailsWizardLayout;
