// function getAllRoutes(userData) {
//   let children = [
//     {
//       name: "Overview",
//       icon: "faChartPie",
//       to: "/welcome/overview",
//       active: true,
//     },
//     {
//       name: "Dashboard",
//       icon: "faChartLine",
//       to: "/welcome/dashboard/all-locations",
//       active: userData.role !== "EMPLOYEE",
//     },
//     {
//       name: "Data",
//       icon: "faFileAlt",
//       active: true,
//       children: [
//         {
//           name: "Upload Data",
//           to: "/welcome/data/file-uploader",
//           active: userData.role !== "EMPLOYEE",
//         },
//         {
//           name: "All Invoices",
//           to: "/welcome/data/all-invoices",
//           active: userData.role !== "EMPLOYEE",
//         },
//         {
//           name: "Supply Chain",
//           to: "/welcome/data/supply-chain",
//           active: userData.role !== "EMPLOYEE",
//         },
//         {
//           name: "Activity Data",
//           // icon: "faCalculator",
//           active: true,
//           children: [
//             {
//               name: "Waste",
//               to: "/welcome/data/climate-active/waste-form",
//               active: userData.role !== "EMPLOYEE",
//             },
//             {
//               name: "Flights",
//               to: "/welcome/data/climate-active/flights-form",
//               active: userData.role !== "EMPLOYEE",
//             },
//             {
//               name: "Paper",
//               to: "/welcome/data/climate-active/paper-form",
//               active: userData.role !== "EMPLOYEE",
//             },
//             {
//               name: "Staff Commute",
//               to: "/welcome/data/climate-active/staff-form",
//               active: userData.role !== "EMPLOYEE",
//             },
//             {
//               name: "See All",
//               to: "/welcome/data/climate-active/all-forms",
//               active: userData.role !== "EMPLOYEE",
//             },
//             {
//               name: "WFH Emission Calculator",
//               to: "/welcome/data/wfh",
//               active: true,
//             },
//           ],
//         },
//       ],
//     },
//     {
//       name: "Events",
//       icon: "faCalendarPlus",
//       active: userData.role !== "EMPLOYEE",
//       to: "/welcome/dashboard/events",
//     },
//     {
//       name: "Solutions",
//       icon: "faLightbulb",
//       active:
//         userData.role !== "EMPLOYEE" &&
//         (userData.subscription === "PAID" ||
//           userData.subscription === "PREMIUM"),
//       children: [
//         {
//           name: "Browse All",
//           to: "/welcome/solutions/browse-all",
//           active:
//             userData.subscription === "PAID" ||
//             userData.subscription === "PREMIUM",
//         },
//         {
//           name: "My Solutions",
//           to: "/welcome/solutions/my-solutions",
//           active:
//             userData.subscription === "PAID" ||
//             userData.subscription === "PREMIUM",
//         },
//         {
//           name: "Areas of Improvement",
//           to: "/welcome/solutions/areas-of-improvement",
//           active:
//             userData.subscription === "PAID" ||
//             userData.subscription === "PREMIUM",
//         },
//         // {
//         //   name: "Chat",
//         //   to: "/welcome/solutions/chat",
//         //   active:
//         //     userData.subscription === "PAID" ||
//         //     userData.subscription === "PREMIUM",
//         // },
//         // {
//         //   name: "Recommended Solutions",
//         //   to: "/welcome/solutions/recommended-solutions",
//         //   active:
//         //     userData.subscription === "PAID" ||
//         //     userData.subscription === "PREMIUM",
//         // },
//       ],
//     },
//     {
//       name: "Partners",
//       icon: "faHandshake",
//       active:
//         userData.role !== "EMPLOYEE" &&
//         (userData.subscription === "PAID" ||
//           userData.subscription === "PREMIUM"),
//       children: [
//         {
//           name: "Browse All",
//           to: "/welcome/partners/browse-all",
//           active:
//             userData.subscription === "PAID" ||
//             userData.subscription === "PREMIUM",
//         },
//         {
//           name: "My Partners",
//           to: "/welcome/partners/my-partners",
//           active:
//             userData.subscription === "PAID" ||
//             userData.subscription === "PREMIUM",
//         },
//       ],
//     },
//     {
//       name: "Integrations",
//       icon: "faPlug",
//       to: "/welcome/integrations",
//       active: true,
//     },
//     {
//       name: "Reports",
//       icon: "faFileExcel",
//       to: "/welcome/user-reports",
//       active: userData.role !== "EMPLOYEE",
//     },
//     {
//       name: "Organisation Details",
//       icon: "faCalendarPlus",
//       active: userData.role !== "EMPLOYEE",
//       to: "/welcome/org-details",
//     },
//     {
//       name: "New Overview",
//       icon: "faCalendarPlus",
//       active: userData.role !== "EMPLOYEE",
//       to: "/welcome/new-overview",
//     },
//     {
//       name: "Kanban",
//       icon: "faTrello",
//       to: "/welcome/kanban",
//       active: true,
//     },
//     // {
//     //   name: "News",
//     //   icon: "faNewspaper",
//     //   to: "/documentation/getting-started",
//     //   active: false,
//     // },

//     {
//       name: "Offset",
//       icon: "faTree",
//       to: "/documentation/getting-started",
//       active: false,
//     },
//     {
//       name: "Grants",
//       icon: "faAward",
//       active:
//         userData.subscription === "PREMIUM" && userData.role !== "EMPLOYEE",
//       children: [
//         {
//           name: "Browse All",
//           to: "/welcome/grants/browse-all",
//           active: userData.subscription === "PREMIUM",
//         },
//         {
//           name: "My Grants",
//           to: "/welcome/grants/my-grants",
//           active: userData.subscription === "PREMIUM",
//         },
//       ],
//     },
//     {
//       name: "Admin",
//       icon: "faLock",
//       active:
//         userData.role === "SUPER_ADMIN" ||
//         userData.role === "MANAGER" ||
//         userData.role === "ADVISOR",
//       hide:
//         userData.role !== "SUPER_ADMIN" &&
//         userData.role !== "MANAGER" &&
//         userData.role !== "ADVISOR",
//       children: [
//         {
//           name: "Businesses",
//           to: "/welcome/businesses",
//           active:
//             userData.role === "SUPER_ADMIN" || userData.role === "ADVISOR",
//           hide: userData.role !== "ADVISOR" && userData.role !== "SUPER_ADMIN",
//         },
//         {
//           name: "Users",
//           to: "/welcome/users",
//           active:
//             userData.role === "SUPER_ADMIN" || userData.role === "MANAGER",
//           hide: userData.role === "ADVISOR",
//         },
//         {
//           name: "Suppliers",
//           to: "/welcome/data/suppliers-view",
//           active: userData.role === "SUPER_ADMIN",
//           hide: userData.role === "ADVISOR",
//         },
//         {
//           name: "Upload Reports",
//           to: "/welcome/upload-reports",
//           active: userData.role === "SUPER_ADMIN",
//           hide: userData.role === "ADVISOR",
//         },
//         {
//           name: "Upload Content",
//           to: "/welcome/upload-content",
//           active: userData.role === "SUPER_ADMIN",
//           hide: userData.role === "ADVISOR",
//         },
//         {
//           name: "Whitelist",
//           to: "/welcome/whitelist",
//           active: userData.role === "SUPER_ADMIN",
//           hide: userData.role === "ADVISOR",
//         },
//       ],
//     },
//   ];

//   return [
//     {
//       label: "All Routes",
//       labelDisable: true,
//       children,
//     },
//   ];
// }

function getAllRoutes(userData) {
  let children = [
    {
      name: "Home",
      icon: "faHouse",
      to: "/welcome/new-overview",
      active: userData.role !== "SUPPLIER",
    },
    {
      name: "Dashboard",
      icon: "faChartLine",
      to: "/welcome/dashboard/all-locations",
      active: userData.role !== "EMPLOYEE" && userData.role !== "SUPPLIER",
    },
    {
      name: "Identify",
      icon: "faMagnifyingGlassChart",
      active: userData.role !== "SUPPLIER",
      children: [
        {
          name: "Reporting  Year",
          to: "/welcome/identify/reporting-year",
          active: false,
        },
        {
          name: "Organisation Details",
          to: "/welcome/account-details",
          active: userData.role !== "EMPLOYEE" && userData.role !== "SUPPLIER",
        },
        {
          name: "Emissions Boundaries",
          to: "/welcome/identify/emissions-boundaries",
          active: userData.role !== "SUPPLIER",
        },
      ],
    },
    {
      name: "Measure",
      icon: "faCalculator",
      active: userData.role !== "SUPPLIER",
      children: [
        {
          name: "Files",
          to: "/welcome/data/all-invoices",
          active: userData.role !== "EMPLOYEE" && userData.role !== "SUPPLIER",
        },
        {
          name: "Calculators",
          to: "/welcome/data/calculations",
          active: userData.role !== "SUPPLIER",
        },
        {
          name: "Supply Chain",
          to: "/welcome/data/supply-chain",
          active: userData.role !== "EMPLOYEE" && userData.role !== "SUPPLIER",
        },
      ],
    },
    {
      name: "Reduce",
      icon: "faTree",
      active: userData.role !== "SUPPLIER",
      children: [
        {
          name: "Strategies",
          // icon: "faLightbulb",
          active:
            userData.role !== "EMPLOYEE" &&
            (userData.subscription === "PAID" ||
              userData.subscription === "PREMIUM") &&
            userData.role !== "SUPPLIER",
          children: [
            {
              name: "Browse All",
              to: "/welcome/solutions/browse-all",
              active:
                (userData.subscription === "PAID" ||
                  userData.subscription === "PREMIUM") &&
                userData.role !== "SUPPLIER",
            },
            {
              name: "My Solutions",
              to: "/welcome/solutions/my-solutions",
              active:
                (userData.subscription === "PAID" ||
                  userData.subscription === "PREMIUM") &&
                userData.role !== "SUPPLIER",
            },
            {
              name: "Areas of Improvement",
              to: "/welcome/solutions/areas-of-improvement",
              active:
                (userData.subscription === "PAID" ||
                  userData.subscription === "PREMIUM") &&
                userData.role !== "SUPPLIER",
            },
          ],
        },
        {
          name: "Partners",
          // icon: "faHandshake",
          active:
            userData.role !== "EMPLOYEE" &&
            (userData.subscription === "PAID" ||
              userData.subscription === "PREMIUM") &&
            userData.role !== "SUPPLIER",
          children: [
            {
              name: "Browse All",
              to: "/welcome/partners/browse-all",
              active:
                (userData.subscription === "PAID" ||
                  userData.subscription === "PREMIUM") &&
                userData.role !== "SUPPLIER",
            },
            {
              name: "My Partners",
              to: "/welcome/partners/my-partners",
              active:
                (userData.subscription === "PAID" ||
                  userData.subscription === "PREMIUM") &&
                userData.role !== "SUPPLIER",
            },
          ],
        },
        {
          name: "Offset",
          to: "/welcome/reduce/offset",
          active: userData.role !== "SUPPLIER",
        },
      ],
    },
    {
      name: "Content",
      icon: "faEnvelopesBulk",
      to: "/welcome/user-content",
      active: userData.role !== "EMPLOYEE" && userData.role !== "SUPPLIER",
    },
    {
      name: "Reports",
      icon: "faFileAlt",
      to: "/welcome/user-reports",
      active: userData.role !== "EMPLOYEE" && userData.role !== "SUPPLIER",
    },
    {
      name: "Integrations",
      icon: "faPlug",
      to: "/welcome/integrations",
      active: userData.role !== "SUPPLIER",
    },
    {
      name: "Admin",
      icon: "faLock",
      active:
        userData.role === "SUPER_ADMIN" ||
        userData.role === "MANAGER" ||
        userData.role === "WHITELABEL_SUPER_ADMIN" ||
        userData.role === "ADVISOR",
      hide:
        userData.role !== "SUPER_ADMIN" &&
        userData.role !== "MANAGER" &&
        userData.role !== "WHITELABEL_SUPER_ADMIN" &&
        userData.role !== "ADVISOR",
      children: [
        {
          name: "Businesses",
          to: "/welcome/businesses",
          active:
            userData.role === "SUPER_ADMIN" ||
            userData.role === "ADVISOR" ||
            userData.role === "WHITELABEL_SUPER_ADMIN",
          hide:
            userData.role !== "ADVISOR" &&
            userData.role !== "SUPER_ADMIN" &&
            userData.role !== "WHITELABEL_SUPER_ADMIN",
        },
        {
          name: "Users",
          to: "/welcome/users",
          active:
            userData.role === "SUPER_ADMIN" ||
            userData.role === "MANAGER" ||
            userData.role === "WHITELABEL_SUPER_ADMIN",
          hide: userData.role === "ADVISOR",
        },
        {
          name: "Suppliers",
          to: "/welcome/data/suppliers-view",
          active: userData.role === "SUPER_ADMIN",
          hide:
            userData.role === "ADVISOR" ||
            userData.role === "WHITELABEL_SUPER_ADMIN",
        },
        {
          name: "Help And Support",
          to: "/welcome/create-help-and-support-docs",
          active: userData.role === "SUPER_ADMIN",
          hide:
            userData.role === "ADVISOR" ||
            userData.role === "WHITELABEL_SUPER_ADMIN",
        },
        {
          name: "Upload Reports",
          to: "/welcome/upload-reports",
          active:
            userData.role === "SUPER_ADMIN" ||
            userData.role === "WHITELABEL_SUPER_ADMIN",
          hide: userData.role === "ADVISOR",
        },
        {
          name: "Upload Content",
          to: "/welcome/upload-content",
          active:
            userData.role === "SUPER_ADMIN" ||
            userData.role === "WHITELABEL_SUPER_ADMIN",
          hide: userData.role === "ADVISOR",
        },
        {
          name: "Whitelist",
          to: "/welcome/whitelist",
          active: userData.role === "SUPER_ADMIN",
          hide:
            userData.role === "ADVISOR" ||
            userData.role === "WHITELABEL_SUPER_ADMIN",
        },
        {
          name: "User Tickets",
          to: "/welcome/user-tickets",
          active: userData.role === "SUPER_ADMIN",
          hide:
            userData.role === "ADVISOR" ||
            userData.role === "WHITELABEL_SUPER_ADMIN",
        },
        {
          name: "Transparency Assessment",
          to: "/welcome/transparency-assessment",
          active:
            userData.role === "SUPER_ADMIN" ||
            userData.role === "WHITELABEL_SUPER_ADMIN",
          hide: userData.role === "ADVISOR",
        },
        {
          name: "Invoices",
          to: "/welcome/edit-invoice",
          active:
            userData.role === "SUPER_ADMIN" ||
            userData.role === "WHITELABEL_SUPER_ADMIN",
          hide: userData.role === "ADVISOR",
        },
      ],
    },
  ];

  return [
    {
      label: "Menu",
      labelDisable: false,
      children,
    },
  ];
}

function getFooterRoutes(userData) {
  let children = [
    {
      name: "Transparency Hub",
      icon: "faStar",
      to: "/welcome/transparency-hub",
      active: true,
    },
    {
      name: "Settings",
      icon: "faCog",
      to: "/settings",
      active: false,
    },
    {
      name: "Contact Us",
      icon: "faEnvelope",
      to: "/contact-us",
      active: false,
    },
  ];
  return [
    {
      label: "For You Page",
      labelDisable: true,
      children,
    },
  ];
}

module.exports = {
  getAllRoutes,
  getFooterRoutes,
};
