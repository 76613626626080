import React, { useEffect, useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { updateReportingPeriodDetailsFromWizard } from "../ReportingPeriodDetails/reportingPeriodDetailsAPI";
import { toast } from "react-toastify";
import { getActiveUserData } from "../../helpers/store";
import { updateActiveFieldForSubStep } from "../../helpers/stepsProgress";

function AccountRepYearDetails({
  inventoryProgress,
  updateInventoryProgressInState,
  loading,
  section, // Receive the section key
  totalQuestions, // Receive total questions if needed
}) {
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function convertToYesNoString(value) {
    if (value === null || value === undefined) {
      return "";
    }
    return value ? "Yes" : "No";
  }

  function convertYesNoToBoolean(value) {
    if (value === "Yes") {
      return true;
    } else if (value === "No") {
      return false;
    } else {
      return null;
    }
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      employeeNumber: inventoryProgress?.employeeNumber || "",
      revenue: inventoryProgress?.revenue || "",
      offsets: inventoryProgress?.offsets || "",
      businessFlightsExist: convertToYesNoString(
        inventoryProgress?.businessFlightsExist
      ),
    },
  });

  const onSubmit = async (formData) => {
    setIsLoading(true);
    try {
      const body = {
        employeeNumber: formData.employeeNumber,
        revenue: formData.revenue,
        offsets: formData.offsets,
        businessFlightsExist: convertYesNoToBoolean(
          formData.businessFlightsExist
        ),
      };

      // 4. Calculate completed questions
      let completedQuestions = 0;

      if (body.employeeNumber) completedQuestions += 1;
      if (body.revenue) completedQuestions += 1;
      if (body.offsets) completedQuestions += 1;
      if (body.businessFlightsExist !== null) completedQuestions += 1;

      // Ensure completedQuestions does not exceed totalQuestions
      completedQuestions = Math.min(completedQuestions, totalQuestions);

      // 5. Update progress in parent component
      // updateSectionProgress(section, completedQuestions);
      // console.log("formData", body);

      await updateReportingPeriodDetailsFromWizard(
        inventoryProgress.inventoryid,
        body
      );

      // 6. If flights set to false update deactivate step
      if (!body.businessFlightsExist) {
        const activeStatusBody = [
          {
            inventoryId: activeUser.inventoryId,
            stringId: "submit-flights-calculator",
            active: false,
            officeid: null,
          },
        ];
        await updateActiveFieldForSubStep(activeStatusBody);
      }

      // Update the parent component's state
      updateInventoryProgressInState(body);
      toast.success(`Reporting Period Data Saved Successfully!`);
      setIsEditing(false);
    } catch (error) {
      console.error("Failed to update reporting period data:", error);
      toast.error(`Failed to save Reporting Period Data. Please try again.`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (inventoryProgress) {
      reset({
        employeeNumber: inventoryProgress.employeeNumber || "",
        revenue: inventoryProgress.revenue || "",
        offsets: inventoryProgress.offsets || "",
        businessFlightsExist: convertToYesNoString(
          inventoryProgress.businessFlightsExist
        ),
      });
    }
  }, [inventoryProgress, reset]);

  if (loading || !inventoryProgress)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "200px" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );

  return (
    <div>
      <h3 className="mb-4">Reporting Period Data</h3>
      <Form onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: 5 }}>
        {/* Exact Number of Employees */}
        <Form.Group
          className="mb-2"
          controlId="employeeNumber"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Form.Label style={{ fontSize: "18px", width: 300 }}>
            Exact Number of Employees:
          </Form.Label>
          {!isEditing ? (
            <div>{inventoryProgress.employeeNumber}</div>
          ) : (
            <>
              <Form.Control
                type="number"
                placeholder="Enter exact number of employees"
                isInvalid={!!errors.employeeNumber}
                {...register("employeeNumber", {
                  // required: "Exact Number of Employees is required",
                  valueAsNumber: true,
                })}
              />
              {errors.employeeNumber && (
                <Form.Control.Feedback type="invalid">
                  {errors.employeeNumber.message}
                </Form.Control.Feedback>
              )}
            </>
          )}
        </Form.Group>

        {/* Yearly Revenue */}
        <Form.Group
          className="mb-2"
          controlId="revenue"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Form.Label style={{ fontSize: "18px", width: 300 }}>
            Yearly Revenue:
          </Form.Label>
          {!isEditing ? (
            <div>{inventoryProgress.revenue}</div>
          ) : (
            <>
              <Form.Control
                type="number"
                placeholder="Enter yearly revenue"
                isInvalid={!!errors.revenue}
                {...register("revenue", {
                  // required: "Yearly Revenue is required",
                  valueAsNumber: true,
                })}
              />
              {errors.revenue && (
                <Form.Control.Feedback type="invalid">
                  {errors.revenue.message}
                </Form.Control.Feedback>
              )}
            </>
          )}
        </Form.Group>

        {/* Offsets for Period */}
        <Form.Group
          className="mb-2"
          controlId="offsets"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Form.Label style={{ fontSize: "18px", width: 300 }}>
            Offsets for Period:
          </Form.Label>
          {!isEditing ? (
            <div>{inventoryProgress.offsets}</div>
          ) : (
            <>
              <Form.Control
                type="number"
                placeholder="Enter offsets for period"
                isInvalid={!!errors.offsets}
                {...register("offsets", {
                  // required: "Offsets for Period is required",
                  valueAsNumber: true,
                })}
              />
              {errors.offsets && (
                <Form.Control.Feedback type="invalid">
                  {errors.offsets.message}
                </Form.Control.Feedback>
              )}
            </>
          )}
        </Form.Group>

        {/* Business Flights in Past 12 Months */}
        <Form.Group
          className="mb-4"
          controlId="businessFlightsExist"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Form.Label style={{ fontSize: "18px", width: 300 }}>
            Did you or any employees take business related flights in the past
            12 months?
          </Form.Label>
          {!isEditing ? (
            <div>
              {convertToYesNoString(inventoryProgress.businessFlightsExist)}
            </div>
          ) : (
            <div>
              <Form.Check
                type="radio"
                label="Yes"
                value="Yes"
                inline
                {...register("businessFlightsExist", { required: true })}
              />
              <Form.Check
                type="radio"
                label="No"
                value="No"
                inline
                {...register("businessFlightsExist", { required: true })}
              />
              {errors.businessFlightsExist && (
                <Form.Text className="text-danger">
                  This field is required.
                </Form.Text>
              )}
            </div>
          )}
        </Form.Group>

        {isEditing ? (
          <>
            <Button
              variant="primary"
              type="submit"
              disabled={!isDirty || isLoading}
            >
              {isLoading ? <Spinner animation="border" size="sm" /> : "Save"}
            </Button>{" "}
            <Button
              variant="secondary"
              onClick={() => setIsEditing(false)}
              disabled={isLoading}
            >
              Cancel
            </Button>
          </>
        ) : (
          <Button variant="primary" onClick={() => setIsEditing(true)}>
            Edit
          </Button>
        )}
      </Form>
    </div>
  );
}

export default AccountRepYearDetails;
