import React, { useState } from "react";
import {
  Button,
  Form,
  Modal,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { BsInfoCircle } from "react-icons/bs";
import FileInput from "./FileInput";
import { toast } from "react-toastify";
import { compareOrder } from "../../../helpers/utils";
import { shouldDisplayQuestion } from "./formHelper";
import { Lock } from "lucide-react";

function DynamicForm({
  questions = [],
  responses = {},
  onSave,
  sectionNumber,
  supplierId,
  assessmentData = {},
}) {
  const [showModal, setShowModal] = useState(false);
  const [textModal, setTextModal] = useState("");
  const [files, setFiles] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: responses,
  });
  // console.log("questions", questions);
  const formValues = watch(); // Watch all form values
  const sortedQuestions = questions
    .slice()
    .sort((a, b) => compareOrder(a.order, b.order));
  const onSubmit = async (data) => {
    setIsLoading(true);
    const updatedData = { ...data, ...files };
    await onSave(updatedData);
    setIsLoading(false);
    toast.success("Successfully saved.");
  };

  const handleOpenModal = (text) => {
    setTextModal(text);
    setShowModal(true);
  };

  const handleAddFile = (fileData, questionId) => {
    console.log("Set files in Dynamic form", fileData);
    setFiles((prevFiles) => ({
      ...prevFiles,
      [questionId]: fileData,
    }));
  };
  // console.log("sortedQuestions", sortedQuestions);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {sortedQuestions?.map((field, index) => {
          // console.log(field);
          if (!shouldDisplayQuestion(questions, field, formValues, responses)) {
            return null; // Do not render this question
          }

          return (
            <Form.Group className="mb-4" controlId={field.id} key={field.id}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex", gap: 5 }}>
                  <span style={{ fontWeight: "bold" }}>
                    {/* {sectionNumber}.{index + 1} */}
                    {field.order}
                  </span>
                  <Form.Label style={{ fontSize: "18px", marginRight: "8px" }}>
                    {field.label}
                  </Form.Label>
                </div>

                {field.description && (
                  <BsInfoCircle
                    style={{
                      color: "#2D7FDE",
                      cursor: "pointer",
                      marginLeft: "8px",
                      marginBottom: "8px",
                    }}
                    onClick={() => handleOpenModal(field.description)}
                  />
                )}
                {field.unlocks && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id={`tooltip-unlock-${field.id}`}>
                        Answering this question may reveal additional sections
                      </Tooltip>
                    }
                  >
                    <Lock
                      style={{
                        cursor: "pointer",
                        marginLeft: "8px",
                        marginBottom: "8px",
                      }}
                    />
                  </OverlayTrigger>
                )}
              </div>

              {/* Render input based on field type */}
              {field.type === "select" ? (
                <Form.Control
                  as="select"
                  {...register(field.id, {
                    // required: "This field is required",
                  })}
                  isInvalid={!!errors[field.id]}
                >
                  <option value="">Select an option</option>
                  {field.options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Control>
              ) : field.type === "checkbox" ? (
                field.options.map((option) => (
                  <div key={option.value}>
                    <Form.Check
                      type="checkbox"
                      id={`${field.id}-${option.value}`}
                      label={option.label}
                      {...register(`${field.id}.${option.value}`, {})}
                    />
                  </div>
                ))
              ) : field.type === "file" ? (
                <FileInput
                  field={field}
                  fileData={responses?.[field.id]}
                  assessmentData={assessmentData}
                  onAddFile={handleAddFile}
                  supplierId={supplierId}
                />
              ) : (
                <Form.Control
                  type={field.type}
                  placeholder={field.placeholder}
                  isInvalid={!!errors[field.id]}
                  {...register(field.id, {
                    // required: "This field is required",
                    // Add other validations as needed
                  })}
                />
              )}

              {errors[field.id] && (
                <Form.Control.Feedback type="invalid">
                  {errors[field.id].message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          );
        })}

        <Button type="submit" disabled={isLoading}>
          {isLoading ? (
            <>
              Loading...
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </>
          ) : (
            "Save Responses"
          )}
        </Button>
      </form>

      {showModal && (
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Question Context
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ padding: "15px" }}>
              <p style={{ marginBottom: "10px" }}>{textModal}</p>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default DynamicForm;
