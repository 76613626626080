import React, { useState } from "react";
import { Button, Table, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import LocationModal from "./LocationModal";

export const OfficeLocationsTable = ({
  locations = [],
  selectedLocation,
  setSelectedLocation,
  setIsEditMode,
  setIsAddingLocation,
  handleToggleSelect,
  handleEditLocation,
}) => {
  // console.log("locations in the table", locations);
  const [showEditModal, setShowEditModal] = useState(false);

  // Function to handle edit location click
  const handleEditClick = (location) => {
    setSelectedLocation(location);
    setIsEditMode(true); // Set edit mode
    setIsAddingLocation(false); // Disable add mode
    setShowEditModal(true); // Show the modal
  };

  const handleEditClose = () => {
    setShowEditModal(false);
    setSelectedLocation(null);
  };

  const handleEditSave = (updatedLocation) => {
    handleEditLocation(updatedLocation);
    setShowEditModal(false);
  };

  return (
    <>
      <Table responsive bordered hover className="table locationTable">
        <thead className="table-light bg-200">
          <tr>
            <th>Select</th>
            <th>Location Name</th>
            <th>Country</th>
            <th>State</th>
            <th>Street Address</th>
            <th>City</th>
            <th>Postcode</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {locations.map((location) => (
            <tr
              key={location.id}
              style={location.selected ? { backgroundColor: "#E5FCE5" } : {}}
            >
              <td>
                <Form.Check
                  type="checkbox"
                  checked={location.selected}
                  onChange={() => handleToggleSelect(location.officeId)}
                />
              </td>
              <td>{location.locationName}</td>
              <td>{location.country}</td>
              <td>{location.state}</td>
              <td>{location.streetAddress}</td>
              <td>{location.city}</td>
              <td>{location.postcode}</td>
              <td>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={() => handleEditClick(location)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {selectedLocation && (
        <LocationModal
          showModal={showEditModal}
          handleCloseModal={handleEditClose}
          handleSave={handleEditSave}
          mode="edit"
          // locationData={selectedLocation}
          location={selectedLocation}
          setLocation={setSelectedLocation}
        />
      )}
    </>
  );
};
