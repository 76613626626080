import {
  updateActiveStatusForSteps,
  updateCurrentStepProgressInInventoryProgress,
  updateStepsProgressByInventoryAndName,
} from "../components/new-overview/newOverviewAPI";
import {
  getInventoryProgress,
  getStepsProgress,
  setInventoryCurrentStepProgressId,
  setStepsProgress,
} from "./store";
import { toast } from "react-toastify";

function isEqualOrBothNull(value1, value2) {
  return value1 === value2 || (!value1 && !value2);
}

export function updateSubStep(data, stringId, progress, officeId) {
  // console.log("Update office", officeId);
  // console.log("Update stringId", stringId);
  let subStepFound = false;
  let operation;
  let newStatus;
  // Traverse categories
  for (let category of data.categories) {
    // Initialize counters for category completion (optional)
    // let totalSteps = category.steps.length;
    // let completedSteps = 0;

    // Traverse steps
    for (let step of category.steps) {
      const totalSubSteps = step.subSteps.length;
      let completedSubSteps = 0;

      // Traverse subSteps
      for (let subStep of step.subSteps) {
        // console.log("Substep", subStep.stringId);
        // console.log("Update stringId", stringId);
        // console.log("office", officeId);

        if (
          subStep.stringId === stringId &&
          isEqualOrBothNull(subStep.officeId, officeId)
        ) {
          console.log("Substep found", subStep);
          subStepFound = true;

          // If the subStep is already completed, return false
          if (subStep.status === "completed" && subStep.completedAt) {
            return false;
          }
          //cases where we change url and string id
          if (subStep.stringId === "complete-organisation-details") {
            subStep.stringId = "review-organisation-details";
            subStep.url = "/welcome/account-details";
          } else if (subStep.stringId === "reporting-period-details") {
            subStep.url = "/welcome/account-details";
          }

          // Update completedAt to the current date

          if (progress === "completed") {
            subStep.status = "completed";
            operation = "completed";
            newStatus = "completed";
            subStep.completedAt = new Date();
          } else if (progress === "skipped") {
            subStep.status = "skipped";
            operation = "skipped";
            newStatus = "skipped";
            subStep.completedAt = new Date();
          } else if (progress >= subStep.maxProgress) {
            subStep.status = "completed";
            operation = "completed";
            newStatus = "completed";
            subStep.completedAt = new Date();
          } else {
            // Update currentProgress and status
            // subStep.currentProgress = progress;
            subStep.status = progress;
            operation = "updated";
            newStatus = progress;
          }
        }

        // Update the count of completed subSteps
        if (subStep.status === "completed" || subStep.status === "skipped") {
          completedSubSteps += 1;
        }
      }

      // Determine isCompleted as a percentage or boolean for step
      if (totalSubSteps > 0) {
        // const completionPercentage = (completedSubSteps / totalSubSteps) * 100;
        step.isCompleted =
          completedSubSteps === totalSubSteps ? true : completedSubSteps;
      } else {
        step.isCompleted = false;
      }

      // Update the count of completed steps (optional)
      // if (step.isCompleted === true) {
      //     completedSteps += 1;
      // }
    }

    // Optionally, update isCompleted for the category based on its steps
    // You can implement similar logic here if required
  }

  if (!subStepFound) {
    console.error(`Sub-step with id "${stringId}" not found.`);
    return false; // Return false if sub-step not found
  }

  return { data, operation, newStatus };
}

export function updateNextTask(data) {
  // console.log(data);
  // Collect all sub-steps in order
  let orderedSubSteps = [];
  for (let category of data.categories) {
    if (category.steps) {
      for (let step of category.steps) {
        if (step.subSteps) {
          // Filter active subSteps
          let activeSubSteps = step.subSteps.filter(
            (subStep) => subStep.active === true
          );

          if (activeSubSteps.length > 0) {
            // Sort active sub-steps by order
            let sortedActiveSubSteps = activeSubSteps.sort(
              (a, b) => a.order - b.order
            );
            orderedSubSteps.push(...sortedActiveSubSteps);
          }
        }
      }
    }
  }

  // Find the first incomplete sub-step where all previous are completed or skipped
  for (let i = 0; i < orderedSubSteps.length; i++) {
    let subStep = orderedSubSteps[i];
    if (subStep.status !== "completed" && subStep.status !== "skipped") {
      // Check if all previous sub-steps are completed or skipped
      let allPrevDone = true;
      for (let j = 0; j < i; j++) {
        let prevSubStep = orderedSubSteps[j];
        if (
          prevSubStep.status !== "completed" &&
          prevSubStep.status !== "skipped"
        ) {
          allPrevDone = false;
          break;
        }
      }
      if (allPrevDone) {
        // This is the current sub-step
        return subStep.stringId;
      } else {
        // Previous sub-steps are not completed
        return orderedSubSteps[i - 1].stringId;
      }
    }
  }

  // Maybe in future change to "completed" or something
  return null; // Indicates completion of all steps
}

export const updateStepProgress = async ({
  stringId,
  status,
  activeUser,
  currentInventoryProgress,
  officeId,
  onUpdateState,
  onUpdateInventoryProgress,
}) => {
  const selectedInventoryProgress = currentInventoryProgress.find(
    (item) =>
      item.measurementyear === activeUser.selectedMeasurementYear &&
      item.measurementyeartype === activeUser.selectedMeasurementYearType
  );

  // const updateInfo = updateSubStep(copyProgress, stringId, status, officeId);

  const updateBody = {
    inventoryProgressId: activeUser.inventoryId,
    stepId: stringId,
    newStatus: status,
    officeId,
  };

  try {
    //Returns null if step does not exists or we send the same status so no update needed
    const transformedStructure = await updateStepsProgressByInventoryAndName(
      updateBody
    );
    // console.log("transformed structure", transformedStructure);
    if (transformedStructure.data) {
      const currentSubStep = updateNextTask(transformedStructure.data);
      setStepsProgress(JSON.stringify(transformedStructure.data));

      if (onUpdateState) {
        onUpdateState(transformedStructure.data);
      }

      if (currentSubStep !== selectedInventoryProgress.currentStepProgressId) {
        const currentStepBody = {
          inventoryProgressId: activeUser.inventoryId,
          stringId: currentSubStep,
          // office_id,
        };

        await updateCurrentStepProgressInInventoryProgress(currentStepBody);
        setInventoryCurrentStepProgressId(
          activeUser.inventoryId,
          currentSubStep
        );
        if (onUpdateInventoryProgress) {
          const updatedInventoryProgress = JSON.parse(getInventoryProgress());
          onUpdateInventoryProgress(updatedInventoryProgress);
        }
      }

      // toast.success(`Substep ${stringId} updated successfully.`);
      return {
        success: true,
        text: `Substep ${stringId} updated successfully.`,
      };
    } else {
      // toast.success(`Substep ${stringId} is already completed.`);
      return {
        success: true,
        text: `Substep ${stringId} is already completed.`,
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      text: `An error occurred while updating substep ${stringId}.`,
    };
    // toast.error("An error occurred while updating steps.");
  }
};

export async function updateActiveFieldForSubStep(activeStatusBody) {
  //1. Call the API to update the active status
  try {
    const response = await updateActiveStatusForSteps(activeStatusBody);
    console.log("response", response);
    if (response.status === "success") {
      const transformedStructure = response.data;

      // Update the steps progress in local storage
      setStepsProgress(JSON.stringify(transformedStructure));
    } else {
      toast.error("An error occurred while updating active status.");
      throw new Error("Failed to update active status");
    }
  } catch (error) {
    console.error(error);
    toast.error("An error occurred while updating active status.");
  }
}

export function isStepCompleted(stringId, officeId = null) {
  const stepProgressData = JSON.parse(getStepsProgress());
  for (let category of stepProgressData.categories) {
    if (category.steps) {
      for (let step of category.steps) {
        if (step.subSteps) {
          for (let subStep of step.subSteps) {
            if (
              subStep.stringId === stringId &&
              (officeId === null || subStep.officeId === officeId)
            ) {
              return subStep.status === "completed";
            }
          }
        }
      }
    }
  }
  // If step with stringId (and officeId) not found, handle as needed
  return false;
}
