import React, { useEffect } from "react";
import {
  Spinner,
  Modal,
  Col,
  Row,
  Form,
  Button,
  Dropdown,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEnvelopesBulk,
  faList,
  faStream,
} from "@fortawesome/free-solid-svg-icons";
import WidgetSectionTitle from "../../widgets/WidgetSectionTitle";
import { useState } from "react";
import AdvanceTableWrapper from "../common/advance-table/AdvanceTableWrapper";
import { Card } from "react-bootstrap";
import AdvanceTable from "../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../common/advance-table/AdvanceTableFooter";
import AdvanceTableSearchBox from "../common/advance-table/AdvanceTableSearchBox";
import {
  deleteBusinessByBusinessId,
  deleteNoUserByBusinessId,
  deleteUsersByBusinessId,
  getAdvisorBusinesses,
  getAdvisors,
  getAllInventoryProgress,
  getBusinesses,
  getUsersAndOfficesByBusinessId,
  refreshDashboardCalculation,
  updateBusiness,
} from "./adminApi";
import SoftBadge from "../common/SoftBadge";
import Flex from "../common/Flex";
import { getActiveUserData } from "../../helpers/store";
import CardDropdown from "../common/CardDropdown";
import SelectDataSourcesModal from "./SendSupplierDetails";
import { Link, useLocation } from "react-router-dom";
import { EditDashboardModal, EditFrozenDataModal } from "./BusinessesModals";
import ActionsModal from "./ActionModal";
import { Ellipsis } from "lucide-react";

const Businesses = () => {
  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [businessId, setBusinessId] = useState(null);
  const [businesses, setBusinesses] = useState([]);
  const [advisors, setAdvisors] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeletingBusiness, setIsDeletingBusiness] = useState(false);
  const [showSelectDataSourcesModal, setShowSelectDataSourcesModal] =
    useState(false);
  const [editFrozenData, setEditFrozenData] = useState(false);
  const [showEditFrozenDataModal, setShowEditFrozenDataModal] = useState(false);
  const [editDashboardData, setEditDashboardData] = useState(false);
  const [showEditDashboardModal, setShowEditDashboardModal] = useState(false);
  const [isYes, setIsYes] = useState(false);
  const [isNo, setIsNo] = useState(false);
  const [editBusiness, setEditBusiness] = useState(null);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const location = useLocation();
  const [showActionsModal, setShowActionsModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  useEffect(() => {
    if (location.state && location.state.businessId) {
      setSearchValue(location.state.businessId);
    }
  }, [location.state]);

  const columns = [
    {
      accessor: "",
      Header: "Actions",
      hide: activeUser.role === "ADVISOR",
      Cell: (rowData) => {
        return (
          <Button
            variant="link"
            onClick={() => {
              setSelectedRowData(rowData.row.original);
              setShowActionsModal(true);
            }}
          >
            <Ellipsis />
          </Button>
        );
      },
    },
    {
      accessor: "id",
      Header: "Business Id",
      Hide: true,
      //headerProps: { className: "text-left" },
      // cellProps: {
      //   className: "fs-0",
      // },
      cellProps: {
        className: "text-wrap",
      },
      Cell: (rowData) => {
        const { id } = rowData.row.original;

        return (
          <Flex>
            <div>
              <a href="#" onClick={() => handleOpen(id)}>
                {id}
              </a>
            </div>
          </Flex>
        );
      },
    },

    {
      accessor: "name",
      Header: "Name",
      cellProps: {
        className: "text-wrap",
      },
    },
    {
      accessor: "inventoryProgress",
      Header: "Frozen Data",
      cellProps: {
        className: "text-wrap",
      },
      Cell: (rowData) => {
        const { inventoryProgress } = rowData.row.original;
        return (
          <>
            {inventoryProgress &&
              inventoryProgress.length > 0 &&
              inventoryProgress.map((entry) => (
                <SoftBadge
                  key={entry.id}
                  bg={entry.is_frozen ? "success" : "warning"}
                >
                  {entry.measurement_year_type === "calendar" ? "CY" : "FY"}
                  {entry.measurement_year}: {entry.is_frozen ? "Yes" : "No"}
                </SoftBadge>
              ))}
          </>
        );
      },
    },
    {
      accessor: "processingstatus",
      Header: "Processing Status",

      headerProps: { className: "text-center" },
      cellProps: {
        className: "fs-0",
      },
      Cell: (rowData) => {
        const { processingstatus } = rowData.row.original;
        return (
          <>
            {processingstatus && (
              <SoftBadge
                pill
                bg={processingstatus === "Completed" ? "success" : "warning"}
                className="d-block"
              >
                {processingstatus}
                <FontAwesomeIcon
                  icon={processingstatus === "Completed" ? faCheck : faStream}
                  transform="shrink-2"
                  className="ms-1"
                />
              </SoftBadge>
            )}
          </>
        );
      },
    },
    {
      accessor: "authprovider",
      Header: "Auth Provider",
    },
    {
      accessor: "subscription",
      Header: "Subscription",
    },
    {
      accessor: "advisor",
      Header: "Advisor",
      cellProps: {
        className: "text-wrap",
      },
      Cell: (rowData) => {
        const { advisor } = rowData.row.original;
        return (
          <>
            {advisor && (
              <SoftBadge pill bg="primary" className="d-block">
                {advisor}
                {/* <FontAwesomeIcon
                  icon={faCheck}
                  transform="shrink-2"
                  className="ms-1"
                /> */}
              </SoftBadge>
            )}
          </>
        );
      },
    },
    {
      accessor: "supplierid",
      Header: "Supplier Id",
      Cell: ({ row }) => (
        <Link
          to={"/welcome/transparency-assessment"}
          state={{ supplierId: row.original.supplierid }}
        >
          {row.original.supplierid}
        </Link>
      ),
    },
    {
      accessor: "created_at",
      Header: "Created At",
      cellProps: {
        className: "text-wrap",
      },
    },
    {
      accessor: "category",
      Header: "Industry",
      cellProps: { className: "text-wrap" },
    },
    {
      accessor: "esgmanager",
      Header: "Manager",
      cellProps: {
        className: "text-wrap",
      },
    },
    {
      accessor: "esgstrategy",
      Header: "Strategy",
      cellProps: {
        className: "text-wrap",
      },
    },
    {
      accessor: "carbonfootprint",
      Header: "Carbon Footprint",
      cellProps: {
        className: "text-wrap",
      },
    },
    {
      accessor: "numberofemployees",
      Header: "Employees Count",
    },
  ];

  async function handleClose(isRefresh) {
    setShowModal(false);
    if (isRefresh) {
    }
  }

  function handleOpen(businessId) {
    setBusinessId(businessId);
    setShowModal(true);
  }

  function handleEditOpen(business) {
    setEditBusiness(business);
    setShowEditModal(true);
  }
  const handleEditFrozenDataOpen = (data) => {
    setEditFrozenData(data);
    setShowEditFrozenDataModal(true);
  };

  const handleDashboardOpen = (data) => {
    setEditDashboardData(data);
    setShowEditDashboardModal(true);
  };

  const handleRefreshDashboard = async (data) => {
    setIsRefreshing(true);
    await refreshDashboardCalculation({ businessId: data.id });
    setIsRefreshing(false);
  };

  async function handleEditClose(isRefresh) {
    setShowEditModal(false);
    if (isRefresh) {
      if (activeUser.role === "ADVISOR") {
        await setAdvisorBusinessesToState(activeUser.uid);
      } else {
        await setBusinessesToState();
      }
    }
  }

  async function handleSelectDataSourcesOpen(business) {
    setSelectedBusiness(business);
    setShowSelectDataSourcesModal(true);
  }

  async function setAdvisorsToState() {
    const response = await getAdvisors();
    setAdvisors(response);
  }
  async function setBusinessesToState() {
    setIsLoading(true);
    const businesses = await getBusinesses();
    const inventoryProgressList = await getAllInventoryProgress();

    const businessesWithInventoryProgress = businesses.map((business) => {
      const matchingInventoryProgress = inventoryProgressList.filter(
        (inventory) => inventory.business_id === business.id
      );
      return {
        ...business,
        inventoryProgress: matchingInventoryProgress,
      };
    });

    setBusinesses(businessesWithInventoryProgress);
    setIsLoading(false);
  }

  async function setAdvisorBusinessesToState(advisorId) {
    setIsLoading(true);
    const response = await getAdvisorBusinesses(advisorId);
    setBusinesses(response);
    setIsLoading(false);
  }

  // handleDeleteOpen
  async function handleDeleteOpen(businessId) {
    setBusinessId(businessId);
    setIsDeletingBusiness(true);
    setShowDeleteModal(true);
    await deleteBusinessByBusinessId({ id: businessId });
    setIsDeletingBusiness(false);
  }
  // handleDeleteClose
  async function handleDeleteClose() {
    setShowDeleteModal(false);
    if (activeUser.role === "ADVISOR") {
      await setAdvisorBusinessesToState(activeUser.uid);
    } else {
      await setBusinessesToState();
    }
    await setAdvisorsToState();
  }

  // handleDeleteUsers
  async function handleDeleteUsers(businessId) {
    setIsYes(true);
    await deleteUsersByBusinessId({ id: businessId });
    setIsYes(false);
    setShowDeleteModal(false);
    if (activeUser.role === "ADVISOR") {
      await setAdvisorBusinessesToState(activeUser.uid);
    } else {
      await setBusinessesToState();
    }
    await setAdvisorsToState();
  }

  // handleDeleteNoUser
  async function handleDeleteNoUser(businessId) {
    setIsNo(true);
    await deleteNoUserByBusinessId({ id: businessId });
    setIsNo(false);
    setShowDeleteModal(false);
    if (activeUser.role === "ADVISOR") {
      await setAdvisorBusinessesToState(activeUser.uid);
    } else {
      await setBusinessesToState();
    }
    await setAdvisorsToState();
  }

  async function fetchBusinesses() {
    if (activeUser.role === "ADVISOR") {
      await setAdvisorBusinessesToState(activeUser.uid);
    } else {
      await setBusinessesToState();
    }
    await setAdvisorsToState();
  }

  useEffect(() => {
    fetchBusinesses();
  }, [activeUser]);

  return (
    <>
      <WidgetSectionTitle
        icon={faList}
        title="Businesses"
        subtitle="Show all the businesses."
        transform="shrink-2"
        className="mb-4 mt-6"
      />

      {showModal && (
        <UsersOfficesModal
          data={{ businessId }}
          showModal={showModal}
          handleClose={handleClose}
        />
      )}

      {showEditModal && (
        <EditBusinessModal
          showEditModal={showEditModal}
          handleEditClose={handleEditClose}
          data={{ ...editBusiness }}
          advisors={advisors}
        />
      )}

      {showDeleteModal && (
        <DeleteBusinessModal
          businessId={businessId}
          isYes={isYes}
          isNo={isNo}
          isDeletingBusiness={isDeletingBusiness}
          showDeleteModal={showDeleteModal}
          handleDeleteClose={handleDeleteClose}
          handleDeleteUsers={handleDeleteUsers}
          handleDeleteNoUser={handleDeleteNoUser}
        />
      )}

      {showSelectDataSourcesModal && (
        <SelectDataSourcesModal
          showSelectDataSourcesModal={showSelectDataSourcesModal}
          setShowSelectDataSourcesModal={setShowSelectDataSourcesModal}
          handleSelectDataSourcesModalClose={() => {
            setShowSelectDataSourcesModal(false);
          }}
          selectedBusiness={selectedBusiness}
        />
      )}
      {showEditDashboardModal && (
        <EditDashboardModal
          data={editDashboardData}
          show={showEditDashboardModal}
          onHide={() => setShowEditDashboardModal(false)}
          onDataUpdated={fetchBusinesses}
        />
      )}
      {showEditFrozenDataModal && (
        <EditFrozenDataModal
          data={editFrozenData}
          show={showEditFrozenDataModal}
          onHide={() => setShowEditFrozenDataModal(false)}
          onDataUpdated={fetchBusinesses}
        />
      )}
      {showActionsModal && selectedRowData && (
        <ActionsModal
          show={showActionsModal}
          onHide={() => setShowActionsModal(false)}
          rowData={selectedRowData}
          activeUser={activeUser}
          handlers={{
            handleEditOpen,
            handleEditFrozenDataOpen,
            handleSelectDataSourcesOpen,
            handleDashboardOpen,
            handleRefreshDashboard,
            handleDeleteOpen,
          }}
        />
      )}
      {isRefreshing && <IsRefreshingModal isRefreshing={isRefreshing} />}

      {isLoading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999, // Adjust the z-index as needed
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="mb-3">
          <AdvanceTableWrapper
            columns={columns.filter((column) => !column.hide)}
            data={businesses}
            sortable
            pagination
            perPage={25}
            rowCount={businesses.length}
          >
            <Card>
              <Card.Header>
                <Row className="flex-end-center mb-3">
                  <Col xs="auto" sm={6} lg={4}>
                    <AdvanceTableSearchBox
                      table
                      searchValue={searchValue}
                      setSearchValue={setSearchValue}
                    />
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="btn-reveal-trigger text-nowrap align-middle"
                  tableProps={{
                    size: "lg",
                    className: "fs--1 mb-0 overflow-hidden",
                  }}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTableFooter
                  data={businesses}
                  rowCount={businesses.length}
                  table
                  rowInfo
                  navButtons
                />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        </div>
      )}
    </>
  );
};

const UsersOfficesModal = ({ showModal, handleClose, data }) => {
  const [users, setUsers] = useState([]);
  const [offices, setOffices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const usersColumns = [
    {
      accessor: "id",
      Header: "User Id",
    },
    {
      accessor: "name",
      Header: "Name",
    },
    {
      accessor: "title",
      Header: "Title",
    },
    {
      accessor: "mobphone",
      Header: "Mobile",
    },
    {
      accessor: "email",
      Header: "Email",
    },
    {
      accessor: "role",
      Header: "Role",
    },
    {
      accessor: "created_at",
      Header: "Created At",
    },
    {
      accessor: "updated_at",
      Header: "Updated At",
    },
    {
      accessor: "office_id",
      Header: "Office Id",
    },
  ];

  const officesColumns = [
    {
      accessor: "office_id",
      Header: "Office Id",
    },
    {
      accessor: "location",
      Header: "Name",
    },
    {
      accessor: "country",
      Header: "Country",
    },

    {
      accessor: "city",
      Header: "City",
    },
    {
      accessor: "street",
      Header: "Street",
    },
    {
      accessor: "postcode",
      Header: "Postbox",
    },
    // {
    //   accessor: "user_id",
    //   Header: "User Id",
    // },
    // {
    //   accessor: "user_name",
    //   Header: "User name",
    // },
  ];

  async function getUsersAndOfficesByBusiness(businessId) {
    const response = await getUsersAndOfficesByBusinessId(businessId);

    for (let i = 0; i < response.users.length; i++) {
      response.users[i].name =
        response.users[i].given + " " + response.users[i].family;
    }

    setUsers(response.users);
    setOffices(response.offices);
    setIsLoading(false);
  }

  useEffect(() => {
    getUsersAndOfficesByBusiness(data.businessId);
  }, []);

  return (
    <div>
      {" "}
      <Modal
        show={showModal}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Business Users and Locations
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              <div className="mb-3">
                <AdvanceTableWrapper
                  columns={usersColumns}
                  data={users}
                  sortable
                  pagination
                  perPage={5}
                  rowCount={users.length}
                >
                  <Card>
                    <Card.Header>
                      {" "}
                      <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                        Users
                      </h5>
                    </Card.Header>
                    <Card.Body className="p-0">
                      <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="btn-reveal-trigger text-nowrap align-middle"
                        tableProps={{
                          size: "lg",
                          className: "fs--1 mb-0 overflow-hidden",
                        }}
                      />
                    </Card.Body>
                    <Card.Footer>
                      <AdvanceTableFooter
                        data={users}
                        rowCount={users.length}
                        table
                        rowInfo
                        navButtons
                      />
                    </Card.Footer>
                  </Card>
                </AdvanceTableWrapper>
              </div>

              <div className="mb-3">
                <AdvanceTableWrapper
                  columns={officesColumns}
                  data={offices}
                  sortable
                  pagination
                  perPage={7}
                  rowCount={offices.length}
                >
                  <Card>
                    <Card.Header>
                      {" "}
                      <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                        Offices
                      </h5>
                    </Card.Header>
                    <Card.Body className="p-0">
                      <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="btn-reveal-trigger text-nowrap align-middle"
                        tableProps={{
                          size: "lg",
                          className: "fs--1 mb-0 overflow-hidden",
                        }}
                      />
                    </Card.Body>
                    <Card.Footer>
                      <AdvanceTableFooter
                        data={offices}
                        rowCount={offices.length}
                        table
                        rowInfo
                        navButtons
                      />
                    </Card.Footer>
                  </Card>
                </AdvanceTableWrapper>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

const EditBusinessModal = ({
  showEditModal,
  handleEditClose,
  data,
  advisors,
}) => {
  // console.log(data);
  const [subscription, setSubscription] = useState(data.subscription);
  const [processingStatus, setProcessingStatus] = useState(
    data.processingstatus
  );
  const [advisor, setAdvisor] = useState(data.advisorId);
  const [authProvider, setAuthProvider] = useState(data.authprovider);
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit(e) {
    setIsLoading(true);
    e.preventDefault();
    await updateBusiness({
      ...data,
      subscription,
      processingstatus: processingStatus,
      authprovider: authProvider,
      advisorId: advisor,
    });
    setIsLoading(false);
    handleEditClose(true);
  }

  return (
    <div>
      {" "}
      <Modal
        show={showEditModal}
        onHide={handleEditClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Edit Business - <i>{data.id}</i>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group className="mb-3" controlId="">
                <Form.Label>Subscription</Form.Label>
                <Form.Select
                  aria-label="Default select"
                  value={subscription}
                  onChange={(e) => setSubscription(e.target.value)}
                >
                  <option value="">Please select</option>
                  <option value="FREE">FREE</option>
                  <option value="PAID">PAID</option>
                  <option value="PREMIUM">PREMIUM</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="">
                <Form.Label>Auth Provider</Form.Label>
                <Form.Select
                  aria-label="Default select"
                  value={authProvider}
                  onChange={(e) => setAuthProvider(e.target.value)}
                >
                  <option value="">None</option>
                  <option value="Xero">Xero</option>
                  <option value="MYOB">MYOB</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="">
                <Form.Label>Processing status</Form.Label>
                <Form.Select
                  aria-label="Default select"
                  value={processingStatus}
                  onChange={(e) => setProcessingStatus(e.target.value)}
                >
                  <option value="">None</option>
                  <option value="InProgress">InProgress</option>
                  <option value="Completed">Completed</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="">
                <Form.Label>Assign Advisor</Form.Label>
                <Form.Select
                  aria-label="Default select"
                  value={advisor}
                  onChange={(e) => setAdvisor(e.target.value)}
                >
                  <option value="">None</option>
                  {advisors.map((advisor) => (
                    <option value={advisor.advisorid}>
                      {advisor.advisor}({advisor.advisoremail})
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {isLoading && (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                Saving...
              </>
            )}
            {!isLoading && <Button type="submit">Save</Button>}
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

const DeleteBusinessModal = ({
  businessId,
  isDeletingBusiness,
  isYes,
  isNo,
  showDeleteModal,
  handleDeleteClose,
  handleDeleteUsers,
  handleDeleteNoUser,
}) => {
  return (
    <div>
      {" "}
      <Modal
        show={showDeleteModal}
        onHide={handleDeleteClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {isDeletingBusiness ? "Deleting Business" : "Delete Users"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isDeletingBusiness ? (
            <div className="text-center">
              <Spinner animation="border" />
              <p>Deleting is in progress...</p>
            </div>
          ) : (
            <p>Do you want to delete the users under this business?</p>
          )}
        </Modal.Body>
        {!isDeletingBusiness && (
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleDeleteNoUser(businessId);
              }}
            >
              {isNo && (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                </>
              )}
              No
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                handleDeleteUsers(businessId);
              }}
            >
              {isYes && (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                </>
              )}
              Yes
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
};

const IsRefreshingModal = ({ isRefreshing }) => {
  return (
    <Modal
      show={isRefreshing}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="text-center">
          <Spinner animation="border" />
          <p>Refreshing is in progress...</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Businesses;
