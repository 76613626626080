import React, { useContext, useState } from "react";
import { TransparencyContext } from "../../context/Context";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import LoadingTransparencyHub from "./LoadingTransparencyHub";
import { getSubCollectionRef } from "../../helpers/store";
import { Lock } from "lucide-react";
import { max } from "lodash";

function Step3TransparencyHub() {
  const {
    transparencyQuestions,
    supplierData,
    assessmentData,
    nextStep,
    isLoading,
    maxStep,
  } = useContext(TransparencyContext);
  const [loading, setLoading] = useState(false);
  // console.log("transparencyQuestions", transparencyQuestions);
  const maxTotalScore = transparencyQuestions?.reduce((acc, curr) => {
    acc += curr.maxScore;
    return acc;
  }, 0);
  // Group questions by sectionId
  const sectionsMap = new Map();

  (transparencyQuestions || []).forEach((question) => {
    const { sectionId, section } = question;

    if (!sectionsMap.has(sectionId)) {
      sectionsMap.set(sectionId, {
        sectionId: sectionId,
        sectionName: section,
        questions: [],
      });
    }

    sectionsMap.get(sectionId).questions.push(question);
  });

  // Convert the map to an array and sort sections by 'sectionId'
  const sectionsArray = Array.from(sectionsMap.values());

  // Sort sections based on 'sectionId' (convert to number if needed)
  sectionsArray.sort((a, b) => Number(a.sectionId) - Number(b.sectionId));

  // console.log("sectionsArray", sectionsArray);

  const cardStyle = {
    marginBottom: "10px",
    cursor: "pointer",
    transition: "transform 0.2s",
  };

  const cardHoverStyle = {
    transform: "scale(1.02)",
  };

  const linkStyle = {
    textDecoration: "none",
    color: "black",
    fontSize: "18px",
    fontWeight: "bold",
    display: "block",
  };

  const subCollectionRef = getSubCollectionRef();
  const assessmentSelected = assessmentData?.find(
    (assessment) => assessment.id === subCollectionRef
  );
  let responsesTransparency = {
    responses: [],
    progress: [],
    totalScore: null,
  };
  if (assessmentSelected?.responses) {
    responsesTransparency = assessmentSelected?.responses;
    // console.log("Responses", responsesTransparency);
  }

  const handleSubmit = async () => {
    // Here you can add any additional actions needed on submit
    // setLoading(true);
    // const maxTotalScore = transparencyQuestions?.reduce((acc, curr) => {
    //   acc += curr.maxScore;
    //   return acc;
    // }, 0);
    // // console.log("transparencyQuestions", transparencyQuestions);
    // // console.log("maxTotalScore", maxTotalScore);
    // const updatedResponses = { ...responsesTransparency, maxTotalScore };
    // await setResponsesToSurvey(
    //   supplierData?.supplierId,
    //   subCollectionRef,
    //   updatedResponses
    // );
    // setMaxScore(maxTotalScore);
    // setLoading(false);
    nextStep();
  };

  // const handleSubmitAutocomplete = async () => {
  //   setLoading(true);

  //   const updatedResponses = {
  //     ...responsesTransparency,
  //     prefillRequest: true,
  //   };

  //   // Set prefill request to true in context
  //   modifyPrefillRequest();

  //   // Add in firebase prefill request to responses
  //   await setResponsesToSurvey(
  //     supplierData?.supplierId,
  //     subCollectionRef,
  //     updatedResponses
  //   );

  //   setLoading(false);
  //   nextStep();
  // };

  return (
    <Container>
      {!isLoading ? (
        <>
          {sectionsArray.map((sectionObj, index) => {
            const section = sectionObj.sectionName;
            const questions = sectionObj.questions;

            const data = responsesTransparency.progress.find(
              (sectionData) => sectionData.sectionName === section
            );
            let totalQuestions = 0;
            let totalLockedQuestions = 0;
            if (data) {
              totalQuestions = data.totalNumberQuestions;
              totalLockedQuestions = data.totalLockedQuestions || 0;
            } else {
              // At the beginning, before any responses, count non-conditional questions
              totalQuestions = questions.filter((q) => !q.conditional).length;
              totalLockedQuestions = questions.filter(
                (q) => q.conditional
              ).length;
            }

            const answeredQuestions = data?.respondedQuestions || 0;
            const unlockableQuestions = totalLockedQuestions;

            const squares = Array.from({ length: totalQuestions }, (_, i) => (
              <div
                key={i}
                style={{
                  width: "13px",
                  height: "16px",
                  backgroundColor:
                    i < answeredQuestions ? "#00d27a" : "#e0e0e0",
                  margin: "2px",
                  display: "inline-block",
                  borderRadius: 3,
                }}
              ></div>
            ));
            const textAmountQuestions = `${answeredQuestions} of ${totalQuestions} questions
                        completed`;

            return (
              <Card
                key={sectionObj.sectionId}
                style={cardStyle}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = cardHoverStyle.transform;
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "none";
                }}
              >
                <Card.Body>
                  <Row className="align-items-center">
                    <Col xs={8}>
                      <Link
                        to={`form/`}
                        state={{
                          questions: questions,
                          responses: responsesTransparency,
                          supplierId: supplierData?.supplierId,
                          sectionNumber: index + 1,
                          assessmentData: assessmentData,
                          maxTotalScore: maxTotalScore,
                        }}
                        style={linkStyle}
                      >
                        {index + 1} - {section}
                      </Link>
                    </Col>
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: 5,
                      }}
                    >
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {squares}
                      </div>
                      <span
                        style={{
                          fontSize: 13,
                          display: "flex",
                          gap: 3,
                          alignItems: "center",
                        }}
                      >
                        {textAmountQuestions}
                        {unlockableQuestions > 0 && (
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id={`tooltip-unlock-${index}`}>
                                {unlockableQuestions} locked questions may
                                become available based on your answers.
                              </Tooltip>
                            }
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 2,
                              }}
                            >
                              <span style={{ fontSize: 14 }}>
                                +{unlockableQuestions}
                              </span>
                              <Lock
                                size={16}
                                style={{
                                  color: "#6c757d",
                                }}
                              />
                            </div>
                          </OverlayTrigger>
                        )}
                      </span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            );
          })}
          <div style={{ display: "flex", justifyContent: "end", gap: 20 }}>
            {" "}
            {/* <div className="d-flex justify-content-end mt-3">
              {!prefillRequest ? (
                <Button
                  variant="outline-primary"
                  onClick={handleSubmitAutocomplete}
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Autocomplete form with the documents submitted"
                  )}
                </Button>
              ) : (
                <Button variant="outline-success" className="w-100">
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ marginRight: "5px" }}
                  />
                  Request to Autocomplete Form Submitted
                </Button>
              )}
            </div> */}
            {maxStep < 3 && (
              <div className="d-flex justify-content-end mt-3">
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={loading || !assessmentSelected?.responses}
                >
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Submit Form"
                  )}
                </Button>
              </div>
            )}
          </div>
        </>
      ) : (
        <LoadingTransparencyHub />
      )}
    </Container>
  );
}

export default Step3TransparencyHub;
