import React, { useEffect, useState } from "react";
import { Nav, Button, NavDropdown } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAuthorizationHeader } from "../../../helpers/utils";
import {
  setImpersonatedIdToken,
  setImpersonatedUserData,
  clearImpersonatedData,
  isImpersonationModeEnabled,
  getImpersonatedUserDisplayName,
  clearSessionStorage,
  getUserData,
  setImpersonatedInventoryProgress,
  setImpersonatedStepProgress,
  getWhiteLabel,
} from "../../../helpers/store";

const ImpersonateUser = () => {
  const [users, setUsers] = useState([]);
  const [userData] = useState(JSON.parse(getUserData()));
  const [role] = useState(userData.role);
  const subdomain = getWhiteLabel().subdomain;

  const getAllUsers = async () => {
    const response = await fetch(`/api/users/get-all?subdomain=${subdomain}`, {
      method: "GET",
      headers: getAuthorizationHeader(),
    });

    if (response.status === 200) {
      setUsers(await response.json());
      //console.log(users);
    }
  };

  const getAdvisorUsers = async () => {
    // console.log("Advisor users", userData.uid);
    const response = await fetch(
      `/api/users/get-advisor-users/${userData.uid}`,
      {
        method: "GET",
        headers: getAuthorizationHeader(),
      }
    );

    if (response.status === 200) {
      const users = await response.json();
      // console.log("Advisor users", users);
      setUsers(users);
    }
  };

  const impersonateUser = async (uid) => {
    const response = await fetch(`/api/users/user-impersonate/${uid}`, {
      method: "GET",
      headers: getAuthorizationHeader(),
    });

    if (response.status !== 200) {
      toast.error("Failed to impersonate user");
      return;
    }
    const data = await response.json();
    console.log("DATA FOR IMPERSONATION", data);
    setImpersonatedIdToken(data.idToken);
    setImpersonatedUserData(JSON.stringify(data.user));
    setImpersonatedInventoryProgress(JSON.stringify(data.inventoryProgress));
    setImpersonatedStepProgress(JSON.stringify(data.stepsProgress));
    clearSessionStorage();
    window.location.href = "/welcome";
  };

  const signOutImpersonateUser = async () => {
    clearImpersonatedData();
    clearSessionStorage();
  };

  useEffect(() => {
    if (role === "ADVISOR") {
      getAdvisorUsers();
    } else {
      getAllUsers();
    }
  }, [role]);

  return (
    <>
      {/* {!isImpersonationModeEnabled() && (
        <Nav.Item as="li">
          <Button
            variant="outline-primary"
            className="rounded-pill me-0 mb-0"
            size="sm"
            onClick={() => impersonateUser()}
          >
            Impersonate User
          </Button>
        </Nav.Item>
      )} */}

      {isImpersonationModeEnabled() && (
        <Nav.Item as="li">
          <Button
            variant="outline-primary"
            className="rounded-pill me-0 mb-0"
            size="sm"
            onClick={() => signOutImpersonateUser()}
          >
            Exit Impersonation
          </Button>
        </Nav.Item>
      )}

      {users.length > 0 && (
        <Nav.Item as="li">
          <NavDropdown
            title={getImpersonatedUserDisplayName() || "Impersonate User"}
            id="navbarScrollingDropdown"
          >
            <div
              className="py-2 bg-light dark__bg-1000 rounded-3"
              style={{ "max-height": "400px", "overflow-y": "auto" }}
            >
              {users.map((user) => (
                <NavDropdown.Item
                  key={user.id}
                  onClick={() => impersonateUser(user.id)}
                >
                  {user.email}
                </NavDropdown.Item>
              ))}
            </div>
          </NavDropdown>
        </Nav.Item>
      )}
      <ToastContainer />
    </>
  );
};

export default ImpersonateUser;
