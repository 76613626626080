import React from "react";

import BarChart from "./BarChart";
import { computeDecimalsToShow, generateCalculatorText } from "./chartHelpers";

function CalculatorCard({ data, keyProp, color }) {
  //   console.log(keyProp)
  const calculatorData = data?.map((item) => ({
    month: item.month,
    [keyProp]: item[keyProp]?.toFixed(3),
  }));

  const keys = [keyProp];
  let title;
  let url;
  // console.log("calculatorData", calculatorData);
  if (keyProp === "WFH") {
    title = "Work From Home";
    url = "/welcome/data/employee-engagement";
  } else if (keyProp === "EmployeeCommute") {
    title = "Employee Commute";
    url = "/welcome/data/employee-engagement";
  } else if (keyProp === "Flights") {
    title = keyProp;
    url = "/welcome/data/climate-active/flights-form";
  } else if (keyProp === "Paper") {
    title = keyProp;
    url = "/welcome/data/climate-active/paper-form";
  } else if (keyProp === "Water") {
    title = keyProp;
    url = "/welcome/data/water";
  } else {
    title = keyProp;
    url = "/welcome/data/calculations";
  }

  const colorsChart = [color];

  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <h4>{title}</h4>
        <p>{generateCalculatorText(keyProp, calculatorData)}</p>
      </div>
      <BarChart
        data={calculatorData}
        colorTheme={colorsChart}
        keys={keys}
        title={`${title} Data`}
        skipTotals={true}
        leftLegendOffset={-50}
        urlAddData={url}
        decimalsToShow={computeDecimalsToShow(calculatorData, keys)}
      />
    </>
  );
}

export default CalculatorCard;
