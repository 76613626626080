import React, { useEffect, useState } from "react";
import { useNavigate, useBlocker } from "react-router-dom";
import { Button, Modal, Spinner } from "react-bootstrap";
import { OfficeLocationsTable } from "./OfficeLocationsTable";
import {
  saveOfficeLocationChanges,
  updateOfficesDetailsFromWizard,
} from "./OfficeLocationManagementApi";
import { toast } from "react-toastify";
import {
  getActiveUserData,
  getAllLocationsFromStorage,
  getInventoryProgress,
  setActiveUserData,
  toSetLocationsToStorage,
} from "../../helpers/store";

import { v4 as uuidv4 } from "uuid"; // Import UUID library to generate temp IDs
import {
  isStepCompleted,
  updateStepProgress,
} from "../../helpers/stepsProgress";
import OfficeLocationManagementHeader from "./OfficeLocationManagementHeader";
import {
  UpdateLocation,
  UpdateSuppliersCollection,
} from "../OrganisationDetails/organisationDetailsAPI";
import LocationModal from "./LocationModal";

export default function OfficeLocationManagement({
  inventoryProgress,
  allLocations = [],
  setAllLocations,
  updateInventoryProgressInState,
  reportingPeriod,
  section,
  totalQuestions,
  loading,
  title,
  setLocationIsSelected,
  primaryCategory,
  secondaryCategory,
}) {
  const navigate = useNavigate();

  const [activeUser] = useState(JSON.parse(getActiveUserData()));
  // State management for locations and original locations
  const [locations, setLocations] = useState([]);
  const [originalLocations, setOriginalLocations] = useState();
  const [isChangesMade, setIsChangesMade] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editedLocations, setEditedLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isAddingLocation, setIsAddingLocation] = useState(false);
  const [location, setLocation] = useState({
    locationName: "",
    country: "",
    countryCode: "",
    state: "",
    stateCode: "",
    streetAddress: "",
    city: "",
    postcode: "",
  });
  const [selectedCount, setSelectedCount] = useState(0);

  const [currentInventoryProgress, setCurrentInventoryProgress] = useState(
    JSON.parse(getInventoryProgress())
  );
  // console.log("allLocations", allLocations);
  // Function to check if any changes were made (selected flag, length, or data)
  const checkIfChangesMade = (updatedLocations) => {
    // Check if the number of locations has changed
    if (updatedLocations.length !== originalLocations.length) {
      console.log("Location count has changed");
      setIsChangesMade(true);
      return;
    }

    const changesExist = updatedLocations.some((location) => {
      const originalLocation = originalLocations.find(
        (origLoc) => origLoc.officeId === location.officeId
      );

      // Check if selection flag has changed
      if (originalLocation && location.selected !== originalLocation.selected) {
        console.log("Selection flag has changed");
        return true;
      }

      // Check if any editable field has changed
      if (originalLocation) {
        return (
          originalLocation.locationName !== location.locationName ||
          originalLocation.country !== location.country ||
          originalLocation.state !== location.state ||
          originalLocation.streetAddress !== location.streetAddress ||
          originalLocation.city !== location.city ||
          originalLocation.postcode !== location.postcode
        );
      }

      return false;
    });

    setIsChangesMade(changesExist);
  };

  // Toggle selection of locations and update count
  const handleToggleSelect = (officeId) => {
    const updatedLocations = locations.map((location) =>
      location.officeId === officeId
        ? { ...location, selected: !location.selected }
        : location
    );

    const selectedCount = updatedLocations.filter((loc) => loc.selected).length;

    setLocations(updatedLocations);
    setSelectedCount(selectedCount);
    checkIfChangesMade(updatedLocations);
  };

  // Edit location
  const handleEditLocation = (updatedLocation) => {
    const updatedList = locations.map((location) =>
      location.officeId === updatedLocation.officeId
        ? updatedLocation
        : location
    );
    setLocations(updatedList);

    if (
      !editedLocations.some((loc) => loc.officeId === updatedLocation.officeId)
    ) {
      console.log("Location not found in edited list");
      setEditedLocations([...editedLocations, updatedLocation]);
    }

    // Check if any changes were made after editing
    checkIfChangesMade(updatedList);
  };

  // Add a new location with react-hook-form validation
  const onAddLocationSubmit = (data) => {
    if (
      data.locationName &&
      data.country &&
      data.state &&
      data.streetAddress &&
      data.city &&
      data.postcode
    ) {
      let userId = activeUser.businessId.replace("Business", "");
      let defaultOfficeId = `Office${userId}`;

      // Check if this is the first location being added to set it as default
      const isFirstNewLocation = !locations.some(
        (location) => location.officeId
      );
      const isDefaultOffice = activeUser.hasDefaultOffice && isFirstNewLocation;

      const newLocationEntry = {
        ...data,
        selected: false,
        officeId: isDefaultOffice ? defaultOfficeId : "",
        tempId: isDefaultOffice ? "Default" : uuidv4(),
        business_id: activeUser.businessId,
      };

      const updatedLocations = [...locations, newLocationEntry];
      setLocations(updatedLocations);

      setSelectedCount(
        updatedLocations.filter((location) => location.selected).length
      );

      checkIfChangesMade(updatedLocations);

      toast.success("Location added successfully!");
    } else {
      toast.error("Please fill in all required fields.");
    }
  };

  // Save changes
  async function handleSave(event) {
    try {
      if (event) event.preventDefault();
      setIsLoading(true);

      let updatedLocations = [...locations];

      // console.log("updatedLocations just when calling save", updatedLocations);
      // Step 1: Handle new locations
      const newLocations = updatedLocations.filter(
        (location) => !location.officeId || location.tempId === "Default"
      );

      //Set the first added location as the default,look for the location with the tempId starting with Office
      if (activeUser.hasDefaultOffice) {
        const firstNewLocation = newLocations.find((location) =>
          location.tempId === "Default" ? location : null
        );
        if (firstNewLocation) {
          let body = {
            businessId: activeUser.businessId,
            businessName: activeUser.businessName,
            country: firstNewLocation.country,
            state: firstNewLocation.state,
            primaryCategory: primaryCategory,
            secondaryCategory: secondaryCategory,
          };

          // add new client ot Supplier collection
          await UpdateSuppliersCollection(body);

          //Update 'hasDefaultOffice' to false
          const userData = JSON.parse(getActiveUserData());
          userData["hasDefaultOffice"] = false;
          setActiveUserData(JSON.stringify(userData));
          toast("First location added as default office.");
        }
      }

      // Send new locations to the backend and get back officeIds
      if (newLocations.length > 0) {
        const newAddedLocations = await saveOfficeLocationChanges(newLocations);

        const newAddedLocationsArray = Object.values(newAddedLocations);

        // If no new locations were added, stop the process
        if (newAddedLocationsArray.length === 0) {
          setIsLoading(false);
          return;
        }

        if (newAddedLocationsArray && newAddedLocationsArray.length > 0) {
          // Replace temporary IDs with actual officeIds from the backend
          const finalUpdatedLocations = updatedLocations.map((location) => {
            const addedLocation = newAddedLocationsArray.find(
              (newLoc) => newLoc.tempId === location.tempId
            );
            return addedLocation
              ? {
                  ...location,
                  officeId: addedLocation.officeId || addedLocation.office_id,
                }
              : location;
          });

          updatedLocations = finalUpdatedLocations;

          toSetLocationsToStorage(finalUpdatedLocations);
          toast(`Successfully added new location`);
        }
      }

      // Step 2: Handle edited locations
      if (editedLocations.length > 0) {
        for (const editedLocation of editedLocations) {
          await UpdateLocation(editedLocation);
        }

        // set modified locations to session storage
        toSetLocationsToStorage(editedLocations);
        toast(`Successfully updated location details`);
      }
      //SELECTED LOCATIONS IS EMPTY RIGHT AFTER REGISTERING
      // Step 3: Handle selected locations
      const selectedLocations = updatedLocations
        .filter((location) => location.selected)
        .map((location) => location.officeId);
      // console.log("selectedLocations", selectedLocations);
      if (selectedLocations.length > 0 && isChangesMade) {
        if (setLocationIsSelected) setLocationIsSelected(true);
      } else if (setLocationIsSelected) setLocationIsSelected(false);

      //Update selected locations in the backend
      const locationsBody = {
        locations: selectedLocations,
      };
      // Get the inventory id from the inventory progress state
      const inventoryId = inventoryProgress.inventoryid;
      // Update the selected offices in the backend
      await updateOfficesDetailsFromWizard(locationsBody, inventoryId);
      // Update inventory progress state with new selected locations
      updateInventoryProgressInState(locationsBody);

      // 4. Calculate completed questions
      const locationStepCompleted = isStepCompleted("locations-selected");
      if (!locationStepCompleted) {
        //UPDATE PROGRESS
        const stringId = "locations-selected";
        const response = await updateStepProgress({
          stringId,
          status: "completed",
          activeUser,
          currentInventoryProgress,
          onUpdateState: false,
          onUpdateInventoryProgress: false,
        });
        if (response.success) {
          toast.success(response.text);
        } else {
          toast.error(response.text);
        }
      }
      // let completedQuestions = 0;
      // if (locationsBody.locations) completedQuestions += 1;
      // completedQuestions = Math.min(completedQuestions, totalQuestions);
      // updateSectionProgress(section, completedQuestions);

      // If the user does not have a default office means its old user
      // If user has default office it might be doing the onboarding wizard and we do not want to redirect him!
      if (!activeUser.hasDefaultOffice) {
        // Step 6: Redirect user based on session state
        if (
          sessionStorage.getItem("FileAddLocation") !== undefined &&
          sessionStorage.getItem("FileAddLocation") === "true"
        ) {
          sessionStorage.removeItem("FileAddLocation");
          navigate("/welcome/data/file-uploader");
        } else {
          navigate("/welcome/new-overview"); // Navigate to the new route
        }
      }

      // Step 7: Update originalLocations and reset flags after successful save
      const finalLocations = await getAllLocationsFromStorage();
      setAllLocations(finalLocations);
      setOriginalLocations(updatedLocations); // Sync originalLocations with saved state
      setLocations(updatedLocations); // Sync locations with saved state
      setIsChangesMade(false); // Reset changes flag

      setIsLoading(false);
      toast.success(`Locations Saved Successfully!`);
    } catch (error) {
      console.error("Error saving locations:", error);
      toast.error(
        "An error occurred while saving locations. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  }

  // Cancel changes
  const handleCancel = () => {
    setLocations(originalLocations);
    setIsChangesMade(false);
  };

  // Function to handle add location click
  const handleAddLocationClick = () => {
    setSelectedLocation(null);
    // Set add mode, clear any existing location data
    setIsAddingLocation(true);
    setIsEditMode(false); // New state to track if in edit or add mode
    setLocation({
      locationName: "",
      country: "",
      countryCode: "",
      state: "",
      stateCode: "",
      streetAddress: "",
      city: "",
      postcode: "",
    });
  };

  // Function to clear unsaved changes by resetting locations
  const handleClearChanges = () => {
    setLocations(originalLocations);
    setSelectedCount(
      originalLocations.filter((location) => location.selected).length
    );
    setIsChangesMade(false);
  };

  const handleCloseModal = () => {
    // Clear form fields by resetting the state
    const emptyLocation = {
      locationName: "",
      country: "",
      countryCode: "",
      state: "",
      stateCode: "",
      streetAddress: "",
      city: "",
      postcode: "",
    };

    setLocation(emptyLocation); // Reset location state to empty

    // Reset the form fields in the react-hook-form context
    // reset(emptyLocation);

    // Hide the modal
    setIsAddingLocation(false);

    // Reset the edit mode flag
    setIsEditMode(false);
  };

  // Initialize locations and selected count based on allLocations
  useEffect(() => {
    setOriginalLocations(allLocations);
    if (allLocations.length > 0) {
      setLocations(allLocations);
      setSelectedCount(
        allLocations.filter((location) => location.selected).length
      );

      // Update location selection status based on selected count
      if (setLocationIsSelected && !isChangesMade) {
        setLocationIsSelected(allLocations.some((loc) => loc.selected));
      }
    }
  }, [allLocations]);

  // Add event listener for beforeunload to prevent accidental navigation
  // useBlocker will prompt the user if they attempt to navigate away
  // useBlocker(() => {
  //   if (isChangesMade) {
  //     return window.confirm(
  //       "You have unsaved changes. Are you sure you want to leave?"
  //     );
  //   }
  // }, isChangesMade);
  // Block navigating elsewhere when data has been entered into the input
  let blocker = useBlocker(({ currentLocation, nextLocation }) =>
    isChangesMade && nextLocation.pathname !== currentLocation.pathname
      ? "Are you sure you want to leave?"
      : undefined
  );
  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "200px" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );

  return (
    <div className="w-100 max-w-4xl">
      {/* Navigation Prompt Modal */}
      {/* {blocker.state === "blocked" ? (
        <div>
          <p>Are you sure you want to leave?</p>
          <button onClick={() => blocker.proceed()}>Proceed</button>
          <button onClick={() => blocker.reset()}>Cancel</button>
        </div>
      ) : null} */}
      <Modal show={blocker.state === "blocked"} onHide={blocker.reset}>
        <Modal.Header closeButton>
          <Modal.Title>Unsaved Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You have unsaved changes. Are you sure you want to leave this page?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => blocker.proceed()}>
            Leave Anyway
          </Button>
          <Button variant="primary" onClick={() => blocker.reset()}>
            Stay Here
          </Button>
        </Modal.Footer>
      </Modal>

      <OfficeLocationManagementHeader
        reportingPeriod={reportingPeriod}
        title={title}
        selectedCount={selectedCount}
        onAddLocation={handleAddLocationClick}
        isChangesMade={isChangesMade}
        handleSave={handleSave}
        handleClearChanges={handleClearChanges}
        isLoading={isLoading}
      />

      <OfficeLocationsTable
        locations={locations}
        setLocations={setLocations}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        setIsEditMode={setIsEditMode}
        setIsAddingLocation={setIsAddingLocation}
        handleToggleSelect={handleToggleSelect}
        handleEditLocation={handleEditLocation}
      />
      {isAddingLocation && (
        <LocationModal
          showModal={isAddingLocation}
          handleCloseModal={handleCloseModal}
          handleSave={onAddLocationSubmit}
          location={location}
          setLocation={setLocation}
          mode={"add"} // Toggle between edit and add modes
        />
      )}

      {/* <AddLocationModal
        showModal={isAddingLocation}
        handleCloseModal={handleCloseModal}
        newLocation={newLocation}
        setNewLocation={setNewLocation}
        handleAddLocation={onAddLocationSubmit}
        register={register}
        errors={errors}
        clearErrors={clearErrors}
        setValue={setValue}
        trigger={trigger}
      /> */}

      {/* <AddLocationForm
        isAddingLocation={isAddingLocation}
        setIsAddingLocation={setIsAddingLocation}
        newLocation={newLocation}
        setNewLocation={setNewLocation}
        handleAddLocation={handleSubmit(onAddLocationSubmit)}
        register={register}
        errors={errors}
        clearErrors={clearErrors}
        setValue={setValue}
      /> */}

      {/* <div className="d-flex justify-content-between mt-4">
        <Button
          variant="outline-secondary"
          onClick={handleCancel}
          disabled={!isChangesMade || isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSave}
          disabled={!isChangesMade || isLoading}
        >
          {isLoading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            "Save Changes"
          )}
        </Button>
      </div> */}
    </div>
  );
}
