export function shouldDisplayQuestion(
  allQuestions,
  question,
  formValues,
  initialResponses
) {
  if (!question.conditional) {
    return true; // No condition, always display
  }
  //   console.log("question", question);
  //   console.log("allQuestions", allQuestions);
  //   console.log("formValues", formValues);
  //   console.log("initialResponses", initialResponses);
  const { dependsOn, value, typeDependency = "value" } = question.conditional;
  //Find the id of the key
  const questionLinked = allQuestions?.find((q) => q.key === dependsOn);
  if (!questionLinked) {
    console.error(`Dependency question with key '${dependsOn}' not found.`);
    return false; // Hide the question if the dependency is not found
  }

  const dependencyValue = formValues[questionLinked.id];

  let conditionMet = false;

  switch (typeDependency) {
    case "value":
      conditionMet = dependencyValue === value;
      break;
    case "greaterThan":
      {
        const depValueNum = parseFloat(dependencyValue);
        const valueNum = parseFloat(value);
        if (!isNaN(depValueNum) && !isNaN(valueNum)) {
          conditionMet = depValueNum > valueNum;
        } else {
          conditionMet = false;
        }
      }
      break;
    case "lessThan":
      {
        const depValueNum = parseFloat(dependencyValue);
        const valueNum = parseFloat(value);
        if (!isNaN(depValueNum) && !isNaN(valueNum)) {
          conditionMet = depValueNum < valueNum;
        } else {
          conditionMet = false;
        }
      }
      break;
    case "any":
      conditionMet = dependencyValue != null && dependencyValue !== "";
      break;
    default:
      conditionMet = dependencyValue === value;
  }

  const hasPreviousResponse =
    initialResponses[question.id] != null &&
    initialResponses[question.id] !== "";

  return conditionMet || hasPreviousResponse;
}
