import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import BarChart from "./BarChart";
import { Link } from "react-router-dom";
import { computeDecimalsToShow } from "./chartHelpers";

function AllCalculators({ data }) {
  const keys = ["EmployeeCommute", "Flights", "Paper", "WFH", "Waste", "Water"];
  const calculatorRoundData = data?.map(
    ({ EmployeeCommute, Flights, Paper, WFH, Waste, Water, month }) => ({
      month,
      // EmployeeCommute: Math.round(EmployeeCommute),
      // Flights: Math.round(Flights),
      // Paper: Math.round(Paper),
      // WFH: Math.round(WFH),
      // Waste: Math.round(Waste),
      EmployeeCommute: Number(EmployeeCommute?.toFixed(3)),
      Flights: Number(Flights?.toFixed(3)),
      Paper: Number(Paper?.toFixed(3)),
      WFH: Number(WFH?.toFixed(3)),
      Waste: Number(Waste?.toFixed(3)),
      Water: Number(Water?.toFixed(3)),
    })
  );
  const colors = [
    "#00688b", // Slightly Lighter Dark Blue
    "#4da6cc", // Light Blue
    "#9ec5d3", // Very Light Blue
    "#ffd099", // Light Orange
    "#ffb066", // Lighter Orange
    "#fb9314", // Brighter Orange
  ];
  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <h4>Emissions From Calculators</h4>
        <p>
          The Calculators section includes various methods where you can upload
          detailed information about different activities that significantly
          impact the company’s emissions. You can find the calculators
          <Link to="/welcome/data/calculations" style={{ marginLeft: 5 }}>
            here
          </Link>
          . All the data entered here is incorporated as Scope 3 emissions and
          represents the most accurate data we have. For example, a bill from
          Emirates is not as precise as the details of the destination, time,
          and class of a flight that you can input in our Calculators.
        </p>
        <p>You can navigate through each of them at the top.</p>
      </div>

      <Row>
        <Col lg={12}>
          <BarChart
            data={calculatorRoundData}
            colorTheme={colors}
            keys={keys}
            title={` Data`}
            // skipTotals={true}
            urlAddData="/welcome/data/calculations"
            decimalsToShow={computeDecimalsToShow(calculatorRoundData, keys)}
          />
        </Col>
      </Row>
    </>
  );
}

export default AllCalculators;
