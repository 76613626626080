import React, { useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import FlatFileUploader from "../../common/FlatFileUploader";
import { flatFileWorkbook } from "./flatFileConfig";
import { flatfileEventListener } from "./flatFilelistener";

export const BusinessExpenses = ({ metadata, disableInput }) => {
  const [showModal, setShowModal] = useState(false);
  const [currency, setCurrency] = useState("");

  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
  };

  return (
    <div className="space-y-4">
      <p
        style={{
          color: "red",
          fontWeight: "bold",
          fontSize: "1.1rem",
          marginTop: "1rem",
        }}
      >
        If you are uploading business expenses, you need to upload a CSV file.
        You can download the template{" "}
        <a
          href="https://docs.google.com/spreadsheets/d/1MRp2S2K_nVC_b6B2Qgw5bkkzxy08i7JRsfOQLBzrhwU/copy"
          target="_blank"
          rel="noreferrer"
        >
          here <FontAwesomeIcon icon={faFileExcel} />
        </a>
        .
      </p>
      <p className="text-sm text-muted-foreground">
        For Business Expenses, we use Flatfile to ensure data accuracy and
        consistency. Click the button below to open the Flatfile importer.
      </p>

      {/* Currency Selection */}
      <Form.Group className="mt-4">
        <FloatingLabel
          controlId="floatingSelect"
          label="Choose the currency of the expenses"
        >
          <Form.Select
            as="select"
            value={currency}
            onChange={handleCurrencyChange}
            disabled={disableInput}
          >
            <option value="">Select Currency</option>
            <option value="USD">USD - United States Dollar</option>
            <option value="AUD">AUD - Australian Dollar</option>
            <option value="EUR">EUR - Euro</option>
            <option value="INR">INR - Indian Rupee</option>
            <option value="GBP">GBP - British Pound</option>
            {/* Add more currencies as needed */}
          </Form.Select>
        </FloatingLabel>
      </Form.Group>

      {/* Open Flatfile Importer button */}
      <div className="d-flex justify-content-between mt-4">
        <div style={{ width: "100px" }} />
        <Button
          onClick={() => setShowModal(!showModal)}
          disabled={
            !metadata.category ||
            !metadata.officeId ||
            !metadata.startDate ||
            !metadata.endDate ||
            !currency ||
            disableInput
          }
        >
          Open Flatfile Importer
        </Button>
      </div>

      {showModal && (
        <FlatFileUploader
          metadata={{ ...metadata, currency }}
          config={{
            title: "Data Uploader",
            workbook: flatFileWorkbook,
            listener: flatfileEventListener,
            operation: "expenses:submit",
            redirectUrl: "/welcome/data/all-invoices",
          }}
        />
      )}
    </div>
  );
};
