import React, { useEffect } from "react";
import {
  Spinner,
  Col,
  Row,
  Modal,
  Form,
  Button,
  Dropdown,
  FloatingLabel,
} from "react-bootstrap";
import { useState } from "react";
import WidgetSectionTitle from "../../widgets/WidgetSectionTitle";
import AdvanceTableWrapper from "../common/advance-table/AdvanceTableWrapper";
import { Card } from "react-bootstrap";
import AdvanceTable from "../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../common/advance-table/AdvanceTableFooter";
import AdvanceTableSearchBox from "../common/advance-table/AdvanceTableSearchBox";
import {
  getUsers,
  getBusinessByUserId,
  updateUser,
  deleteUserByUserId,
  getBusinesses,
  getOffices,
  updateBusinessByUserId,
} from "./adminApi";
import Flex from "../common/Flex";
import { useAuth } from "../../Contexts/AuthContext";
import CardDropdown from "../common/CardDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faChevronRight,
  faList,
} from "@fortawesome/free-solid-svg-icons";

const Users = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useAuth();
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [editUser, setEditUser] = useState(null);

  const columns = [
    {
      accessor: "",
      Header: "Actions",
      Cell: (rowData) => {
        return (
          <>
            <CardDropdown>
              <div className="py-2">
                {(currentUser.role === "SUPER_ADMIN" ||
                  currentUser.role === "MANAGER" ||
                  currentUser.role === "WHITELABEL_SUPER_ADMIN") && (
                  <Dropdown.Item
                    as="button"
                    onClick={() => handleEditOpen(rowData.row.original)}
                  >
                    Edit Details
                  </Dropdown.Item>
                )}
                {(currentUser.role === "SUPER_ADMIN" ||
                  currentUser.role === "WHITELABEL_SUPER_ADMIN") && (
                  <Dropdown.Item
                    as="button"
                    onClick={() => handleMergeOpen(rowData.row.original)}
                  >
                    Merge
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  className="text-danger"
                  as="button"
                  onClick={() => handleDelete(rowData.row.original.id)}
                >
                  Delete User
                </Dropdown.Item>
              </div>
            </CardDropdown>
          </>
        );
      },
    },
    {
      accessor: "id",
      Header: "User Id",
      headerProps: { className: "text-left" },
      // cellProps: {
      //   className: "fs-0",
      // },
      // cellProps: {
      //   className: "text-wrap",
      // },
      Cell: (rowData) => {
        const { id } = rowData.row.original;

        return (
          <Flex>
            <div>
              <a href="#" onClick={() => handleOpen(id)}>
                {id}
              </a>
            </div>
          </Flex>
        );
      },
    },
    {
      accessor: "email",
      Header: "Email",
    },
    {
      accessor: "role",
      Header: "Role",
    },
    {
      accessor: "name",
      Header: "Name",
    },
    {
      accessor: "title",
      Header: "Title",
    },
    {
      accessor: "mobphone",
      Header: "Mobile",
    },

    {
      accessor: "created_at",
      Header: "Created At",
    },
    {
      accessor: "updated_at",
      Header: "Updated At",
    },
  ];

  async function handleClose(isRefresh) {
    setShowModal(false);
    if (isRefresh) {
    }
  }

  function handleOpen(userId) {
    setUserId(userId);
    setShowModal(true);
  }

  function handleEditOpen(user) {
    setEditUser(user);
    setShowEditModal(true);
  }

  async function handleEditClose(isRefresh) {
    setShowEditModal(false);
    if (isRefresh) {
      await setUsersToState();
    }
  }

  async function handleDelete(userId) {
    setUserId(userId);
    setShowDeleteModal(true);
    await deleteUserByUserId({ id: userId });
    setShowDeleteModal(false);
    await setUsersToState();
  }

  // handleDeleteClose
  async function handleDeleteClose() {
    setShowDeleteModal(false);
    await setUsersToState();
  }

  //handleMergeOpen
  async function handleMergeOpen(user) {
    setEditUser(user);
    setShowMergeModal(true);
  }
  //handleMergeClose
  async function handleMergeClose(isRefresh) {
    setShowMergeModal(false);
    if (isRefresh) {
      await setUsersToState();
    }
  }

  async function setUsersToState() {
    const response = await getUsers();
    for (let i = 0; i < response.length; i++) {
      //set name column as "given" + " " + "family"
      response[i].name = response[i].given + " " + response[i].family;
    }
    setUsers(response);
    setIsLoading(false);
  }

  useEffect(() => {
    setUsersToState();
  }, []);

  return (
    <>
      <WidgetSectionTitle
        icon={faList}
        title="Users"
        subtitle={
          currentUser.role === "SUPER_ADMIN" ||
          currentUser.role === "WHITELABEL_SUPER_ADMIN"
            ? "List of all the users"
            : "List of users in your business"
        }
        transform="shrink-2"
        className="mb-4 mt-6"
      />

      {showModal && (
        <BusinessModal
          data={{ userId }}
          showModal={showModal}
          handleClose={handleClose}
        />
      )}

      {showEditModal && (
        <EditUserModal
          showEditModal={showEditModal}
          handleEditClose={handleEditClose}
          data={{ ...editUser }}
          currentUser={currentUser}
        />
      )}

      {showDeleteModal && (
        <DeleteUserModal
          showDeleteModal={showDeleteModal}
          handleDeleteClose={handleDeleteClose}
          data={{ userId }}
        />
      )}

      {showMergeModal && (
        <MergeUserModal
          showMergeModal={showMergeModal}
          handleMergeClose={handleMergeClose}
          data={{ ...editUser }}
        />
      )}

      {isLoading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999, // Adjust the z-index as needed
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="mb-3">
          <AdvanceTableWrapper
            columns={columns}
            data={users}
            sortable
            pagination
            perPage={25}
            rowCount={users.length}
          >
            <Card>
              <Card.Header>
                <Row className="flex-end-center mb-3">
                  <Col xs="auto" sm={6} lg={4}>
                    <AdvanceTableSearchBox table />
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="btn-reveal-trigger text-nowrap align-middle"
                  tableProps={{
                    size: "lg",
                    className: "fs--1 mb-0 overflow-hidden",
                  }}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTableFooter
                  data={users}
                  rowCount={users.length}
                  table
                  rowInfo
                  navButtons
                />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        </div>
      )}
    </>
  );
};

const BusinessModal = ({ showModal, handleClose, data }) => {
  const [businesses, setBusinesses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      accessor: "id",
      Header: "Business Id",
    },
    {
      accessor: "name",
      Header: "Business name",
    },
    {
      accessor: "subscription",
      Header: "Subscription",
    },

    {
      accessor: "xerostatus",
      Header: "Xero status",
    },
    {
      accessor: "office_id",
      Header: "Office Id",
    },
    {
      accessor: "office_name",
      Header: "Office name",
    },
  ];

  async function getBusiness(userId) {
    const response = await getBusinessByUserId(userId);
    setBusinesses(response);
    setIsLoading(false);
  }

  useEffect(() => {
    getBusiness(data.userId);
  }, []);

  return (
    <div>
      {" "}
      <Modal
        show={showModal}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Businesses
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              <div className="mb-3">
                <AdvanceTableWrapper
                  columns={columns}
                  data={businesses}
                  sortable
                  pagination
                  perPage={5}
                  rowCount={businesses.length}
                >
                  <Card>
                    {/* <Card.Header>
                      {" "}
                      <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                        Businesses
                      </h5>
                    </Card.Header> */}
                    <Card.Body className="p-0">
                      <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="btn-reveal-trigger text-nowrap align-middle"
                        tableProps={{
                          size: "lg",
                          className: "fs--1 mb-0 overflow-hidden",
                        }}
                      />
                    </Card.Body>
                    <Card.Footer>
                      <AdvanceTableFooter
                        data={businesses}
                        rowCount={businesses.length}
                        table
                        rowInfo
                        navButtons
                      />
                    </Card.Footer>
                  </Card>
                </AdvanceTableWrapper>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

const EditUserModal = ({
  showEditModal,
  handleEditClose,
  data,
  currentUser,
}) => {
  const [role, setRole] = useState(data.role);

  async function handleSubmit(e) {
    e.preventDefault();
    await updateUser({ ...data, role });
    handleEditClose(true);
  }

  return (
    <div>
      {" "}
      <Modal
        show={showEditModal}
        onHide={handleEditClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Edit User -{" "}
              <i>
                {data.name}({data.id})
              </i>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group className="mb-3" controlId="">
                <Form.Label>Role</Form.Label>
                <Form.Select
                  aria-label="Default select"
                  value={role}
                  required
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value="">Please select</option>
                  <option value="EMPLOYEE">EMPLOYEE</option>
                  <option value="MANAGER">MANAGER</option>
                  <option value="ADVISOR">ADVISOR</option>
                  {currentUser.role === "SUPER_ADMIN" && (
                    <>
                      <option value="CUSTOMER">CUSTOMER</option>
                      <option value="SUPER_ADMIN">SUPER_ADMIN</option>
                      <option value="SUPPLIER">SUPPLIER</option>
                      <option value="WHITELABEL_SUPER_ADMIN">
                        WHITELABEL_SUPER_ADMIN
                      </option>
                    </>
                  )}
                </Form.Select>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" disabled={!role}>
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

const DeleteUserModal = ({ showDeleteModal, handleDeleteClose, data }) => {
  return (
    <Modal
      show={showDeleteModal}
      onHide={handleDeleteClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Delete User - <i>{data.userId}</i>{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <Spinner animation="border" />
          <p>Deleting is in progress...</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const MergeUserModal = ({ showMergeModal, handleMergeClose, data }) => {
  const [userBusiness, setUserBusiness] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState("");
  const [offices, setOffices] = useState([]);
  const [selectedOffice, setSelectedOffice] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteQuestion, setShowDeleteQuestion] = useState(false);
  const [isYes, setIsYes] = useState(false);
  const [isNo, setIsNo] = useState(false);

  useEffect(() => {
    // Fetch businesses and offices
    fetchBusinessesAndOffices();
  }, []);

  const fetchBusinessesAndOffices = async () => {
    await fetchBusinesses();
    await fetchUsersBusiness();
  };

  const fetchBusinesses = async () => {
    setIsLoading(true);
    const mockBusinesses = await getBusinesses();
    //order by name
    mockBusinesses.sort((a, b) => (a.name > b.name ? 1 : -1));

    const mockOffices = await getOffices();

    setBusinesses(mockBusinesses);
    setOffices(mockOffices);
    setIsLoading(false);
  };

  const fetchUsersBusiness = async () => {
    const businesses = await getBusinessByUserId(data.id);
    setUserBusiness(businesses[0]);
  };

  const handleBusinessChange = (event) => {
    const selectedBusinessId = event.target.value;
    setSelectedBusiness(selectedBusinessId);

    // Update the selected office when business changes
    setSelectedOffice("");
  };

  const handleOfficeChange = (event) => {
    const selectedOfficeId = event.target.value;
    setSelectedOffice(selectedOfficeId);
  };

  const handleSubmit = async (isDelete) => {
    if (isDelete) {
      setIsYes(true);
    } else {
      setIsNo(true);
    }

    await updateBusinessByUserId({
      isDelete: isDelete,
      userId: data.id,
      existingBusinessId: userBusiness.id,
      newBusinessId: selectedBusiness,
      officeId: selectedOffice,
    });

    if (isDelete) {
      setIsYes(false);
    } else {
      setIsNo(false);
    }

    // Close the modal after submitting
    handleMergeClose(true);
  };

  const handleProceed = async () => {
    setShowDeleteQuestion(true);
  };

  return (
    <div>
      {" "}
      <Modal
        show={showMergeModal}
        onHide={handleMergeClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {!showDeleteQuestion && (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>
                Merge to new business - <i>{data.name}</i>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Select a business:
                    {isLoading && (
                      <Spinner
                        animation="grow"
                        role="status"
                        size="sm"
                        style={{ color: "#f5803e", marginRight: "4px" }}
                      ></Spinner>
                    )}
                  </Form.Label>
                  <FloatingLabel label="Business Selection">
                    <Form.Select
                      required
                      name="business"
                      value={selectedBusiness}
                      onChange={handleBusinessChange}
                    >
                      <option value="">Select a business</option>
                      {businesses.map((business) => (
                        <option key={business.id} value={business.id}>
                          {business.name} - {business.id}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-3">
                  <Form.Label>Select a office:</Form.Label>
                  <FloatingLabel label="Office Selection">
                    <Form.Select
                      required
                      name="office"
                      value={selectedOffice}
                      onChange={handleOfficeChange}
                      disabled={!selectedBusiness}
                    >
                      <option value="">Select an office</option>
                      {selectedBusiness && offices[selectedBusiness] ? (
                        offices[selectedBusiness].map((office) => (
                          <option key={office.id} value={office.id}>
                            {office.name}
                          </option>
                        ))
                      ) : (
                        <option value="" disabled={!selectedBusiness}>
                          No offices available
                        </option>
                      )}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleMergeClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleProceed}
                disabled={!selectedBusiness || !selectedOffice}
              >
                Proceed <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            </Modal.Footer>
          </Form>
        )}
        {showDeleteQuestion && (
          <>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Delete Business -{" "}
                <i>
                  {userBusiness.name}({userBusiness.id})
                </i>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Do you want to delete this user's existing business ?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  handleSubmit(false);
                }}
              >
                {isNo && (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                  </>
                )}
                No
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  handleSubmit(true);
                }}
              >
                {isYes && (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                  </>
                )}
                Yes
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </div>
  );
};

export default Users;
