import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardContent, CardHeader, CardTitle } from "../shadcn/Card";
import { Link } from "react-router-dom";
import Flex from "../common/Flex";
import { Col } from "react-bootstrap";

export function SideCard({ links }) {
  const isExternalLink = (link) => {
    return link.startsWith("http") || link.startsWith("mailto");
  };

  return (
    <Card className="tw-mb-6 tw-min-h-[280px]">
      <CardHeader>
        <CardTitle>Quick Links</CardTitle>
      </CardHeader>
      <CardContent className="tw-flex tw-flex-col tw-items-start tw-space-y-3">
        {links.map(({ icon, color, title, text, link }) => {
          return (
            <Col key={title} className="tw-w-full">
              <Flex className="tw-flex tw-items-center tw-space-x-2 tw-w-full">
                <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                  <FontAwesomeIcon icon={icon} className={`text-${color}`} />
                  {/* <FontAwesomeIcon icon={icon} color={color} /> */}
                </div>
                <div className="flex-1">
                  {isExternalLink(link) ? (
                    <a
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      //   className="stretched-link"
                    >
                      <h6 className="text-800 mb-0">{title}</h6>
                    </a>
                  ) : (
                    <Link to={link}>
                      <h6 className="text-800 mb-0">{title}</h6>
                    </Link>
                  )}
                  {/* <p className="mb-0 fs--2 text-500 ">{text}</p> */}
                </div>
              </Flex>
            </Col>
          );
        })}
      </CardContent>
    </Card>
  );
}
