import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AdvanceTableWrapper from "../common/advance-table/AdvanceTableWrapper";
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Row,
  Spinner,
} from "react-bootstrap";
import AdvanceTableSearchBox from "../common/advance-table/AdvanceTableSearchBox";
import AdvanceTable from "../common/advance-table/AdvanceTable";
import AdvanceTableFooter from "../common/advance-table/AdvanceTableFooter";
import { faInfoCircle, faList } from "@fortawesome/free-solid-svg-icons";
import WidgetSectionTitle from "../../widgets/WidgetSectionTitle";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import {
  CreateTransparencyAssessmentModal,
  DeleteTransparencyAssessmentModal,
  EditTransparencyAssessmentModal,
} from "./TransparencyAssessmentModals";
import { getTransparencyAssessments } from "./adminApi";
import IconButton from "../common/IconButton";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { MdDelete } from "react-icons/md";
import SoftBadge from "../common/SoftBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "../common/Flex";
import { FileIcon, defaultStyles } from "react-file-icon";
import { FaPaperclip } from "react-icons/fa6";

const TransparencyAssessment = () => {
  const location = useLocation();
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [transparencyAssessments, setTransparencyAssessments] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [contactData, setContactData] = useState();
  const [indexToEdit, setIndexToEdit] = useState();

  useEffect(() => {
    if (location.state && location.state.supplierId) {
      setSearchValue(location.state.supplierId);
    }
  }, [location.state]);

  const getTransparencyAssessmentsToState = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getTransparencyAssessments();
      setTransparencyAssessments(response);
      // console.log("response", response);
    } catch (error) {
      console.error("Error fetching transparency assessments: ", error);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getTransparencyAssessmentsToState();
  }, []);

  const handleOpenEditModal = (data) => {
    setContactData(data);
    setShowEditModal(true);
  };

  const handleOpenDeleteModal = (data, index) => {
    setContactData(data);
    // setIndexToEdit(index);
    setShowDeleteModal(true);
  };

  const handleDataUpdated = useCallback(async () => {
    await getTransparencyAssessmentsToState();
  }, [getTransparencyAssessmentsToState]);

  const columns = [
    {
      accessor: "supplierId",
      Header: "Supplier ID",
      Cell: (rowData) => {
        const supplierId = rowData.row.original?.supplierId;
        return (
          <div style={{ width: "100px", overflowX: "auto" }}>{supplierId}</div>
        );
      },
    },
    {
      accessor: "supplierInfo.name",
      Header: "Supplier Name",
    },
    {
      accessor: "supplierInfo.status",
      Header: "Status",
      cellProps: {
        style: { maxWidth: "150px" },
      },
      headerProps: {
        style: { maxWidth: "150px" },
      },
      Cell: (rowData) => {
        const status = rowData.row.original?.supplierInfo?.status;

        if (status === "approved") {
          return <SoftBadge bg={"success"}>Aproved</SoftBadge>;
        } else if (status === "drafted") {
          return <SoftBadge bg={"warning"}>Drafted</SoftBadge>;
        } else {
          return <SoftBadge bg={"warning"}>Needs Review</SoftBadge>;
        }
      },
    },
    {
      accessor: "assessmentData.email",
      Header: "Email Used",
      Cell: (rowData) => {
        const email = rowData.row.original?.assessmentData?.email;
        return <div style={{ width: "100px", overflowX: "auto" }}>{email}</div>;
      },
    },
    {
      accessor: "assessment.invitedBy",
      Header: "Invited By",
      Cell: ({ row }) => (
        <Link
          to={"/welcome/businesses"}
          state={{ businessId: row.original?.assessment?.invitedBy }}
        >
          {row.original?.assessment?.invitedBy}
        </Link>
      ),
    },
    {
      accessor: "assessment.files[0]",
      Header: "Files",
      Cell: (rowData) => {
        const assessment = rowData.row.original?.assessment;
        const hasFiles = assessment?.files?.length > 0;
        return (
          <DropdownButton
            // id={`dropdown-button-${index}`}
            id={`dropdown-button-${assessment?.expiryDate?._seconds}`}
            title={<FaPaperclip color={hasFiles ? "black" : "gray"} />}
            variant="outline-secondary"
            drop="start"
            disabled={!hasFiles}
          >
            {assessment.files?.map((file, fileIndex) => (
              <Dropdown.Item key={fileIndex} as="button">
                <a
                  href={file.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="dropdown-item"
                >
                  <Flex alignItems="center" justifyContent="space-between">
                    <div className="file-thumbnail">
                      <FileIcon
                        size={5}
                        extension={file.fileType.toLowerCase() || ""}
                        type="image"
                        {...defaultStyles[file.fileType.toLowerCase() || ""]}
                      />
                    </div>
                    <div className="ms-3">{file.fileName}</div>
                  </Flex>
                </a>
              </Dropdown.Item>
            ))}
          </DropdownButton>
        );
      },
    },
    {
      accessor: "assessment.reportingYear",
      Header: "Period",
      Cell: (rowData) => {
        const periodType =
          rowData.row.original?.assessment?.reportingType === "financial"
            ? "FY"
            : "CY";
        const periodYear = rowData.row.original?.assessment?.reportingYear;

        return `${periodType}${periodYear}`;
      },
    },
    {
      accessor: "assessment.responses.totalScore",
      Header: "Score",
    },
    {
      accessor: "assessment.status",
      Header: "Status",
      Cell: ({ row }) => {
        const hasResponses = row.original.assessment.responses;
        return hasResponses ? "COMPLETED" : "REGISTERED";
      },
    },
    // {
    //   accessor: "assessment.expiryDate._seconds",
    //   Header: "Expiry At",
    //   Cell: (rowData) => {
    //     const assessment = rowData.row.original?.assessment;
    //     let expiryDate = assessment?.expiryDate;
    //     expiryDate = new Date(expiryDate._seconds * 1000);

    //     // Calculate the difference in milliseconds between now and the expiry date
    //     const timeDifference = expiryDate.getTime() - Date.now();

    //     // Display a warning if the expiry date is within 7 days from now
    //     assessment.isExpiringSoon =
    //       timeDifference > 0 && timeDifference <= 7 * 24 * 60 * 60 * 1000;

    //     // Display an error if the expiry date is in the past
    //     assessment.hasExpired = timeDifference < 0;

    //     // Convert the expiryDate to a readable string with DD/MM/YYYY format
    //     const formattedExpiryDate = expiryDate.toLocaleDateString("en-AU", {
    //       day: "2-digit",
    //       month: "2-digit",
    //       year: "numeric",
    //     });

    //     assessment.formattedExpiryDate = formattedExpiryDate;

    //     // Calculate the number of days left
    //     const daysLeft = Math.ceil(timeDifference / (24 * 60 * 60 * 1000));
    //     assessment.daysLeft = daysLeft;
    //     return (
    //       <OverlayTrigger
    //         placement="top"
    //         overlay={
    //           <Tooltip
    //             id={`dropdown-button-${assessment?.expiryDate?._seconds}`}
    //           >
    //             {assessment.hasExpired
    //               ? "This assessment has expired!"
    //               : assessment.isExpiringSoon
    //               ? "This assessment is expiring soon!"
    //               : "This assessment will expire in " +
    //                 assessment.daysLeft +
    //                 " days."}
    //           </Tooltip>
    //         }
    //       >
    //         <div className="col text-center">
    //           <span>
    //             {assessment.formattedExpiryDate}
    //             {assessment.hasExpired && (
    //               <FontAwesomeIcon
    //                 icon={faInfoCircle}
    //                 className="ms-1 text-danger" // Change color to red for expired assessments
    //               />
    //             )}
    //             {assessment.isExpiringSoon && (
    //               <FontAwesomeIcon
    //                 icon={faInfoCircle}
    //                 className="ms-1 text-warning" // Change color to yellow for expiring soon assessments
    //               />
    //             )}
    //             {!assessment.hasExpired && !assessment.isExpiringSoon && (
    //               <FontAwesomeIcon
    //                 icon={faInfoCircle}
    //                 className="ms-1 text-primary" // Change color to green for non-expired assessments
    //               />
    //             )}
    //           </span>
    //         </div>
    //       </OverlayTrigger>
    //     );
    //   },
    // },
    {
      accessor: "edit",
      Header: "Edit",
      cellProps: {
        className: "text-center",
      },
      headerProps: {
        className: "text-center",
      },
      Cell: ({ row }) => {
        // const assessment = row.original?.assessment;
        return (
          <>
            <div style={{}}>
              <Button
                variant="falcon-primary"
                className="me-2 mb-1"
                onClick={() => {
                  handleOpenEditModal(row.original);
                }}
              >
                <MdEdit />
              </Button>
            </div>
          </>
        );
      },
    },
    {
      accessor: "delete",
      Header: "Delete",
      Cell: ({ row }) => {
        // const assessments = row.original.assessments ?? [];
        return (
          <>
            <div style={{}}>
              <Button
                variant="falcon-primary"
                className="me-2 mb-1"
                onClick={() => {
                  handleOpenDeleteModal(row.original);
                }}
              >
                <MdDelete />
              </Button>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <WidgetSectionTitle
        icon={faList}
        title="Transparency Assessment"
        subtitle="List of Transparency Assessments"
        transform="shrink-2"
        className="mb-4 mt-6"
      />

      {isLoading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999, // Adjust the z-index as needed
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="mb-3">
          <AdvanceTableWrapper
            columns={columns}
            data={transparencyAssessments}
            sortable
            pagination
            perPage={25}
            rowCount={transparencyAssessments.length}
          >
            <Card>
              <Card.Header>
                <Row className="flex-end-center mb-3">
                  <Col>
                    {" "}
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon={faPlus}
                      transform="shrink-3"
                      onClick={() => {
                        setShowCreateModal(true);
                      }}
                    >
                      <span className="d-none d-sm-inline-block ms-1">New</span>
                    </IconButton>
                  </Col>
                  <Col xs="auto" sm={6} lg={4}>
                    <AdvanceTableSearchBox
                      table
                      searchValue={searchValue}
                      setSearchValue={setSearchValue}
                    />
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="btn-reveal-trigger text-nowrap align-middle"
                  tableProps={{
                    size: "lg",
                    className: "fs--1 mb-0 overflow-hidden",
                  }}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTableFooter
                  data={transparencyAssessments}
                  rowCount={transparencyAssessments.length}
                  table
                  rowInfo
                  navButtons
                />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        </div>
      )}
      {showEditModal && (
        <EditTransparencyAssessmentModal
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          data={contactData}
          // index={indexToEdit}
          onDataUpdated={handleDataUpdated}
        />
      )}

      {showCreateModal && (
        <CreateTransparencyAssessmentModal
          show={showCreateModal}
          onHide={() => setShowCreateModal(false)}
          onDataUpdated={handleDataUpdated}
        />
      )}

      {showDeleteModal && (
        <DeleteTransparencyAssessmentModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          data={contactData}
          index={indexToEdit}
          onDataUpdated={handleDataUpdated}
        />
      )}
    </>
  );
};

export default TransparencyAssessment;
