import { Form, Button, Col, Row, Spinner } from "react-bootstrap";
import xero from "../../Assets/img/icons/xero.png";
import myobLogo from "../../Assets/img/icons/myob.png";
import googleDark from "../../Assets/img/icons/googleDark.png";
import googleLight from "../../Assets/img/icons/googleLight.png";
import microsoftLogo from "../../Assets/img/icons/microsoft.png";
import { useState } from "react";
import { ToastContainer } from "react-toastify";

const LoginSocialAuthButtons = ({
  handleRedirectToOrBack,
  azureADLogin,
  handleXeroAuth,
  isAzureLogin,
  isLoading,
}) => {
  const [imgSrc, setImgSrc] = useState(googleDark);

  return (
    <>
      <Form.Group className="mb-0">
        <Row>
          <Col sm={6} className="ps-sm-1">
            <Button
              variant=""
              size="sm"
              className="btn-outline-facebook mt-2 w-100"
              onClick={() => {
                window.location = "/xero-sign-in";
              }}
              disabled={isLoading}
            >
              <img
                src={xero}
                className="me-2"
                alt="xero"
                style={{ height: "30px", width: "30px" }}
              />{" "}
              Sign in with Xero
            </Button>
          </Col>
          <Col sm={6} className="ps-sm-1">
            <Button
              variant=""
              size="sm"
              className="btn-outline-facebook mt-2 w-100"
              onClick={() => {
                window.location = "/myob-sign-in";
              }}
              disabled={isLoading}
            >
              <div>
                <img
                  src={myobLogo}
                  alt="myob"
                  style={{ height: "30px", width: "45px" }}
                />
                {""}
                Sign in with MYOB
              </div>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="ps-sm-1">
            <Button
              variant=""
              size="sm"
              className="btn-outline-google-plus mt-2 w-100 me-2"
              onClick={handleRedirectToOrBack}
              onMouseOver={() => {
                setImgSrc(googleLight);
              }}
              onMouseOut={() => {
                setImgSrc(googleDark);
              }}
              disabled={isLoading}
            >
              <img
                src={imgSrc}
                className="me-2"
                alt="google"
                style={{ height: "30px", width: "30px" }}
              />{" "}
              Sign in with Google
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="ps-sm-1">
            <Button
              variant=""
              size="sm"
              className="btn-outline-facebook mt-2 w-100 me-2"
              onClick={async () => {
                await azureADLogin();
              }}
              onMouseOver={() => {
                setImgSrc(googleLight);
              }}
              onMouseOut={() => {
                setImgSrc(googleDark);
              }}
              disabled={isLoading}
            >
              {isAzureLogin && (
                <Spinner size="sm" style={{ marginRight: "10px" }}></Spinner>
              )}
              <img
                src={microsoftLogo}
                className="me-2"
                alt="google"
                style={{ height: "30px", width: "60px" }}
              />{" "}
              Sign in with Microsoft
            </Button>
          </Col>
        </Row>
      </Form.Group>
      <ToastContainer />
    </>
  );
};

export default LoginSocialAuthButtons;
