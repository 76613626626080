// ActionsModal.js
import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopesBulk } from "@fortawesome/free-solid-svg-icons";

const ActionsModal = ({ show, onHide, rowData, activeUser, handlers }) => {
  const {
    handleEditOpen,
    handleEditFrozenDataOpen,
    handleSelectDataSourcesOpen,
    handleDashboardOpen,
    handleRefreshDashboard,
    handleDeleteOpen,
  } = handlers;

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Actions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className="list-unstyled">
          <li>
            <Button
              variant="link"
              onClick={() => {
                handleEditOpen(rowData);
                onHide();
              }}
            >
              Edit Details
            </Button>
          </li>
          <li>
            <Button
              variant="link"
              onClick={() => {
                handleEditFrozenDataOpen(rowData);
                onHide();
              }}
            >
              Edit Frozen Data
            </Button>
          </li>
          {(activeUser.role === "SUPER_ADMIN" ||
            activeUser.role === "WHITELABEL_SUPER_ADMIN") && (
            <li>
              <Button
                variant="link"
                onClick={() => {
                  handleSelectDataSourcesOpen(rowData);
                  onHide();
                }}
              >
                Bulk Campaigning <FontAwesomeIcon icon={faEnvelopesBulk} />
              </Button>
            </li>
          )}
          <li>
            <Button
              variant="link"
              onClick={() => {
                handleDashboardOpen(rowData);
                onHide();
              }}
            >
              Edit Dashboard
            </Button>
          </li>
          <li>
            <Button
              variant="link"
              className="text-primary"
              onClick={() => {
                handleRefreshDashboard(rowData);
                onHide();
              }}
            >
              Refresh Dashboard
            </Button>
          </li>
          <li>
            <Button
              variant="link"
              className="text-danger"
              onClick={() => {
                handleDeleteOpen(rowData.id);
                onHide();
              }}
            >
              Delete Business
            </Button>
          </li>
        </ul>
      </Modal.Body>
    </Modal>
  );
};

export default ActionsModal;
