import React, { useContext, useState, createContext } from "react";
import { auth, anonymousAuth } from "../firebase";
import {
  updateProfile,
  signInWithPopup,
  GoogleAuthProvider,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";

import {
  isLoginDataExists,
  getActiveUserData,
  clearLoginData,
} from "../helpers/store";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(getActiveUserData()) || null
  );
  const [isUserSignedIn, setIsUserSignedIn] = useState(
    isLoginDataExists() || false
  );
  const [loading, setLoading] = useState(false);

  // function signup(name, email, password, number, givenName, familyName) {
  //   console.log(typeof (number));
  //   // const user = auth.createUserWithEmailAndPassword(email, password).then(() => {
  //   //   updateProfile(auth.currentUser, { displayName: name, phoneNumber: number })
  //   // }).then((userCredential) => {
  //   //   // send verification mail.
  //   //   auth.currentUser.sendEmailVerification();
  //   //   //auth.signOut();
  //   //   //alert("Email sent");
  //   // }).then(() => { const { uid, email, phoneNumber } = auth.currentUser; registration(uid, email, givenName, familyName, number, name) }).catch(err => { console.log(err); return err })

  //   const user = auth.createUserWithEmailAndPassword(email, password).catch(err => { console.log(err); return err })

  //   return user;
  // }

  async function signup(email, password) {
    const user = await auth
      .createUserWithEmailAndPassword(email, password)
      .catch((err) => {
        console.log(err);
        return err;
      });

    const idToken = await auth.currentUser.getIdToken();
    return { user, idToken };
  }

  // Function to sign in anonymously using the secondary app
  async function signupAnonymously() {
    try {
      const userCredential = await anonymousAuth.signInAnonymously();

      // After fetching the UID, sign out from the secondary app
      await anonymousAuth.signOut();

      return { user: userCredential.user };
    } catch (error) {
      console.error("Error signing in anonymously:", error);
      return { error };
    }
  }

  // async function login(email, password) {
  //   const result = await auth
  //     .signInWithEmailAndPassword(email, password)
  //     .then(async (user) => {
  //       const idToken = await auth.currentUser.getIdToken();
  //       return { user: user.user, idToken };
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       return err;
  //     });
  //   return result;
  // }

  async function login(email, password) {
    try {
      const result = await auth.signInWithEmailAndPassword(email, password);
      let idToken = await auth.currentUser.getIdToken(true);

      // Decode the ID token to access custom claims
      const decodedToken = jwtDecode(idToken);

      // Get the current domain/subdomain
      let currentDomain;
      console.log("split host", window.location.hostname.split("."));

      if (window.location.hostname.includes("localhost")) {
        currentDomain = "localhost";
      } else {
        currentDomain = window.location.hostname.split(".")[1];
      }

      // Check if subDomain is "default"
      if (decodedToken.subdomain === "default") {
        console.log(`Updating subdomain to: ${currentDomain}`);

        // Send a request to your backend to update the subdomain
        const response = await fetch("/api/users/updateSubdomain", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({ subdomain: currentDomain }),
        });

        if (!response.ok) {
          throw new Error("Failed to update subdomain");
        }

        console.log("Subdomain updated successfully.");

        // Refresh the token to include the updated subdomain
        idToken = await auth.currentUser.getIdToken(true); // Force refresh
      } else {
        console.log("Subdomain is not 'default', no update needed.");

        // Skip the domain verification if running on localhost
        if (currentDomain !== "localhost") {
          // Verify the domain matches the custom claim
          if (
            decodedToken.subdomain &&
            decodedToken.subdomain !== currentDomain
          ) {
            console.error(
              `Domain mismatch: expected ${decodedToken.subdomain}, got ${currentDomain}`
            );
            // Optionally, log out the user if the domain does not match
            await auth.signOut();
            throw new Error("Domain mismatch. Access denied.");
          }
        }
      }

      // Return the user and token if verification is successful
      return { user: result.user, idToken };
    } catch (error) {
      console.error("Error during login:", error);
      return { error: error.message };
    }
  }

  async function loginWithGoogle() {
    try {
      await setPersistence(auth, browserLocalPersistence);
      const provider = new GoogleAuthProvider();

      // Perform Google sign-in
      const result = await signInWithPopup(auth, provider);
      let idToken = await auth.currentUser.getIdToken(true);

      // Decode the ID token to access custom claims
      const decodedToken = jwtDecode(idToken);

      // Get the current domain/subdomain
      let currentDomain;
      console.log("split host", window.location.hostname.split("."));
      if (window.location.hostname.includes("localhost")) {
        currentDomain = "localhost";
      } else {
        currentDomain = window.location.hostname.split(".")[1];
      }
      console.log(`Current domain: ${currentDomain}`);

      // Check if subDomain is "default"
      console.log(`Decoded subdomain: ${decodedToken.subdomain}`);
      if (decodedToken.subdomain === "default") {
        console.log(`Updating subdomain to: ${currentDomain}`);

        // Send a request to your backend to update the subdomain
        const response = await fetch("/api/users/updateSubdomain", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({ subdomain: currentDomain }),
        });

        if (!response.ok) {
          throw new Error("Failed to update subdomain");
        }

        console.log("Subdomain updated successfully.");

        // Refresh the token to include the updated subdomain
        idToken = await auth.currentUser.getIdToken(true); // Force refresh
      } else {
        console.log("Subdomain is not 'default', no update needed.");
        // Skip the domain verification if running on localhost
        if (currentDomain !== "localhost") {
          // Verify the domain matches the custom claim
          if (
            decodedToken.subdomain &&
            decodedToken.subdomain !== currentDomain
          ) {
            console.error(
              `Domain mismatch: expected ${decodedToken.subdomain}, got ${currentDomain}`
            );
            // Optionally, log out the user if the domain does not match
            await auth.signOut();
            throw new Error("Domain mismatch. Access denied.");
          }
        }
      }
      // Return the user and token if verification is successful
      return { user: result.user, idToken };
    } catch (error) {
      console.error("Error during login with Google:", error);
      return { error: error.message };
    }
  }

  async function logout() {
    await auth.signOut();
    clearLoginData();
    // removeIdToken();
    // removeUserData();
    // window.location.href = "/";
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  function updateUserProfile(displayName, phoneNumber) {
    const user = updateProfile(auth.currentUser, { displayName, phoneNumber });
    return user;
  }

  function sendEmailVerificationMail() {
    return auth.currentUser.sendEmailVerification();
  }

  // useEffect(() => {
  //   const unsubscribe = auth.onAuthStateChanged((user) => {
  //     console.log("onAuthStateChanged");
  //     if (user) {
  //       setIsUserSignedIn(true);
  //       setCurrentUser(user);
  //     } else {
  //       setIsUserSignedIn(false);
  //     }

  //     setLoading(false);
  //   });

  //   return unsubscribe;
  // }, []);

  const value = {
    setCurrentUser,
    currentUser,
    setIsUserSignedIn,
    isUserSignedIn,
    login,
    loginWithGoogle,
    signup,
    signupAnonymously,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    updateUserProfile,
    sendEmailVerificationMail,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
