import React from "react";
import { Route, Routes } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.min.css";
// import ECommerce from "./components/dashboards/e-commerce/index";

import FileUploader from "./components/DataUploader/UploadData/FileUploader";
import AllInvoices from "./components/DataUploader/AllInvoices/AllInvoices";
import SupplyChain from "./components/unifiedData/supply-chain";
import SuppliersView from "./components/suppliers/suppliers-view";
import WasteForm from "./components/ClimateActive/Waste/index";
import FlightForm from "./components/ClimateActive/Flights/flightsForm";
import PaperForm from "./components/ClimateActive/Paper/paperForm";
import StaffForm from "./components/ClimateActive/Staff/staffForm";
import AllForms from "./components/ClimateActive/MyForms/AllForms";
import WasteDataViewer from "./components/ClimateActive/Waste/wasteDataViewer";
import PaperDataViewer from "./components/ClimateActive/Paper/paperDataViewer";
import ActivityData from "./components/ClimateActive/activityData";

// import Management from "./components/dashboards/project-management/index";
import AllLocations from "./components/AllLocations/index";

import Events from "./components/Events/index";

import Solutions from "./components/solutions/browse-all/Solutions";
import MySolutions from "./components/solutions/my-solutions/MySolutionProvider";
import AreasOfImprovement from "./components/solutions/areas-of-improvement/AreasOfImprovementProvider";
import Chat from "./components/solutions/chat/Chat";
import RecommendedSolutions from "./components/solutions/recommended-solutions/Index";

import Partner from "./components/partners/partners";
import MyPartners from "./components/partners/myPartners";
import Grants from "./components/grants/grants";
import MyGrants from "./components/grants/myGrants";
import Integrations from "./components/integrations/index";
import UserReports from "./components/reports/index";
import OrganisationDetails from "./components/org-details/organisationDetails.js";
// import OrganisationDetailsOld from "./components/org-details/organisationDetailsOld.js";
import NewOverview from "./components/new-overview/newOverview.js";
import Content from "./components/content/index";
import Account from "./components/account/index";
import InviteUser from "./components/invite-user/InviteUser";
import GettingStarted from "./components/pages/GettingStarted";
import FAQ from "./components/pages/FAQ";
import AddLocation from "./components/add-location/AddLocation";

import Businesses from "./components/admin/Businesses";
import Users from "./components/admin/Users";
import WhitelistUsers from "./components/admin/WhitelistUsers";
import UploadReports from "./components/admin/UploadReports";
import UploadContent from "./components/admin/UploadContent";
import UserTickets from "./components/admin/UserTickets";

import EventForm from "./components/Events/eventForm";
import WaterForm from "./components/Events/Water/WaterForm.js";
import FoodForm from "./components/Events/Food/FoodForm";
import LocalTransportForm from "./components/Events/LocalTransport/LocalTransportForm";
import RegionalGroundTransportForm from "./components/Events/RegionalGroundTransport/RegionalGroundTransportForm";
// import WaterDataForm from "./components/water/WaterDataForm.js";
import WaterDataForm from "./components/water/index.js";
import WfhForm from "./components/wfh/WfhForm";
import WfhFormData from "./components/wfh/WfhFormData";
import EmployeeEngagementForm from "./components/employee-engagement/index.js";
import EmployeeEngagementResponseOverview from "./components/employee-engagement/EmployeeEngagementResponseOverview";
import EmployeeEngagementEmployeeSpecificForm from "./components/employee-engagement/EmployeeEngagementEmployeeSpecificForm";
import EmployeeEngagementEstimationForm from "./components/employee-engagement/EmployeeEngagementEstimationForm";
import WfhAllFormData from "./components/wfh/WfhAllFormData";
import EmissionsBoundaries from "./components/emissions-boundaries/EmissionsBoundaries";
import Offset from "./components/offset/index";

// import NewDashboard from "../src/components/dashboards/newDashboard/NewDashboard";

import TransparencyHub from "./components/TransparencyHub/index";
import TransparencyHubForm from "./components/TransparencyHub/Form";
import TransparencyAssessment from "./components/admin/TransparencyAssessment.js";
import Invoices from "./components/admin/Invoices.js";
// import BusinessDetailsForm from "./components/BusinessDetailWizard/BusinessDetailsForm.js";
import Overview from "./components/overview/index.js";
import CreateHelpAndSupportDocs from "./components/admin/HelpAndSupport/CreateHelpAndSupportDocs.js";

import AccountDetails from "./components/AccountDetails/Index.js";
import OrganisationDetailsWizardLayout from "./components/OrganisationDetails/OrganisationDetailsWizardLayout.js";
import ReportingPeriodDetailsWizardLayout from "./components/ReportingPeriodDetails/ReportingPeriodDetailsWizardLayout.js";

// const TransparencyHub = React.lazy(() =>
//   import("./components/TransparencyHub/index")
// );

const AuthenticatedRoutes = ({ currentUser }) => {
  return (
    <Routes>
      {/* Top Navigation Bar Routes */}
      <Route path="/account" element={<Account />} />
      <Route path="/getting-started" element={<GettingStarted />} />
      <Route path="/FAQs" element={<FAQ />} />
      <Route exact path="/add-location" element={<AddLocation />} />
      {/* <Route path="/overview" element={<ECommerce />} /> */}
      {/* <Route path="/dashboard/project-management" element={<Management />} /> */}
      <Route path="/dashboard/all-locations" element={<AllLocations />} />
      <Route path="/data/file-uploader" element={<FileUploader />} />
      <Route path="/data/all-invoices" element={<AllInvoices />} />
      <Route path="/data/supply-chain" element={<SupplyChain />} />
      <Route path="/data/suppliers-view" element={<SuppliersView />} />
      <Route path="/data/calculations" element={<ActivityData />} />
      <Route path="/data/climate-active/waste-form" element={<WasteForm />} />
      <Route
        path="/data/climate-active/flights-form"
        element={<FlightForm />}
      />
      <Route path="/data/climate-active/paper-form" element={<PaperForm />} />
      <Route
        path="/data/climate-active/staff-form/:id?"
        element={<StaffForm />}
      />
      <Route path="/data/climate-active/water-form" element={<WaterForm />} />
      <Route path="/data/climate-active/food-form" element={<FoodForm />} />
      <Route
        path="/data/climate-active/local-transport-form"
        element={<LocalTransportForm />}
      />
      <Route path="/data/climate-active/all-forms" element={<AllForms />} />

      <Route
        path="/data/climate-active/waste-data-viewer"
        element={<WasteDataViewer />}
      />
      <Route
        path="/data/climate-active/paper-data-viewer"
        element={<PaperDataViewer />}
      />

      <Route path="/dashboard/events" element={<Events />} />
      <Route path="/dashboard/event-form" element={<EventForm />} />

      <Route
        path="/data/climate-active/regional-ground-transport-form"
        element={<RegionalGroundTransportForm />}
      />

      <Route path="/data/water" element={<WaterDataForm />} />
      <Route
        path="/data/employee-engagement"
        element={<EmployeeEngagementForm />}
      />

      <Route path="/data/wfh" element={<WfhForm />} />
      <Route path="/data/wfh-form-data" element={<WfhFormData />} />
      {/* <Route
        path="/data/employee-engagement-response-overview/:employeeEngagementFormId?"
        element={<EmployeeEngagementResponseOverview />}
      /> */}
      <Route
        path="/data/employee-engagement-response-overview"
        element={<EmployeeEngagementResponseOverview />}
      />
      <Route
        path="/data/employee-engagement-form-data"
        element={<EmployeeEngagementEmployeeSpecificForm />}
      />
      <Route
        path="/data/employee-engagement-estimation-form-data"
        element={<EmployeeEngagementEstimationForm />}
      />

      <Route path="/data/wfh-all-form-data" element={<WfhAllFormData />} />
      <Route path="/org-details" element={<OrganisationDetails />} />
      {/* <Route path="/org-details" element={<OrganisationDetailsOld />} /> */}
      {/* <Route path="/new-overview" element={<NewOverview />} /> */}

      <Route path="/new-overview" element={<Overview />} />

      {/* <Route exact path="/newDashboard" element={<NewDashboard />} /> */}

      {(currentUser.subscription === "PAID" ||
        currentUser.subscription === "PREMIUM") && (
        <>
          <Route path="/solutions/browse-all" element={<Solutions />} />
          <Route path="/solutions/my-solutions" element={<MySolutions />} />
          <Route
            path="/solutions/areas-of-improvement"
            element={<AreasOfImprovement />}
          />
          <Route path="/solutions/chat" element={<Chat />} />
          <Route
            path="/solutions/recommended-solutions"
            element={<RecommendedSolutions />}
          />
          <Route path="/partners/browse-all" element={<Partner />} />
          <Route path="/partners/my-partners" element={<MyPartners />} />
        </>
      )}

      {currentUser.subscription === "PREMIUM" && (
        <>
          <Route path="/grants/browse-all" element={<Grants />} />
          <Route path="/grants/my-grants" element={<MyGrants />} />
        </>
      )}
      <>
        <Route path="/integrations" element={<Integrations />} />
      </>
      <>
        <Route path="/reduce/offset" element={<Offset />} />
      </>

      <>
        <Route path="/user-reports" element={<UserReports />} />
      </>
      <>
        {/* <Route
                      path=" /identify/emissions-boundaries"
                      element={<EmissionsBoundaries />}
                    /> */}
        <Route
          path="/identify/emissions-boundaries"
          element={<EmissionsBoundaries />}
        />
      </>
      <>
        <Route path="/user-content" element={<Content />} />
      </>
      {(currentUser.role === "SUPER_ADMIN" ||
        currentUser.role === "WHITELABEL_SUPER_ADMIN") && (
        <>
          <Route
            path="/create-help-and-support-docs"
            element={<CreateHelpAndSupportDocs />}
          />
          <Route path="/upload-reports" element={<UploadReports />} />
          <Route path="/upload-content" element={<UploadContent />} />
          <Route path="/whitelist" element={<WhitelistUsers />} />
          <Route path="/user-tickets" element={<UserTickets />} />
          <Route
            path="/transparency-assessment"
            element={<TransparencyAssessment />}
          />
          <Route path="/edit-invoice" element={<Invoices />} />
        </>
      )}
      {(currentUser.role === "SUPER_ADMIN" ||
        currentUser.role === "ADVISOR" ||
        currentUser.role === "WHITELABEL_SUPER_ADMIN") && (
        <>
          <Route path="/businesses" element={<Businesses />} />
        </>
      )}

      {(currentUser.role === "SUPER_ADMIN" ||
        currentUser.role === "MANAGER" ||
        currentUser.role === "WHITELABEL_SUPER_ADMIN") && (
        <>
          <Route path="/users" element={<Users />} />
        </>
      )}

      <Route path="/inviteUser" element={<InviteUser />} />

      <Route path="/transparency-hub" element={<TransparencyHub />} />
      <Route path="/transparency-hub/form" element={<TransparencyHubForm />} />
      {/* 
      <Route path="/questions" element={<BusinessDetailsForm />} /> */}

      <Route
        path="/organisation-details"
        element={<OrganisationDetailsWizardLayout />}
      />
      <Route
        path="/reporting-period-details"
        element={<ReportingPeriodDetailsWizardLayout />}
      />
      <Route path="/account-details" element={<AccountDetails />} />
    </Routes>
  );
};

export default AuthenticatedRoutes;
