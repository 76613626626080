import React, { useEffect, useState } from "react";
import { Card, ProgressBar, Badge, Nav, Spinner } from "react-bootstrap";
import classNames from "classnames";
import AccountOnboardingDetails from "./AccountOnboardingDetails";
import AccountOrgDetails from "./AccountOrgDetails";
import AccountRepYearDetails from "./AccountRepYearDetails";
import NavItemPill from "./NavItemPill";
import { FaUserPlus, FaCalendarAlt, FaBuilding } from "react-icons/fa";
import { MdBusiness } from "react-icons/md";
import { getBusinessDetails } from "../solutions/solutionsAPI";
import {
  getActiveUserData,
  getAllLocationsFromStorage,
  getInventoryProgress,
  getSingleInventoryProgress,
  setSingleInventoryProgress,
} from "../../helpers/store";
import { useLocation, useNavigate } from "react-router-dom";
import OfficeLocationManagement from "../OfficeLocationManagement/OfficeLocationManagement";
import { updateStepProgress } from "../../helpers/stepsProgress";
import { toast } from "react-toastify";
import PageHeader from "../common/PageHeader";

function AccountDetails() {
  // Define total questions per section
  const totalQuestionsPerSection = {
    onboarding: 7, // Example values; adjust as needed
    organization: 7,
    reportingPeriod: 4,
    offices: 1,
  };
  // Centralized state for section progresses
  const [sectionProgress, setSectionProgress] = useState({
    onboarding: 0, // Initial progress; adjust as needed
    organization: 0,
    reportingPeriod: 0,
    offices: 0,
  });
  // Calculate overall progress as the average of section progresses
  const overallProgress =
    Object.values(sectionProgress).reduce((acc, curr) => acc + curr, 0) /
    Object.keys(sectionProgress).length;

  // Dummy data for demonstration purposes
  // const overallProgress = 75;
  // const onboardingData = { progress: 100 };
  // const organizationData = { progress: 50 };
  // const reportingPeriodData = { progress: 75 };
  // const officesData = { progress: 25 };
  const [step, setStep] = useState(1);
  const variant = "pills";
  const activeUser = JSON.parse(getActiveUserData());
  const [businessDetails, setBusinessDetails] = useState(null);
  const [inventoryProgress, setInventoryProgress] = useState(null);
  const [allLocations, setAllLocations] = useState([]);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [loadingChild, setLoadingChild] = useState(false);
  const { stringId = "", tabKey } = location.state || {};

  const getStepFromStringId = (id) => {
    switch (id) {
      case "review-onboarding":
        return 1;
      case "review-organisation-details":
        return 2;
      case "reporting-period-details":
        return 3;
      case "locations-selected":
        return 4;
      default:
        return 1; // Default step
    }
  };

  const navItems = [
    {
      icon: <FaUserPlus />,
      label: "Onboarding Details",
      key: "onboarding",
    },
    {
      icon: <MdBusiness />,
      label: "Organisation Details",
      key: "organization",
    },
    {
      icon: <FaCalendarAlt />,
      label: "Reporting Period Data",
      key: "reportingPeriod",
    },
    {
      icon: <FaBuilding />,
      label: "Offices",
      key: "offices",
    },
  ];

  // const navItems = [
  //   {
  //     icon: <FaUserPlus />,
  //     label: "Onboarding Details",
  //   },
  //   {
  //     icon: <MdBusiness />,
  //     label: "Organization Details",
  //   },
  //   {
  //     icon: <FaCalendarAlt />,
  //     label: "Reporting Period Data",
  //   },
  //   {
  //     icon: <FaBuilding />,
  //     label: "Offices",
  //   },
  // ];

  const handleNavs = (targetStep) => {
    setStep(targetStep);
  };

  const updateBusinessDetailsInState = (updatedDetails) => {
    setBusinessDetails((prevDetails) => ({
      ...prevDetails,
      ...updatedDetails,
    }));
  };

  const updateInventoryProgressInState = (updatedDetails) => {
    setInventoryProgress((prevDetails) => {
      const newDetails = {
        ...prevDetails,
        ...updatedDetails,
      };
      // Call setSingleInventoryProgress with inventoryid and newDetails
      setSingleInventoryProgress(prevDetails.inventoryid, newDetails);
      return newDetails;
    });
  };

  const navigate = useNavigate();

  const [currentInventoryProgress, setCurrentInventoryProgress] = useState(
    JSON.parse(getInventoryProgress())
  );

  const handleMarkAsComplete = async (stringId) => {
    setLoadingChild(true);
    const responseProgress = await updateStepProgress({
      stringId,
      status: "completed",
      activeUser,
      currentInventoryProgress,
      onUpdateState: false,
      onUpdateInventoryProgress: false,
    });
    if (responseProgress.success) {
      toast.success(responseProgress.text);
    } else {
      toast.error(responseProgress.text);
    }
    setLoadingChild(false);
    navigate("/welcome/new-overview"); // Navigate to the new route
    toast.success(`Details Saved Successfully!`);
  };

  // // 2. Define a reusable callback function to update section progress
  // const updateSectionProgress = (section, completedQuestions) => {
  //   const totalQuestions = totalQuestionsPerSection[section];
  //   const progress = Math.round((completedQuestions / totalQuestions) * 100);
  //   setSectionProgress((prev) => ({
  //     ...prev,
  //     [section]: progress,
  //   }));
  // };

  // const calculateOnboardingCompleted = (data) => {
  //   let completed = 0;
  //   const fields = [
  //     "caexperience",
  //     "completingreportfor",
  //     "name",
  //     "numberofemployees",
  //     "primarycategory",
  //     "integration",
  //     "careason",
  //   ];

  //   fields.forEach((field) => {
  //     if (
  //       data.hasOwnProperty(field) &&
  //       data[field] !== "" &&
  //       data[field] !== null
  //     ) {
  //       completed += 1;
  //     }
  //   });
  //   return completed;
  // };

  // const calculateOrganizationCompleted = (data) => {
  //   let completed = 0;
  //   const fields = [
  //     "abn",
  //     "primarycategory",
  //     "secondarycategory",
  //     "manufacture_or_sell_goods",
  //     "lease_spaces",
  //     "remote_employees",
  //     "own_or_lease_vehicles",
  //   ];

  //   fields.forEach((field) => {
  //     if (
  //       data.hasOwnProperty(field) &&
  //       data[field] !== "" &&
  //       data[field] !== null
  //     ) {
  //       completed += 1;
  //     }
  //   });

  //   return completed;
  // };

  // const calculateReportingCompleted = (data) => {
  //   let completed = 0;
  //   const fields = [
  //     "employeeNumber",
  //     "revenue",
  //     "offsets",
  //     "businessFlightsExist",
  //   ];

  //   fields.forEach((field) => {
  //     if (
  //       data.hasOwnProperty(field) &&
  //       data[field] !== "" &&
  //       data[field] !== null
  //     ) {
  //       completed += 1;
  //     }
  //   });

  //   return completed;
  // };

  // const calculateOfficesCompleted = (locations) => {
  //   let completed = 0;

  //   //  if at least one location in the array has field 'selected' set to true, then the question is considered completed and should return 1
  //   if (locations.some((location) => location.selected)) {
  //     completed = 1;
  //   }

  //   return completed;
  // };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const businessData = await getBusinessDetails();
      const inventoryData = await getSingleInventoryProgress(
        +activeUser.inventoryId
      );
      const locations = await getAllLocationsFromStorage();
      setAllLocations(locations);
      setBusinessDetails(businessData);
      setInventoryProgress(inventoryData);
      setLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (tabKey !== undefined && tabKey !== null) {
      // If tabKey is provided, use it
      setStep(tabKey);
    } else if (stringId) {
      // Else if stringId is provided, map it to a step
      setStep(getStepFromStringId(stringId));
    } else {
      // If neither is provided, default to step 1
      setStep(1);
    }
  }, [tabKey, stringId]);

  // useEffect(() => {
  //   if (businessDetails && inventoryProgress && allLocations) {
  //     // **Onboarding Details Progress**
  //     const onboardingCompleted = calculateOnboardingCompleted(businessDetails);
  //     updateSectionProgress("onboarding", onboardingCompleted);

  //     // **Organization Details Progress**
  //     const organizationCompleted =
  //       calculateOrganizationCompleted(businessDetails);
  //     updateSectionProgress("organization", organizationCompleted);

  //     // **Reporting Period Details Progress**
  //     const reportingCompleted = calculateReportingCompleted(inventoryProgress);
  //     updateSectionProgress("reportingPeriod", reportingCompleted);

  //     // **Offices Progress**
  //     const officesCompleted = calculateOfficesCompleted(allLocations);
  //     updateSectionProgress("offices", officesCompleted);
  //   }
  // }, [businessDetails, inventoryProgress, allLocations]);

  return (
    <>
      {/* <Card className="mb-4">
        <Card.Body>
          <h5 className="mb-1">Account Details Completion</h5>
          <div className="text-muted mb-3">
            Your overall account setup progress
          </div> */}
      {/* Overall Progress Section */}
      {/* <div className="mb-3">
            <ProgressBar now={overallProgress} style={{ height: "10px" }} />
          </div> */}

      {/* Badges for Individual Sections */}

      {/* <div className="mt-4 d-flex gap-2 flex-wrap">
            {navItems.map((item) => (
              <Badge
                key={item.key}
                pill
                bg={sectionProgress[item.key] === 100 ? "success" : "secondary"}
                style={{ width: 150 }}
              >
                {item.label.split(" ")[0]} {sectionProgress[item.key]}%
              </Badge>
            ))}
          </div>
        </Card.Body>
      </Card> */}

      <PageHeader
        title="Account Details"
        description={`The Account Details page provides an overview of key information related to your organization,
           including Onboarding details, Organization details, Reporting Period data, and Office locations. 
           Each section summarizes relevant information and tracks your completion progress, helping you ensure that 
           all necessary details are accurate and complete for effective emissions reporting.`}
        className="mb-3"
      ></PageHeader>

      <Card className="theme-wizard mb-5">
        <Card.Header
          className={classNames("bg-light", {
            "px-4 py-3": variant === "pills",
            "pb-2": !variant,
          })}
        >
          <Nav className="justify-content-center" variant={variant}>
            {navItems.map((item, index) => (
              <NavItemPill
                key={item.label}
                index={index + 1}
                step={step}
                handleNavs={handleNavs}
                icon={item.icon}
                label={item.label}
              />
            ))}
          </Nav>
        </Card.Header>

        <Card.Body className="fw-normal px-md-6 py-4">
          {step === 1 && (
            <AccountOnboardingDetails
              businessDetails={businessDetails}
              updateBusinessDetailsInState={updateBusinessDetailsInState}
              handleMarkAsComplete={handleMarkAsComplete}
              loadingChild={loadingChild}
              loading={loading}
              section="onboarding" // Pass the section key
              totalQuestions={totalQuestionsPerSection.onboarding} // Pass total questions if needed
            />
          )}
          {step === 2 && (
            <AccountOrgDetails
              businessDetails={businessDetails}
              updateBusinessDetailsInState={updateBusinessDetailsInState}
              handleMarkAsComplete={handleMarkAsComplete}
              loadingChild={loadingChild}
              loading={loading}
              section="organization" // Pass the section key
              totalQuestions={totalQuestionsPerSection.organization} // Pass total questions if needed
            />
          )}
          {step === 3 && (
            <AccountRepYearDetails
              inventoryProgress={inventoryProgress}
              updateInventoryProgressInState={updateInventoryProgressInState}
              loading={loading}
              section="reportingPeriod" // Pass the section key
              totalQuestions={totalQuestionsPerSection.reportingPeriod} // Pass total questions if needed
              // handleMarkAsComplete={handleMarkAsComplete}
            />
          )}
          {step === 4 && (
            <>
              {/* Office Location Management */}
              <OfficeLocationManagement
                inventoryProgress={inventoryProgress}
                allLocations={allLocations}
                setAllLocations={setAllLocations}
                updateInventoryProgressInState={updateInventoryProgressInState}
                reportingPeriod={
                  activeUser.selectedMeasurementYear +
                  " " +
                  activeUser.selectedMeasurementYearType
                }
                handleMarkAsComplete={handleMarkAsComplete}
                loading={loading}
                section="offices" // Pass the section key
                totalQuestions={totalQuestionsPerSection.offices} // Pass total questions
                title="Manage and select locations included in the measurement for the reporting period"
              />
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default AccountDetails;
