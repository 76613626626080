import React, { useState, useEffect } from "react";
import { Form, Button, Spinner, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { saveOnBoardingDetails } from "../OnBoarding/OnBoardingAPI";
import { CheckCircle } from "lucide-react";
import {
  isStepCompleted,
  updateActiveFieldForSubStep,
} from "../../helpers/stepsProgress";
import { getActiveUserData } from "../../helpers/store";

function AccountOnboardingDetails({
  businessDetails,
  updateBusinessDetailsInState,
  handleMarkAsComplete,
  loadingChild,
  loading,
  // updateSectionProgress, // Receive the callback
  section, // Receive the section key
  totalQuestions, // Receive total questions if needed
}) {
  const [activeUser] = useState(JSON.parse(getActiveUserData()));

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const stepCompleted = isStepCompleted("review-onboarding");
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      caexperience: businessDetails?.caexperience || [],
      completingreportfor: businessDetails?.completingreportfor || "",
      company_name: businessDetails?.name || "",
      numberofemployees: businessDetails?.numberofemployees || "",
      category: businessDetails?.category || "",
      integration: businessDetails?.integration || "",
      careason: businessDetails?.careason || [],
      requesting_company_name:
        businessDetails?.carequestingcompanydetails?.companyName || "",
      requesting_company_website:
        businessDetails?.carequestingcompanydetails?.companyWebsite || "",

      tender_company_name:
        businessDetails?.carequestingtenderdetails?.tenderName || "",
      tender_company_website:
        businessDetails?.carequestingtenderdetails?.tenderWebsite || "",
      careporting: businessDetails?.careporting || [],
    },
  });

  // Watch fields for conditional rendering
  const carbonMeasurementPurpose = watch("careason", []);
  const helpingWithSustainability = watch("completingreportfor", "");

  const onSubmit = async (formData) => {
    setIsLoading(true);
    try {
      console.log("formData", formData);

      const body = {
        CAExperience: formData.caexperience || null,
        CompletingReportFor: formData.completingreportfor || null,
        NameOfTheCompany: formData.company_name || null,

        // NameOfTheCompany: {
        //   "Company Name": formData.company_name || null,
        // },
        NumberOfEmployees: formData.numberofemployees || null,
        Category: formData.category || null,
        Integration: formData.integration || null,
        CAReason: formData.careason || null,
        CARequestingCompanyDetails: {
          companyName: formData.requesting_company_name || null, // Save null if empty
          companyWebsite: formData.requesting_company_website || null, // Save null if empty
        },
        CARequestingTenderDetails: {
          tenderName: formData.tender_company_name || null, // Save null if empty
          tenderWebsite: formData.tender_company_website || null, // Save null if empty
        },
        CAReporting: formData.careporting || null, // Save null if empty careporting
      };

      // // 4. Calculate completed questions
      // let completedQuestions = 0;
      // if (body.CAExperience) completedQuestions += 1;
      // if (body.CompletingReportFor) completedQuestions += 1;
      // if (body.NameOfTheCompany) completedQuestions += 1;
      // if (body.NumberOfEmployees !== undefined) completedQuestions += 1;
      // if (body.Category !== undefined) completedQuestions += 1;
      // if (body.Integration !== undefined) completedQuestions += 1;
      // if (body.CAReason !== undefined) completedQuestions += 1;
      // if (body.CARequestingCompanyDetails !== undefined)
      //   completedQuestions += 1;
      // if (body.CARequestingTenderDetails !== undefined) completedQuestions += 1;
      // if (body.CAReporting !== undefined) completedQuestions += 1;
      // // Ensure completedQuestions does not exceed totalQuestions
      // completedQuestions = Math.min(completedQuestions, totalQuestions);
      // // 5. Update progress in parent component
      // updateSectionProgress(section, completedQuestions);

      // Update the business details in the state or send to API
      await saveOnBoardingDetails(body);

      const inStateBody = {
        caexperience: formData.caexperience || null,
        completingreportfor: formData.completingreportfor || null,
        name: formData.company_name || null,
        numberofemployees: formData.numberofemployees || null,
        category: formData.category || null,
        integration: formData.integration || null,
        careason: formData.careason || null,
        carequestingcompanydetails: {
          companyName: formData.requesting_company_name || null, // Save null if empty
          companyWebsite: formData.requesting_company_website || null, // Save null if empty
        },
        carequestingtenderdetails: {
          tenderName: formData.tender_company_name || null, // Save null if empty
          tenderWebsite: formData.tender_company_website || null, // Save null if empty
        },
        careporting: formData.careporting || null, // Save null if empty
      };
      updateBusinessDetailsInState(inStateBody);

      //1. Update active status in postgress
      const activeStatusBody = [
        {
          inventoryId: activeUser.inventoryId,
          stringId: "xero-data-upload",
          active: formData.integration === "Xero" ? true : false,
          officeid: null,
        },
        {
          inventoryId: activeUser.inventoryId,
          stringId: "myob-data-upload",
          active: formData.integration === "MYOB" ? true : false,
          officeid: null,
        },
      ];

      await updateActiveFieldForSubStep(activeStatusBody);

      setIsEditing(false);
    } catch (error) {
      console.error("Failed to update business details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (businessDetails) {
      reset({
        caexperience: businessDetails.caexperience || [],
        completingreportfor: businessDetails.completingreportfor || "",
        company_name: businessDetails.name || "",
        numberofemployees: businessDetails.numberofemployees || "",
        category: businessDetails.category || "",
        integration: businessDetails.integration || "",
        careason: businessDetails.careason || [],
        requesting_company_name:
          businessDetails.carequestingcompanydetails?.companyName || "",
        requesting_company_website:
          businessDetails.carequestingcompanydetails?.companyWebsite || "",
        tender_company_name:
          businessDetails.carequestingtenderdetails?.tenderName || "",
        tender_company_website:
          businessDetails.carequestingtenderdetails?.tenderWebsite || "",
        careporting: businessDetails.careporting || [],
      });
    }
  }, [businessDetails, reset]);

  if (loading || !businessDetails)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "200px" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );

  return (
    <div>
      <h3 className="mb-4">Onboarding Details</h3>

      {/* Instruction Alert */}
      {!stepCompleted && (
        <Alert variant="info" className="mb-4">
          To proceed to the next step, please review your answers below and
          click <strong>Confirm and Proceed</strong>.
        </Alert>
      )}

      <Form onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: 5 }}>
        {/* Question 1: Experience with business sustainability */}
        <Form.Group className="mb-3">
          <Form.Label style={{ fontSize: "18px" }}>
            What is your experience with business sustainability? (Select all
            that apply)
          </Form.Label>
          {/* UNCOMMENT THE LINE BELOW TO DISPLAY THE BUSINESS DETAILS */}
          {!isEditing && <div>{businessDetails.caexperience?.join(" | ")}</div>}
          {!isEditing && <div>{[].join(", ")}</div>}
          {isEditing && (
            <>
              {[
                "Carbon Accounting \u2013 Measuring and tracking carbon emissions within my business.",
                "Sustainable Supply Chain \u2013 Ensuring sustainability practices across suppliers and partners.",
                "Climate Risk Management \u2013 Identifying and mitigating business risks related to climate change.",
                "Sustainable Product Design \u2013 Developing products that minimize environmental impact.",
                "Sustainability Reporting \u2013 Publicly sharing sustainability efforts and progress through reports or disclosures.",
                "No Experience Yet",
                "Other",
                // "Energy Efficiency - Implementing energy-saving technologies and processes.",
                // "Waste Reduction - Reducing, reusing, and recycling materials in business operations.",
                // "Circular Economy - Designing products or services with reusability and minimal waste in mind.",
                // "Sustainable Development Goals (SDGs) - Aligning business operations with the United Nations SDGs.",
                // "Employee Engagement in Sustainability - Involving staff in sustainability practices and initiatives.",
                // "No Focus Yet - I am interested in learning about different sustainability areas to start.",
              ].map((option, index) => (
                <Form.Check
                  key={index}
                  type="checkbox"
                  label={option}
                  value={option}
                  {...register("caexperience")}
                />
              ))}
              {errors.caexperience && (
                <Form.Text className="text-danger">
                  {errors.caexperience.message}
                </Form.Text>
              )}
            </>
          )}
        </Form.Group>

        {/* Question 2: Who are you helping with your sustainability efforts? */}
        <Form.Group className="mb-3">
          <Form.Label style={{ fontSize: "18px" }}>
            Who are you helping with your sustainability efforts?
          </Form.Label>
          {!isEditing && <div>{businessDetails.completingreportfor}</div>}
          {isEditing && (
            <>
              {["The company I work for", "Clients (as a consultant)"].map(
                (option, index) => (
                  <Form.Check
                    key={index}
                    type="radio"
                    label={option}
                    value={option}
                    {...register("completingreportfor", {
                      // required:
                      //   "Please select who you are helping with sustainability efforts.",
                    })}
                  />
                )
              )}
              {errors.completingreportfor && (
                <Form.Text className="text-danger">
                  {errors.completingreportfor.message}
                </Form.Text>
              )}
            </>
          )}
        </Form.Group>

        {/* Question 3: What's the name of the company? */}
        {(helpingWithSustainability === "The company I work for" ||
          !isEditing) && (
          <Form.Group className="mb-3">
            <Form.Label style={{ fontSize: "18px" }}>
              What's the name of the company?
            </Form.Label>
            {!isEditing && <div>{businessDetails.name}</div>}
            {isEditing && (
              <>
                <Form.Control
                  type="text"
                  placeholder="Enter company name"
                  isInvalid={!!errors.company_name}
                  {...register("company_name", {
                    // required: "Company Name is required",
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.company_name?.message}
                </Form.Control.Feedback>
              </>
            )}
          </Form.Group>
        )}

        {/* Question 4: Company headcount */}
        <Form.Group className="mb-3">
          <Form.Label style={{ fontSize: "18px" }}>
            Company headcount
          </Form.Label>
          {!isEditing && <div>{businessDetails.numberofemployees}</div>}
          {isEditing && (
            <>
              {["1-10", "11-50", "51-100", "101-250", "251-499", "500+"].map(
                (option, index) => (
                  <Form.Check
                    key={index}
                    type="radio"
                    label={option}
                    value={option}
                    {...register("numberofemployees", {
                      // required: "Company headcount is required",
                    })}
                  />
                )
              )}
              {errors.numberofemployees && (
                <Form.Text className="text-danger">
                  {errors.numberofemployees.message}
                </Form.Text>
              )}
            </>
          )}
        </Form.Group>

        {/* Question 5: Industry */}
        <Form.Group className="mb-3">
          <Form.Label style={{ fontSize: "18px" }}>Industry</Form.Label>
          {!isEditing && <div>{businessDetails.category}</div>}
          {isEditing && (
            <>
              <Form.Control
                as="select"
                isInvalid={!!errors.category}
                {...register("category", {
                  // required: "Industry is required",
                })}
              >
                <option value="">Select Industry</option>
                {[
                  "Accommodation and Tourism",
                  "Architecture",
                  "Construction",
                  "Consulting",
                  "Education & Training",
                  "Environmental Services",
                  "Financial Services",
                  "Food",
                  "Healthcare",
                  "Information Technology",
                  "Legal Services",
                  "Manufacturing",
                  "Mining",
                  "Non-Profit Organisations",
                  "Professional, Scientific & Technical Services",
                  "Property & Real Estate",
                  "Public Administration",
                  "Retail & Wholesale Trade",
                  "Transport & Logistics",
                  "Utilities, Energy & Waste Services",
                ].map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.category?.message}
              </Form.Control.Feedback>
            </>
          )}
        </Form.Group>

        {/* Question 6: What carbon accounting software do you use */}
        <Form.Group className="mb-3">
          <Form.Label style={{ fontSize: "18px" }}>
            What carbon accounting software do you use
          </Form.Label>
          {!isEditing && <div>{businessDetails.integration}</div>}
          {isEditing && (
            <>
              {[
                "MYOB",
                "NetSuite",
                "QuickBooks",
                "Sage",
                "SAP",
                "Xero",
                "Other",
              ].map((option, index) => (
                <Form.Check
                  key={option}
                  type="radio"
                  label={option}
                  value={option}
                  {...register("integration", {
                    // required: "Please select your accounting software",
                  })}
                />
              ))}
              {errors.integration && (
                <Form.Text className="text-danger">
                  {errors.integration.message}
                </Form.Text>
              )}
            </>
          )}
        </Form.Group>

        {/* Question 7: Why do you need a carbon measurement or other sustainability-related information? */}
        <Form.Group className="mb-3">
          <Form.Label style={{ fontSize: "18px" }}>
            Why do you need a carbon measurement or other sustainability-related
            information? (Select all that apply)
          </Form.Label>
          {!isEditing && <div>{businessDetails.careason?.join(", ")}</div>}
          {isEditing && (
            <>
              {[
                "It was requested by a current client",
                "To be included in a tender or proposal",
                "As part of a certification or reporting requirement",
                "As part of company strategy and value alignment",
              ].map((option, index) => (
                <Form.Check
                  key={index}
                  type="checkbox"
                  label={option}
                  value={option}
                  {...register("careason")}
                />
              ))}
              {errors.careason && (
                <Form.Text className="text-danger">
                  {errors.careason.message}
                </Form.Text>
              )}
            </>
          )}
        </Form.Group>

        {/* Conditional Questions based on carbon measurement purpose */}
        {isEditing &&
          carbonMeasurementPurpose.includes(
            "It was requested by a current client"
          ) && (
            <Form.Group className="mb-3">
              <Form.Label style={{ fontSize: "18px" }}>
                Enter the details of the company that is requesting this
                information
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Company Name"
                isInvalid={!!errors.requesting_company_name}
                {...register("requesting_company_name", {
                  // required: "Company Name is required",
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.requesting_company_name?.message}
              </Form.Control.Feedback>

              <Form.Control
                type="text"
                placeholder="Website"
                isInvalid={!!errors.requesting_company_website}
                {...register("requesting_company_website", {
                  // required: "Company Website is required",
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.requesting_company_website?.message}
              </Form.Control.Feedback>
            </Form.Group>
          )}

        {isEditing &&
          carbonMeasurementPurpose.includes(
            "To be included in a tender or proposal"
          ) && (
            <Form.Group className="mb-3">
              <Form.Label style={{ fontSize: "18px" }}>
                Enter the details of the company that you are tendering for
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Company Name"
                isInvalid={!!errors.tender_company_name}
                {...register("tender_company_name", {
                  // required: "Company Name is required",
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.tender_company_name?.message}
              </Form.Control.Feedback>

              <Form.Control
                type="text"
                placeholder="Website"
                isInvalid={!!errors.tender_company_website}
                {...register("tender_company_website", {
                  // required: "Company Website is required",
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors.tender_company_website?.message}
              </Form.Control.Feedback>
            </Form.Group>
          )}

        {isEditing &&
          carbonMeasurementPurpose.includes(
            "As part of a certification or reporting requirement"
          ) && (
            <Form.Group className="mb-3">
              <Form.Label style={{ fontSize: "18px" }}>
                Please select all that apply
              </Form.Label>
              {[
                "UN Global Compact and SDGs",
                "CDP Reporting",
                "TCFD",
                "ISSB",
                "Ecovadis",
                "Carbon neutrality",
                "Standalone report (for internal use)",
                "B-Corp",
                "Other",
              ].map((option, index) => (
                <Form.Check
                  key={index}
                  type="checkbox"
                  label={option}
                  value={option}
                  {...register("careporting")}
                />
              ))}
              {errors.careporting && (
                <Form.Text className="text-danger">
                  {errors.careporting.message}
                </Form.Text>
              )}
            </Form.Group>
          )}

        {isEditing ? (
          <>
            <Button
              variant="primary"
              type="submit"
              disabled={!isDirty || loadingChild}
            >
              {isLoading ? <Spinner animation="border" size="sm" /> : "Save"}
            </Button>{" "}
            <Button
              variant="secondary"
              onClick={() => setIsEditing(false)}
              disabled={isLoading || loadingChild}
              className="ml-2"
            >
              Cancel
            </Button>
          </>
        ) : (
          <div style={{ display: "flex", gap: 15, marginTop: "20px" }}>
            <Button
              variant="secondary"
              onClick={() => {
                setIsEditing(true);
                // Optional: Reset step completion if editing after completion
                // If you want users to reconfirm after editing, uncomment the line below
                // if (stepCompleted) resetStepCompletion("review-onboarding");
              }}
              disabled={loadingChild}
            >
              {loadingChild ? <Spinner animation="border" size="sm" /> : "Edit"}
            </Button>
            {!stepCompleted && (
              <Button
                variant="success"
                onClick={() => handleMarkAsComplete("review-onboarding")}
                disabled={loadingChild}
              >
                {loadingChild ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <>
                    <CheckCircle className="tw-mr-2 tw-h-4 tw-w-4" /> Confirm
                    and Proceed
                  </>
                )}
              </Button>
            )}
          </div>
        )}
      </Form>
    </div>
  );
}

export default AccountOnboardingDetails;
