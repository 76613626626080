import { recordHook } from "@flatfile/plugin-record-hook";
import { FlatfileListener } from "@flatfile/listener";
import { getAuthorizationHeaderWithContentType } from "../../../helpers/utils";
import app from "../../../firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import {
  getDatabase,
  ref as dbRef,
  child,
  push,
  update,
} from "firebase/database";
import { getFirestore, collection, addDoc, doc } from "firebase/firestore";
import {
  getActiveUserData,
  getUserData,
  isImpersonationModeEnabled,
} from "../../../helpers/store";
import { toast } from "react-toastify";

async function flatFileUploads(sheetIds, filesMetadata) {
  const userData = JSON.parse(getActiveUserData());
  const inventoryId = userData.inventoryId;
  let updatedBy = userData.uid;
  if (isImpersonationModeEnabled()) {
    const parentUserData = JSON.parse(getUserData());
    updatedBy = parentUserData.uid;
  }

  //update filesMetadata
  filesMetadata = filesMetadata.map((fileMetadata) => {
    return {
      ...fileMetadata,
      userId: userData.uid,
      businessId: userData.businessId,
      tableName: "netnada_v2.CSVImports",
      updatedBy,
    };
  });

  //get fileIds from session storage
  const fileSheetIds = JSON.parse(sessionStorage.getItem("fileSheetIds"));
  const fileDocIds = JSON.parse(sessionStorage.getItem("fileDocIds"));
  //form object with docId:sheetId
  const documentIds = {};
  Object.keys(fileSheetIds).forEach((fileId) => {
    const sheetId = fileSheetIds[fileId];
    const docId = fileDocIds[fileId];
    documentIds[sheetId] = docId;
  });
  // console.log("documentIds", documentIds);
  //delete fileSheetIds and fileDocIds from session storage
  sessionStorage.removeItem("fileSheetIds");
  sessionStorage.removeItem("fileDocIds");
  sessionStorage.removeItem("currentFileId");

  try {
    await fetch("/api/files/flat-file-uploads", {
      method: "POST",
      headers: getAuthorizationHeaderWithContentType(),
      body: JSON.stringify({
        sheetIds,
        filesMetadata,
        documentIds,
        inventoryId,
      }),
      mode: "cors",
    });
  } catch (error) {
    console.error(error);
  }
}

async function deleteFlatFileUpload(fileId) {
  const url = `https://api.x.flatfile.com/v1/files/${fileId}`;
  const options = {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_FLATFILE_SECRET_KEY}`,
    },
  };

  try {
    const response = await fetch(url, options);
    const data = await response.json();
  } catch (error) {
    console.error(error);
  }
}

async function uploadFilesOnFirebase(fileName, fileBuffer) {
  const userData = JSON.parse(getActiveUserData());
  const userFilePath = `users/${userData.uid}/${fileName}`;
  const database = getDatabase(app);
  const storageRef = ref(getStorage(app), userFilePath);
  const uploadTask = uploadBytesResumable(storageRef, fileBuffer);

  try {
    const [snapshot] = await Promise.all([
      new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (uploadSnapshot) => {
            const progress =
              (uploadSnapshot.bytesTransferred / uploadSnapshot.totalBytes) *
              100;
            console.log(`Upload is ${progress}% done`);
          },
          (error) => {
            console.error(error);
            reject(error);
          },
          () => resolve(uploadTask.snapshot)
        );
      }),
    ]);

    const downloadURL = await getDownloadURL(snapshot.ref);

    const uploadData = {
      url: downloadURL,
      user: userData.uid,
    };

    // Create new key for the file --> used as document id in Firestore
    const newPostKey = push(child(dbRef(database), "Files")).key;
    const updates = {};
    updates[`/Files/${newPostKey}`] = uploadData;
    update(dbRef(database), updates);

    const docRef = await addDoc(
      collection(getFirestore(app), "Files"),
      uploadData
    );

    console.log("File uploaded and metadata updated");

    // Return an object containing download URL and docId
    return {
      downloadURL: downloadURL,
      docId: docRef.id,
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function downloadFlatFile(fileId) {
  const url = `https://api.x.flatfile.com/v1/files/${fileId}/download`;
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json, multipart/form-data",
      Authorization: `Bearer ${process.env.REACT_APP_FLATFILE_SECRET_KEY}`,
    },
  };

  try {
    const response = await fetch(url, options);
    const arrayBuffer = await response.arrayBuffer();
    const fileBuffer = new Uint8Array(arrayBuffer);
    return fileBuffer;
  } catch (error) {
    console.error(error);
  }
}

async function getSheetIds(workbookId) {
  const url = `https://api.x.flatfile.com/v1/workbooks/${workbookId}`;
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_FLATFILE_SECRET_KEY}`,
    },
  };

  try {
    const response = await fetch(url, options);
    const data = await response.json();
    const sheets = data["data"]["sheets"];
    const sheetIds = sheets.map((sheet) => sheet["id"]);
    return sheetIds;
  } catch (error) {
    console.error(error);
  }
}

async function getFilesBySpaceId(spaceId) {
  let url = `https://api.x.flatfile.com/v1/spaces/${spaceId}`;
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_FLATFILE_SECRET_KEY}`,
    },
  };

  try {
    const response = await fetch(url, options);
    const data = await response.json();
    const spaceMetadata = data["data"]["metadata"]["spaceInfo"];

    const {
      category,
      currency,
      inventoryId,
      officeId,
      startDate,
      endDate,
      approvalStatus,
    } = spaceMetadata;

    //fetch all files
    url = `https://api.x.flatfile.com/v1/files?spaceId=${spaceId}`;
    options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_FLATFILE_SECRET_KEY}`,
      },
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();

      const files = data["data"];

      //download all files
      const filesMetadata = await Promise.all(
        files.map(async (file) => {
          const { id, name } = file;

          const fileBuffer = await downloadFlatFile(id);

          //store file on firebase storage
          const fileMetadata = await uploadFilesOnFirebase(name, fileBuffer);
          // console.log(fileMetadata);

          const { downloadURL, docId } = fileMetadata;

          //save fileId:docId in session storage
          const fileDocIds =
            JSON.parse(sessionStorage.getItem("fileDocIds")) || {};
          fileDocIds[id] = docId;
          sessionStorage.setItem("fileDocIds", JSON.stringify(fileDocIds));

          const fileKey = docId;
          const fileName = name;
          const fileURL = downloadURL;
          const fileType = "csv";

          //delete file from flatfile
          await deleteFlatFileUpload(id);

          return { fileKey, fileName, fileURL, fileType };
        })
      );

      //update filesMetadata with category, officeId, startDate, endDate
      const updatedFilesMetadata = filesMetadata.map((fileMetadata) => {
        return {
          ...fileMetadata,
          category,
          currency,
          inventoryId,
          officeId,
          startDate,
          endDate,
          approvalStatus,
        };
      });
      return updatedFilesMetadata;
    } catch (error) {
      console.error(error);
    }
  } catch (error) {
    console.error(error);
  }
}

async function submit(jobId, spaceId, workbookId) {
  //acknowledge the job
  let url = `https://api.x.flatfile.com/v1/jobs/${jobId}/ack`;
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_FLATFILE_SECRET_KEY}`,
    },
    body: '{"info":"string","progress":33}',
  };

  try {
    const response = await fetch(url, options);
    const data = await response.json();
  } catch (error) {
    console.error(error);
  }

  //1. get file details from spaceId
  const filesMetadata = await getFilesBySpaceId(spaceId);

  //1. get sheetId from workbookId
  const sheetIds = await getSheetIds(workbookId);

  //2. store records in database
  await flatFileUploads(sheetIds, filesMetadata);

  //complete the job
  url = `https://api.x.flatfile.com/v1/jobs/${jobId}/complete`;
  const body = {
    info: "Job's work is done",
    outcome: { next: { type: "wait" } },
  };
  options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_FLATFILE_SECRET_KEY}`,
    },
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(url, options);
    const data = await response.json();
  } catch (error) {
    console.error(error);
  }
}

async function modifySheet(sheetId) {
  const url = `https://api.x.flatfile.com/v1/sheets/${sheetId}/records`;
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_FLATFILE_SECRET_KEY}`,
    },
  };

  try {
    const response = await fetch(url, options);
    const data = await response.json();
    const records = data["data"]["records"];

    let accountName = "";
    let isUpdated = false;
    let deletedRecords = [];
    const updatedRecords = records
      .map((record) => {
        const isValid = record["valid"];
        if (isValid) {
          //update accountName
          if (accountName !== "") {
            record["values"]["accountName"]["value"] = accountName;
            isUpdated = true;
          }
        } else {
          //get accountName
          accountName = record["values"]["date"]["value"];
          deletedRecords.push(record["id"]);
          return null;
        }

        //read description and extract contactName if any
        const description = record["values"]["description"]["value"];
        const contactName = description?.split("-")[0] || "";
        record["values"]["contactName"]["value"] = contactName;

        Object.keys(record.values).forEach((key) => {
          const updatedAt = record.values[key].updatedAt;
          record.values[key].updatedAt = new Date(updatedAt);
        });

        return record;
      })
      .filter((record) => record !== null);

    if (isUpdated) {
      // Update database records after modification
      // await api.records.update(sheetId, updatedRecords);

      // const url = "https://api.x.flatfile.com/v1/sheets/sheetId/records";
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_FLATFILE_SECRET_KEY}`,
        },
        body: JSON.stringify(updatedRecords),
      };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
      } catch (error) {
        console.error(error);
      }

      if (deletedRecords.length > 0) {
        //generate string
        const ids = deletedRecords.join("&ids=");
        const url = `https://api.x.flatfile.com/v1/sheets/${sheetId}/records?ids=${ids}`;
        const options = {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_FLATFILE_SECRET_KEY}`,
          },
        };

        try {
          const response = await fetch(url, options);
          const data = await response.json();
        } catch (error) {
          console.error(error);
        }
      }
      return updatedRecords;
    }

    return records;
  } catch (error) {
    console.error(error);
  }
}

async function cancel(jobId) {
  //acknowledge the job
  let url = `https://api.x.flatfile.com/v1/jobs/${jobId}/ack`;
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_FLATFILE_SECRET_KEY}`,
    },
    body: '{"info":"Job is cancelled"}',
  };

  try {
    const response = await fetch(url, options);
    const data = await response.json();
  } catch (error) {
    console.error(error);
  }

  //complete the job
  url = `https://api.x.flatfile.com/v1/jobs/${jobId}/complete`;
  const body = {
    info: "Job is cancelled",
    outcome: { next: { type: "wait" } },
  };
  options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_FLATFILE_SECRET_KEY}`,
    },
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(url, options);
    const data = await response.json();
    window.location = "/welcome/data/file-uploader";
  } catch (error) {
    console.error(error);
  }
}

export const flatfileEventListener = FlatfileListener.create((client) => {
  client.use(
    recordHook("expenseByAccountsBeta", (record) => {
      const dateRegexYearFirstDash =
        /^(19|20)\d\d-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/;
      const dateRegexDayFirstDash =
        /^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[012])-(19|20)\d\d$/;
      const dateRegexYearFirstSlash =
        /^(19|20)\d\d\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/;
      const dateRegexDayFirstSlash =
        /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[012])\/(19|20)\d\d$/;
      const dateRegexYearFirstDot =
        /^(19|20)\d\d\.(0?[1-9]|1[012])\.(0?[1-9]|[12][0-9]|3[01])$/;
      const dateRegexDayFirstDot =
        /^(0?[1-9]|[12][0-9]|3[01])\.(0?[1-9]|1[012])\.(19|20)\d\d$/;

      // const validateNumber = /^-?\d{1,3}(?:(?:,\d{3})+|\d*)(?:\.\d+)?$/;
      const validateNumberPositive =
        /^\s*\$?\s*\d{1,3}(?:(?:,\d{3})+|\d*)(?:\.\d+)?\s*$/;
      record.validate(
        "date",
        (value) =>
          dateRegexYearFirstDash.test(value) ||
          dateRegexDayFirstDash.test(value) ||
          dateRegexYearFirstSlash.test(value) ||
          dateRegexDayFirstSlash.test(value) ||
          dateRegexYearFirstDot.test(value) ||
          dateRegexDayFirstDot.test(value),
        "date formats accepted: DD/MM/YYYY, YYYY/MM/DD, with - or . as separators"
      );
      record.validate(
        "subTotal",
        (value) => validateNumberPositive.test(value),
        "Amount must be a number greater than 0"
      );
      return record;
    })
  );
  //when the user clicks the submit button
  client.on(
    "job:ready",
    { payload: { operation: "expenses:submit" } },
    async (event) => {
      const { context } = event;
      return submit(context.jobId, context.spaceId, context.workbookId);
    }
  );
  //when the user clicks the cancel button
  client.on(
    "job:ready",
    { payload: { operation: "expenses:cancel" } },
    async (event) => {
      const { context } = event;
      return cancel(context.jobId);
    }
  );

  //after the user maps the column, we process only 'expenseByAccounts'
  client.on("records:created", async ({ context: { sheetId } }) => {
    const sheetUrl = `https://api.x.flatfile.com/v1/sheets/${sheetId}`;
    const sheetOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_FLATFILE_SECRET_KEY}`,
      },
    };

    try {
      const response = await fetch(sheetUrl, sheetOptions);
      const data = await response.json();

      const sheetData = data["data"];
      const slug = sheetData["config"]["slug"];
      const modified = sessionStorage.getItem("modified");
      if (slug === "expenseByAccounts" && modified === "false") {
        await modifySheet(sheetId);
        sessionStorage.setItem("modified", "true");
      }

      //update fileids in session storage based on 'currentFileId'
      const fileSheetIds =
        JSON.parse(sessionStorage.getItem("fileSheetIds")) || {};
      if (Object.keys(fileSheetIds).length > 0) {
        const currentFileId = sessionStorage.getItem("currentFileId");
        if (currentFileId) {
          fileSheetIds[currentFileId] = sheetId;
          sessionStorage.setItem("fileSheetIds", JSON.stringify(fileSheetIds));
        }
      }
    } catch (error) {
      console.error(error);
    }
  });

  //after file creation, check if the file is of type csv
  client.on("file:created", async ({ context: { fileId } }) => {
    console.log("file created", fileId);

    const url = `https://api.x.flatfile.com/v1/files/${fileId}`;
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_FLATFILE_SECRET_KEY}`,
      },
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();

      if (data.data.mimetype !== "text/csv") {
        alert("Please upload a csv file");

        const url = `https://api.x.flatfile.com/v1/files/${fileId}`;
        const options = {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_FLATFILE_SECRET_KEY}`,
          },
        };

        try {
          const response = await fetch(url, options);
          const data = await response.json();
          // console.log(data);
        } catch (error) {
          console.error(error);
        }
      }

      //save current fileId in session storage
      sessionStorage.setItem("currentFileId", fileId);
      //Add file Id to session storage as an object {fileId:sheetId}
      const fileSheetIds =
        JSON.parse(sessionStorage.getItem("fileSheetIds")) || {};
      fileSheetIds[fileId] = "";
      sessionStorage.setItem("fileSheetIds", JSON.stringify(fileSheetIds));
    } catch (error) {
      console.error(error);
    }
  });

  //listener for all events
  // client.on("**", ({ topic }) => {
  //   console.log(`Received event: ${topic}`);
  // });
});
